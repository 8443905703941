import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PeriodsService } from '../open/services/periods.service';
import { TransactionTypeService } from '../transaction-type.service';

@Component({
  selector: 'app-period-details',
  templateUrl: './period-details.component.html',
  styleUrls: ['./period-details.component.css']
})
export class PeriodDetailsComponent implements OnInit {
  columns: Array<any> = [
    { name: 'br', label: 'Br' },
    { name: 'id', label: 'ID' },
    { name: 'description', label: 'Opis' },
    { name: 'in', label: 'Ulaz (RSD)', type : 'money' },
    { name: 'out', label: 'Izlaz (RSD)', type: 'money' },
    { name: 'actions', label: '', tooltip:{title: 'Dodaj novi unos'}, headerPath:'/transaction/new', actions : [{name: 'PDF', path: '/transaction/print/receipt/',param : 'id','icon': 'picture_as_pdf'}] },
  ];
  footerCols: Array<any> = [
    { name: 'br', label: '' },
    { name: 'id', label: '' },
    { name: 'description', label: 'Total' },
    { name: 'in', label: 'Ulaz (RSD)', type : 'money' },
    { name: 'out', label: 'Izlaz (RSD)', type: 'money' },
    { name: 'actions', label: ''},
  ];
  PERIODS_PATH = '/periods';

  displayedColumns: string[] = this.columns.map(column => column.name);
  dataSource = new BehaviorSubject([]);
  transactionTypes = new Map() ;
  currentPeriod = null;
  id = null;
  transactionsOverview = {
    start_cash: '',
    start_cek: '',
    start_total: '',
    end_cash: '',
    end_cek: '',
    end_total: '',
  };

  totals = {
    description : 'Total',
    in : 0,
    out : 0
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private periodsService: PeriodsService,
    private transactionTypeService : TransactionTypeService
    ) { }

  ngOnInit() {
    this.transactionTypeService.getTransactionTypes().forEach(type => {
      this.transactionTypes.set(type.id, type);
    });
    this.activeRoute.params.subscribe(params => {
      this.id = params.id;
    });


    const rows = [];
    this.periodsService.getTransactions(this.id).subscribe(res => {
      if (res['data']['period']) {
        this.currentPeriod = res['data']['period'];
      }

      if (res['data']['transactions']) {
        res['data']['transactions'].forEach((row, k) => {
          const obj = {};
          obj['br'] = ++k;
          obj['id'] = row.id;
          obj['description'] = row.id;
          let description = '';
          description += row['user'];
          description += ' - ' + this.getType(row.paymentMethod);
          if (row.comment && row.comment.length) {
            description += ' - ' + row.comment;
          }
          obj['description'] = description;
          //@todo use constant as in form
          if (row.type == 1) {
            obj['in'] = row.amount;  
          }
          if (row.type == 2) {
            obj['out'] = row.amount;  
          }  
          obj['actions'] = '';
          rows.push(obj);  
        });
      }
      this.dataSource.next(rows);

      if (res['data']['overview']) {
        this.transactionsOverview.start_cash = res['data']['overview']['start_cash'];
        this.transactionsOverview.start_cek = res['data']['overview']['start_cek'];
        this.transactionsOverview.start_total = res['data']['overview']['start_total'];
        this.transactionsOverview.end_cash = res['data']['overview']['end_cash'];
        this.transactionsOverview.end_cek = res['data']['overview']['end_cek'];
        this.transactionsOverview.end_total = res['data']['overview']['end_total'];
      }

      if (res['data']['totalIn']) {
        this.totals.in = res['data']['totalIn'];
      }
      if (res['data']['totalOut']) {
        this.totals.out = res['data']['totalOut'];
      }
    });
  }

  getType(typeId) {
    const id = Number(typeId);
    const type = this.transactionTypes.get(id);
    if(type && type.name) {
      return type.name; 
    } else {
      console.log(type);
      console.log(id);
      console.log(this.transactionTypes);
      return '';
    }
  }

}
