import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import { AuthenticationService } from '../_services';

@Component({
    selector: 'app-logout',
    template: '<strong>Logging out...</strong>'
})
export class LogoutComponent implements OnInit {
    public submitted: boolean = false;
    public error: string = '';

    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    ngOnInit() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    } 
}
