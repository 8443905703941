import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Transaction } from './Transaction';
import { TableColumn } from '../shared/lt-table/lt-table';
import { ResponseBody } from '../model/response-body';

@Injectable()
export class TransactionService {
    static MAIN_CURRENCY = {
        id :1,
        name: 'RSD'
    };
    static EUR = 'EUR';
    static EUR_SYMBOL = '€';
    readonly CASH = 1;
    readonly KARTICA = 3;
    readonly DEVIZNI = 4;
    readonly TEKUCI = 6;
    readonly FAKTURA = 7;

    readonly PAY_IN = 1;
    readonly PAY_OUT = 2;

    readonly HAS_BANK_STATEMENT = [
        this.KARTICA,
        this.TEKUCI,
        this.FAKTURA,
        this.DEVIZNI,
    ];
    static TRANSACTION_PRINT_COL : TableColumn = {
        name: 'print',
        label: 'Print', 
        type: 'icon-link',
        settings:{
            icon: 'picture_as_pdf', 
            iconText: 'pdf',
            color: 'primary',
            param: 'id',
            path: '/transaction-new/print/receipt/',
        }
    };

    url = environment.apiHost + '/lider/transaction-new'; 
    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };

    constructor(private http: HttpClient) {
    }

    dataList: any[] = [];

    getAll(): Observable<{
        success: boolean;
        data: Transaction[]
    }> {
        return this.http.get<any>(this.url, {
            params: {
                sort: '-id'
            }, headers: {
                ...this.headers
            }
        });
    }
    
    findById(id: number | string): Observable<{
        success: boolean;
        data: Transaction
    }> {
        let params = {};
        let headers = this.headers;
        return this.http.get<any>(this.url + '/' + id, {params, headers});
    }
    
    load(filter: object): void {
        this.find(filter).subscribe(
            result => {
                this.dataList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: object): Observable<ResponseBody<any[]>> {

        let params = {
            sort: '-id'
        };
        params = {
            ...params,
            ...filter
        }
        //params['page'] = data['page'];
        //params['per-page'] = data['perPage'];
        let headers = this.headers;

        return this.http.get<ResponseBody<any[]>>(this.url, {params, headers});
    }

    getOverview(filter: object): Observable<ResponseBody<any[]>> {

        let params = {
            sort: '-id'
        };
        params = {
            ...params,
            ...filter
        }
        let headers = this.headers;

        return this.http.get<ResponseBody<any[]>>(this.url + '/overview', {params, headers});
    }

    save(entity: any): Observable<any> {
        const headers = this.headers;
        if (entity.id) {
            //update
            return this.http.put<any>(this.url + '/' + entity.id, entity, {headers});
        } else {
            //create
            return this.http.post<any>(this.url, entity, {headers});
        }
    }

    getArchive(): Observable<any> {
        const path = this.url + '/archive';
        return this.http.get<any>(path);
    }

    getPaymentPeriod(id): Observable<any> {
        const path = this.url + '/get-period/?id=' + id;
        return this.http.get<any>(path);
    }

    confirmIsOnBankReceipt(data): Observable<any> {
        let url = this.url + '/has-bank-receipt'; 
        let headers = this.headers;
        return this.http.post<any>(url, data, {headers});
    }

    //helper data

  getInOut(){
    return of([
      {
          id: this.PAY_IN,
          name: 'Uplata',
          checked: true
      },
      {
          id: this.PAY_OUT,
          name: 'Isplata',
          checked: false
      }
    ]);
  }

  getCurrencies(){
    return of([
      {
          id: 1,
          name: 'RSD',
          checked: true,
          symbol: 'RSD'
      },
      {
          id: 2,
          name: TransactionService.EUR,
          checked: false,
          symbol: TransactionService.EUR_SYMBOL
      }
    ]);
  }
  //fiscalId id is account type on IT creator side
  
  getAccountTypes() {
    return of([
      {
        id: this.CASH,
        name: 'Keš',
        checked: true,
        fiscalId: 1
      },
      {
        id: 2,
        name: 'Ček',
        checked: false,
        fiscalId: 3
      },
      {
        id: this.KARTICA,
        name: 'Bankovna kartica',
        checked: false,
        fiscalId: 2
      },
      {
        id: this.DEVIZNI,
        name: 'Devizni račun',
        checked: false,
        fiscalId: 0
      },
      {
        id: 5,
        name: 'Administrativna zabrana',
        checked: false,
        fiscalId: 0
      },
      {
        id: this.TEKUCI,
        name: 'Tekući račun',
        checked: false,
        fiscalId: 4
      },
      {
        id: this.FAKTURA,
        name: 'Faktura',
        checked: false,
        fiscalId: 0
      }
    ]);
  }

  getTranactionReasons(){
    return of([
        {
            id: 1,
            name: 'Putovanje',
            checked: true
        },
        {
            id: 2,
            name: 'Avio karta',
            checked: false
        },
        {
            id: 3,
            name: 'PZO',
            checked: false
        },
        {
            id: 4,
            name: 'Slobodan unos',
            checked: false
        },
        {
            id: 5,
            name: 'Uplata pazara',
            checked: false
        }
    ]);
  }
}

