import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionEditComponent } from './transaction-edit/transaction-edit.component';
import { TransactionService } from './transaction.service';
import { TRANSACTION_ROUTES } from './transaction.routes';
import { MaterialModule } from './../material.module';
import { CardService } from '../card/card.service';
import { OpenTransactionComponent } from './open/open-transaction.component';
import { PeriodsService } from './open/services/periods.service';
import { ReceiptComponent } from './print/receipt.component';
import { PeriodDetailsComponent } from './archive/period-details.component';
import { PeriodComponent } from './archive/period.component';
import { SharedModule } from '../shared/shared.module';
import { CommonDialogComponent } from '../shared/dialog/common-dialog.component';
import { BankCardTransactionNewComponent } from '../features/bank-card-transaction/bank-card-transaction-new.component';
import { BankCardTransactionService } from '../features/bank-card-transaction/bank-card-transaction.service';
import { BankCardTransactionListComponent } from '../features/bank-card-transaction/bank-card-transaction-list.component';
import { BankCardTransactionArchiveComponent } from '../features/bank-card-transaction/bank-card-transaction-archive.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(TRANSACTION_ROUTES),
        MaterialModule,
        SharedModule
    ],
    declarations: [
        TransactionListComponent,
        TransactionEditComponent,
        OpenTransactionComponent,
        ReceiptComponent,
        PeriodDetailsComponent,
        PeriodComponent,
        BankCardTransactionNewComponent,
        BankCardTransactionListComponent,
        BankCardTransactionArchiveComponent,
    ],
    providers: [
        TransactionService,
        CardService,
        PeriodsService,
        BankCardTransactionService
    ],
    exports: []
})
export class TransactionModule { }
