import {formatDate} from '../../shared/helpers/date';

export interface BookOfContracts {
    agency_id: string;
    contract_date: string;
    contract_on_lastname: string;
    contract_on_name: string;
    contract_seq_number: string;
    contract_incremental_number: string;
    contract_number: string;
    created_on: string;
    fiscal_receipt_dates: string[];
    fiscal_receipt_numbers: string[];
    fiscal_receipt_amounts: string[];
    guaranty_date: string;
    guaranty_number: string;
    id: string;
    notice: string;
    organizer_id: string;
    organizer_name: string;
    sold_at_location: string;
    sold_by_id: string;
    sold_by_name: string;
    status: string;
    total_contract_amount: string;
    traveller_count: string;
    ltRowClass?: string;
}

export class BookOfContractsModel {
    id: string;
    customer_name: string;
    contract_number: string;
    contract_seq_number: string;
    contract_incremental_number: string;
    contract_date: string;
    guaranty_number: string;
    guaranty_date: string;
    fiscal_receipt_numbers: string[];
    fiscal_receipt_dates: string[];
    fiscal_receipt_amounts: string[];
    // fiscal_receipts: string[];
    total_contract_amount: string;
    traveller_count: string;
    organizer_name: string;
    sold_by_name: string;
    sold_at_location: string;
    notice: string;
    ltRowClass?: string;
    status: string;

    constructor(contract: BookOfContracts) {
        this.id = contract.id;
        this.customer_name = `${ contract.contract_on_name } ${ contract.contract_on_lastname }`
        this.contract_number = contract.contract_number;
        this.contract_seq_number = contract.contract_seq_number;
        this.contract_incremental_number = contract.contract_incremental_number;
        this.contract_date = contract.contract_date;
        this.guaranty_number = contract.guaranty_number;
        this.guaranty_date = contract.guaranty_date;
        this.fiscal_receipt_numbers = contract.fiscal_receipt_numbers;
        this.fiscal_receipt_dates = contract.fiscal_receipt_dates;
        this.fiscal_receipt_amounts = contract.fiscal_receipt_amounts;
        this.total_contract_amount = contract.total_contract_amount;
        this.traveller_count = contract.traveller_count;
        this.organizer_name = contract.organizer_name;
        this.sold_by_name = contract.sold_by_name;
        this.sold_at_location = contract.sold_at_location;
        this.notice = contract.notice;
        this.status = contract.status;
        if (contract.ltRowClass != '') {
            this.ltRowClass = contract.ltRowClass;
        }
    }

    formatTextToList(value: string | null): string[] {
        return (value || '').split(',') ;
    }
    formatTextToDateList(value: string | null): string[] {
        const textList = this.formatTextToList(value);
        return textList.map(text => formatDate(text));
    }
}
