import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BankCardTransactionService } from 'src/app/features/bank-card-transaction/bank-card-transaction.service';
import { TransactionFilter } from '../transaction-filter';
import { TransactionService } from '../transaction.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  transactionId : number = null;
  transaction = null;
  constructor(
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private bankCardTransactionService: BankCardTransactionService  
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let filter = new TransactionFilter();
      filter.id = params.id;
      if(!params.type) {
        this.transactionService.find(filter).subscribe( res => {
          if (res['data'] && res['data']['data'][0]) {
            this.transaction = res['data']['data'][0]; 
            this.transaction.date = formatDate(new Date(this.transaction.timestamp), 'dd.MM.yyyy', 'en');
          }
        });
      } else {
        switch(params.type) {
          case 'bank-card':
            this.bankCardTransactionService.getReceiptData(params.id).subscribe( res => {
              if (res.data && res.data[0]) {
                this.transaction = res.data[0];
              }
            });
          break;
        }
      }
      
    });
  }

}
