import { Component, OnInit } from '@angular/core';
import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

import { BehaviorSubject } from 'rxjs';
import { TransactionFilter } from '../transaction-filter';
import { TransactionTypeService } from '../transaction-type.service';
import { TransactionService } from '../transaction.service';
import { PeriodsService } from './services/periods.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonDialogComponent } from 'src/app/shared/dialog/common-dialog.component';

@Component({
  selector: 'app-open-transaction',
  templateUrl: './open-transaction.component.html',
  styleUrls: ['./open-transaction.component.css']
})
export class OpenTransactionComponent implements OnInit {
  columns: Array<any> = [
    { name: 'br', label: 'Br' },
    { name: 'id', label: 'ID' },
    { name: 'description', label: 'Opis' },
    { name: 'in', label: 'Ulaz (RSD)', type : 'money' },
    { name: 'out', label: 'Izlaz (RSD)', type: 'money' },
    { name: 'receipt', label: '', type: 'checkbox-action'},
    { name: 'actions', label: '', tooltip:{title: 'Dodaj novi unos'}, headerPath:'/transaction/new', actions : [{name: 'PDF', path: '/transaction/print/receipt/',param : 'id','icon': 'picture_as_pdf'}] },
  ];

  displayedColumns: string[] = this.columns.map(column => column.name);
  dataSource = new BehaviorSubject([]);
  transactionTypes = new Map() ;
  currentPeriod = null;
  loaded = false;
  openingNewPeriod = false;
  closingCurrentPeriod = false;
  canAddTransaction = false;
  totals = {
    description : 'Total',
    in : 0,
    out : 0
  };
  cashTotalStart = 0;
  cekTotalStart = 0;

  constructor(
    private transactionService: TransactionService,
    private transactionTypeService: TransactionTypeService,
    private periodsService: PeriodsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    this.transactionTypeService.getTransactionTypes().forEach(type => {
      this.transactionTypes.set(type.id, type);
    });

    this.loadCurrentPeriod();
  }

  getType(typeId) {
    const id = Number(typeId);
    const type = this.transactionTypes.get(id);
    if(type && type.name) {
      return type.name; 
    } else {
      console.log(type);
      console.log(id);
      console.log(this.transactionTypes);
      return '';
    }
  }

  openNewPeriod(){
    this.openingNewPeriod = true;
    this.periodsService.openNewPeriod().subscribe(
      res => {
        if (res && res.data) {
          this.currentPeriod = res.data;
          this.setCanAddTransaction();

          this._snackBar.open('Blagajna je uspješno otvorena', null, {
            duration: 5000,
            panelClass: ['success-snackbar']
          });
        } else {
          alert('Neuspjesno');
        }
        this.openingNewPeriod = false;
      },
      err => {
        this.showError(err);
        this.openingNewPeriod = false;
      }
    ); 
  }
  
  closeCurrentPeriod(){

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '450px',
      data: {
        title: 'Zatvaranje dnevnika blagajne',
        text: 'Da li ste potpuno sigurni da želite da zatvorite dnevnik blagajne? Dnevnik blagajne ćete trajno zatvoriti.',
        confirmBtn: 'Zatvori blagajnu',
        cancelBtn: 'Otkaži',
        confirmClass: 'red-btn',
        cancelClass: 'green-btn',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.closingCurrentPeriod = true;
        this.periodsService.closeCurrentPeriod().subscribe( 
          res => {
          if (res && res.data) {
            this.currentPeriod = res.data;
            this._snackBar.open('Blagajna je uspješno zatvorena', null, {
              duration: 5000,
              panelClass: ['success-snackbar']
            });
          } else {
            alert('Neuspjesno');
          }
        }, err => {
          alert('Error happened');
        }
        );
        this.closingCurrentPeriod = false;
      }
    });
     
  }

  loadCurrentPeriod(){
    this.periodsService.getCurrent().subscribe(res => {
      console.log(res);
      if (res.data && res.data != null) {
        this.currentPeriod = res.data;
        console.log(this.currentPeriod);
        this.setCanAddTransaction();
        this.loadTransactions()
      }
      this.loaded = true;
    });
  }

  setCanAddTransaction() {
    console.log(formatDate(new Date(), 'yyyy/MM/dd', 'en'));
    console.log(this.currentPeriod.date);

    if (this.currentPeriod && !this.currentPeriod.closed && formatDate(new Date(), 'yyyy-MM-dd', 'en') == this.currentPeriod.date) {
      this.canAddTransaction = true;
      console.log('can');
    } else {
      this.canAddTransaction = false;
      console.log('can not');

    }
  }

  loadTransactions() {
    const rows = [];
    const filter = new TransactionFilter();
    filter.open = 1;
    filter.payment_period_id = this.currentPeriod && this.currentPeriod.id ? this.currentPeriod.id : -1;
    this.transactionService.find(filter).subscribe(
      res => {
        if (res['data'] && res['data']['totalIn']) {
          this.totals.in = res['data']['totalIn'];
        }
        if (res['data'] && res['data']['totalOut']) {
          this.totals.out = res['data']['totalOut'];
        }

        if (res['data'] && res['data']['cashTotal']) {
          this.cashTotalStart += res['data']['cashTotal'];
        }

        if (res['data'] && res['data']['cekTotal']) {
          this.cekTotalStart += res['data']['cekTotal'];
        }
        
        if (res['data'] && res['data']['data']) {
          res['data']['data'].forEach((row, k) => {
            const obj = {};
            obj['br'] = ++k;
            obj['id'] = row.id;
            obj['description'] = row.id;
            let description = '';
            description += row['user'];
            description += ' - ' + this.getType(row.paymentMethod);
            if (row.comment && row.comment.length) {
              description += ' - ' + row.comment;
            }
            obj['description'] = description;
            obj['receipt'] = row['receipt'] ==1 ? true : false;
            //@todo use constant as in form
            if (row.type == 1) {
              obj['in'] = row.amount;  
            }
            if (row.type == 2) {
              obj['out'] = row.amount;  
            }  
            obj['actions'] = '';
            rows.push(obj);  
          });
        }
        this.dataSource.next(rows);
      });
  }

  cashTotal() {
    const startSaldoCash = this.currentPeriod && this.currentPeriod.start_saldo ? this.currentPeriod.start_saldo : 0;
    return Number.parseInt(startSaldoCash) + (this.cashTotalStart * 1);
  }

  
  cekTotal() {
    const startSaldoCek = this.currentPeriod && this.currentPeriod.start_saldo_cek ? this.currentPeriod.start_saldo_cek * 1 : 0;
    return (startSaldoCek) + (this.cekTotalStart * 1);
  }

  sendChecked($event, element) {
    let data = {
      checked: $event.checked,
      id: element.id
    };
    this.transactionService.confirmChecked(data).subscribe(res=> {
      if(res && res['success'] == true) {
        this._snackBar.open('Transakcija uspešno čekirana', null, {
          duration: 5000,
          panelClass: ['success-snackbar']
        });
      }
      this.loadTransactions();
    },
    err =>{
      this.showError(err);
      this.loadTransactions();
    });
  }

  getError(err) {
    let errMsg = '';
    if (err['error'] && err['error']['data'] && err['error']['data']['message']) {
      errMsg = err['error']['data']['message'];
    } else {
      errMsg = 'Desila se greška';
    }
    console.log(err);
    return errMsg;
  }

  showError(err) {
    let errMsg = this.getError(err);

    this._snackBar.open(errMsg, null, {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }
} 
