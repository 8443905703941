import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';

@Component({
  selector: 'form-select-chip', 
  //styles: [''],
  //styleUrls: ['form-select.component.scss'],
  template: `
    <style>
      mat-form-field {
        width: 100%;
      }
      :host ::ng-deep .mat-form-field-infix
      { 
        padding: 15px 0px!important;
      }
    </style>
    <mat-form-field [formGroup]="group">
        <mat-select  [compareWith]="compareWithFn" [placeholder]="config.placeholder" [formControl]="control" multiple>
            <mat-select-trigger>
                <mat-chip-list>
                    <mat-chip *ngFor="let option of group.controls[config.name].value"
                    [removable]="true" (removed)="onCatRemoved(option)" color="primary" selected>
                    {{ option.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip> 
                </mat-chip-list>
            </mat-select-trigger>
            <mat-option *ngFor="let option of config.options" [value]="option">
            {{ option.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
  `
})
export class FormSelectChipComponent extends AbstractInputComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  control;

  ngOnInit() {
    this.control = this.group.get(this.config.name);
  }
  /**
	 * Write code on Method
	 *
	 * method logical code
	 */
	onCatRemoved(cat:never) {
    const optionsSelected = this.group.get(this.config['name']).value;
    this.removeFirst(optionsSelected, cat);
		this.group.get(this.config['name']).setValue(optionsSelected); // To trigger change detection
	}
  
  compareWithFn(item1, item2) {
    return item1 && item2 ? item1.id == item2.id : item1 === item2;
  }
  /**
   * Write code on Method
   *
   * method logical code
   */
	private removeFirst(array, toRemove: never): void {
		const index = array.indexOf(toRemove);
		if (index !== -1) {
			array.splice(index, 1);
		}
	}



}