import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizerComponent } from './organizer/organizer.component';
import { SharedModule } from '../shared/shared.module';
import { OrganizerListComponent } from './organizer-list/organizer-list.component';
import { MaterialModule } from '../material.module';
import {RouterModule} from '@angular/router';
import { OrganizerEditComponent } from './organizer-edit/organizer-edit.component';
import { OrganizerSettingsEditComponent } from './organizer-settings/organizer-settings-edit.component';
import { OrganizerSettingsService } from './organizer-settings/organizer-settings.service';



@NgModule({
  declarations: [
    OrganizerComponent,
    OrganizerListComponent,
    OrganizerEditComponent,
    OrganizerSettingsEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    RouterModule.forChild([
      {
        path: '',
        component: OrganizerListComponent
      },
      {
        path: ':id',
        component: OrganizerEditComponent
      }
    ]),
  ],
  providers: [
    OrganizerSettingsService
  ]
})
export class OrganizerModule { }
