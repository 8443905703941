import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lt-pagination',
  templateUrl: './lt-pagination.component.html',
  //styleUrls: ['./lt-table.component.scss'],
})
export class LtPaginationComponent implements OnInit, AfterViewInit {
  defaultValues = {
    pageIndex: 0,
    pageSize: 20 
  };
  @Input() pageSizeOptions = [20, 30, 50, 100];
  @Input() totalCount = 0;
  @Input() pageIndex = this.defaultValues.pageIndex; 
  @Input() pageSize;

  @Output() paginationEvent = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  allSub : Subscription[] = [];
  
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.allSub.push(
      this.route.queryParams.subscribe(params => {
        if (params.pageSize) {
          this.pageSize = params.pageSize;
        }
        if (params.pageIndex) {
          this.pageIndex = params.pageIndex;
        }
      })
    );
  }

  ngAfterViewInit() {  
    this.paginator.page.subscribe(data => {
      this.emitEvent(data);
    });
  }

  firstPage() {
    this.paginator.firstPage();
  }

  emitEvent(data) {
    this.paginationEvent.emit({
      data: data,
    })
  }

  ngOnDestroy(): void {
    for (const sub  of this.allSub) {
      if(!sub.closed) {
        sub.unsubscribe();
      }
    }
  }

}
