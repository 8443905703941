import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './../material.module';
import {SharedModule} from '../shared/shared.module';
import { HOTEL_FILTER_ROUTES } from './hotel-filter.routes';
import { HotelHasFilterEditComponent } from './hotel-has-filter-edit.component';
import { EntityFilterService } from './entity-filter.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(HOTEL_FILTER_ROUTES),
    MaterialModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    HotelHasFilterEditComponent,
  ],
  providers: [
    EntityFilterService
  ],
  exports: [
  ]
})
export class HotelFilterModule { }
