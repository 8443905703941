export const PASSENGER_TODOS = [
    {
        id: 1,
        key: 'poruka_anketa',
        text: 'Por. anketa'
    },
    {
        id: 2,
        key: 'poruka_dug',
        text: 'Por. dug'
    },
    {
        id: 3,
        key: 'poruka_podatak_nedostaje',
        text: 'Por. nepotpuni podaci'
    },
    {
        id: 4,
        key: 'poruka_polazak',
        text: 'Por. polazak'
    },
    {
        id: 5,
        key: 'poruka_admin_zabrana',
        text: 'Por. admin. zabrana'
    }
];
