import { Component, OnInit, ViewChild } from '@angular/core';
import { ContractService } from '../contract.service';
import { TableColumn, TableEvent } from '../../shared/lt-table/lt-table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { PASSENGER_TODOS } from './passenger-todo';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PassengerListService } from './passenger-list.service';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { finalize } from 'rxjs/operators';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { LtFilterComponent } from 'src/app/shared/lt-filter/lt-filter.component';
import { LtPaginationComponent } from 'src/app/shared/pagination/lt-pagination.component';
import { OrganizerService } from 'src/app/organizer/organizer.service';
import { TableOptions } from '../contract.component';
import { nowDateTime } from 'src/app/shared/helpers/date';

export interface ContractSearchParams {
  id?: number;
  departure_from?: string;
  departure_to?: string;
  return_from?: string;
  return_to?: string;
  customer_name?: string;
  customer_lastname?: string;
  customer_personal_id?: string;
  customer_phone?: string;
  transport?: number;
  organizer?: string;
  guar_no?: number;
  org_contr?: number;
  arr?: string;
  hotel?: string;
  extendedView?: string;
  fullSearch?: string;
  hideOldDates?: boolean | string;
  sortOrder?: string;
  sortCol?: string;
  pageSize?: number;
}

@Component({
  selector: 'app-lt-passenger-list',
  templateUrl: './passenger-list.component.html',
  //styleUrls: ['./book-of-contracts.component.css']
})
export class PassengerListComponent implements OnInit {
  pageTitle = 'Spisak putnika';
  dataSource: any[] = [];
  subscriptions: Subscription[] = [];
  filterConfig: FieldConfig[] = [];
  isSearching: boolean = false;
  tableColumns: TableColumn[] = [
    {
      name: 'contractNo', label: 'Br.Ugovora', type: 'url', sort: true,
      url: {
        route: '/contract/',
        idParam: 'contractId'
      }
    },
    { name: 'name', label: 'Putnik', sort: true },
    { name: 'destination', label: 'Aranžman', sort: true },
    { name: 'entryCity', label: 'Mesto ulaska putnika', sort: true },
    { name: 'departureDate', label: 'Polazak', sort: true },
    { name: 'returnDate', label: 'Povratak', sort: true },
    { name: 'room', label: 'Soba', sort: true },
    { name: 'service', label: 'Usluga', sort: true },
    { name: 'hotel', label: 'Hotel', sort: true },
    { name: 'phone', label: 'Telefon' },
    { name: 'email', label: 'Email' },
    { name: 'jmbg', label: 'JMBG' },
    { name: 'passport', label: 'Broj pasoša' },
    { name: 'address', label: 'Adresa' },
    { name: 'noticeItern', label: 'Interna napomena' },
    /*
    {
      name: 'button',
      label: 'Akcije',
      actions: [
        {
          type: 'event',
          actionName: 'lt-edit-book-of-contracts',
          name: 'Izmeni',
          icon: 'edit'
        }
      ]
    },
    */
  ]

  totalRowsCount = 0;
  pageSizeDefault = 100;
  pageSize;
  searchValues: ContractSearchParams = {};
  organizers = [];

  tableOptions: TableOptions = {
    hasHideShowCol: true
  };

  toolbarButtons = [
    {
      label: 'Lista putnika export',
      clickAction: 'exportPassengerList',
      icon: 'contact_page',
      color: 'primary'
    }
  ];

  @ViewChild(LtFilterComponent) filter: LtFilterComponent;
  @ViewChild(LtPaginationComponent) pagination: LtPaginationComponent;

  constructor(
    private contractService: ContractService,
    private passengerListService: PassengerListService,
    private organizerService: OrganizerService,
    private formEventService: FormEventService,
    private _snackBar: MatSnackBar,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    PASSENGER_TODOS.forEach(todo => {
      this.tableColumns.push({
        name: todo.key,
        label: todo.text,
        type: 'checkbox-action',
        actionName: 'ltTodoChanged-' + todo.id
      })
    });
    this.subscriptions.push(
      this.route.queryParams.subscribe(queryParams => {
        this.pageSize = queryParams.pageSize ?? this.pageSizeDefault;
        this.searchValues = { pageSize: this.pageSize, ...queryParams };
        this.getRows(this.searchValues);
      })
    );

    this.subscriptions.push(
      this.formEventService.eventEmitter$.subscribe(event => {
        if (event.message == 'ltHideOldDatesEvt') {
          this.onFilterChanged(this.filter.filterForm.getRawValue());
        }
      })
    );
  }

  getRows(filter?: any) {
    this.isSearching = true;

    forkJoin({
      organizers: this.organizers.length ?
       (() : Observable<any> =>{return of({data:this.organizers})})() : this.organizerService.getAll(),
      mainData: this.contractService.getPassengerList(filter)
    })
      .pipe(
        finalize(() => {
          this.isSearching = false;
        })
      )
      .subscribe({
        next: (response) => {
          //only load first time
          if (!this.organizers.length) {
            this.organizers = this.organizers = response.organizers.data.map(el => {
              return {
                id: el.id,
                name: el.name,
              }
            });
          }
          //set here because it depends on organizers
          this.setFilterFormConfig();

          let mainData = response.mainData;
          if (mainData.data) {
            this.totalRowsCount = mainData?.meta?.totalCount;
            this.dataSource = mainData.data.map(row => {
              row.contractNo = {
                text: row.contractNo ? '#' + row.contractNo : '__',
                idParam: row.contractId
              };
              let checklist = {};
              if (row.checklist) {
                row.checklist.forEach(item => {
                  checklist[Number(item.checklist_type_id)] = item.checklist_value;
                });
              }

              PASSENGER_TODOS.forEach(todo => {
                row[todo.key] = {
                  value: checklist[todo.id] ?? false
                }
              });
              return row;
            });
          }
        },
        error: (err) => {
          this.showError(err.error.message);
        }
      });
  }

  saveChanges(event) {
    if (!event?.id) {
      this.showError('Greška! Nedostaje ID knjige ugovora');
      return;
    }
    if (event.notice === undefined) {
      this.showError('Greška! Nedostaje polje napomene');
      return;
    }

  }

  paginationChanged(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          pageIndex: event.data.pageIndex,
          pageSize: event.data.pageSize
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  showError(message: string, action = null) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }

  onTableEvent(event: TableEvent) {
    if (event?.name && event.name.indexOf('ltTodoChanged') != -1) {
      const checklistItemType = event.name.split('-')[1] ?? null;
      const rowData = event.data as any;
      const changed = event.event as MatCheckboxChange;

      const checked = changed?.checked ?? false;
      const itemId = rowData.contractHasCustomerId;
      this.passengerListService.updateChecklistItem(itemId, checklistItemType, checked).subscribe({
        next: (resp: any) => {
          if (resp.success) {
            this._snackBar.open('Uspešno sačuvano', '', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
            this.getRows(this.searchValues);
          }
        },
        error: (err) => {
          this.showError(err.error.message);
          this.getRows(this.searchValues);
        },
      });
    }

    if (event.name == 'ltTableSortChanged') {
      const sortedCol = event?.event?.active;
      const sortOrder = event?.event?.direction;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { sortCol: sortedCol, sortOrder: sortOrder },
          queryParamsHandling: 'merge'
        }
      );
    }

    if (event && event.name == 'exportPassengerList') {
      this.exportPassengerList();
    }
  }

  onFilterChanged(filter) {
    //@todomilos filter and use only set value
    /*
    Object.keys(filter).forEach(key=> {
      if (filter[key] === '') {
        console.log('deleted ' + key);
        delete filter[key]; 
      }
    });
    */
    let organizer = filter.organizer ?? [];
    organizer = organizer.join(','); 

    filter = {
      ...filter,
      organizer: organizer,
      ...this.pagination.defaultValues,
      pageSize: this.pageSize
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: filter,
        queryParamsHandling: 'merge'
      }
    );
  }

  setFilterFormConfig() {
    const filterConfig: FieldConfig[] = [
      {
        type: 'input',
        label: 'Br. Ugovora',
        name: 'id',
        placeholder: 'Br Ugovora',
        value: this.searchValues?.id ? this.searchValues.id : '',
        col: 1
      },
      {
        type: 'datepicker',
        label: 'Polazak od',
        name: 'departure_from',
        placeholder: 'Polazak od',
        value: this.searchValues?.departure_from ? this.searchValues.departure_from : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Polazak do',
        name: 'departure_to',
        placeholder: 'Polazak do',
        value: this.searchValues?.departure_to ? this.searchValues.departure_to : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Povratak od',
        name: 'return_from',
        placeholder: 'Povratak od',
        value: this.searchValues?.return_from ? this.searchValues.return_from : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Povratak do',
        name: 'return_to',
        placeholder: 'Povratak do',
        value: this.searchValues?.return_to ? this.searchValues.return_to : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Prevoz',
        name: 'transport',
        options: [
          {
            id: '',
            name: 'Odaberi prevoz',
          },
          {
            id: 1,
            name: 'Bus'
          },
          {
            id: 2,
            name: 'Avio'
          },
          {
            id: 3,
            name: 'Sopstveni'
          }],
        placeholder: 'Prevoz',
        //validation: [Validators.required],
        value: this.searchValues?.transport ? Number(this.searchValues.transport) : '',
        col: 1
      },
      {
        type: 'input',
        label: 'Aranžman',
        name: 'arr',
        placeholder: 'Aranžman',
        value: this.searchValues?.arr ? this.searchValues.arr : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Smeštaj',
        name: 'hotel',
        placeholder: 'Smeštaj',
        value: this.searchValues?.hotel ? this.searchValues.hotel : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Ime',
        name: 'customer_name',
        placeholder: 'Ime putnika',
        value: this.searchValues?.customer_name ? this.searchValues.customer_name : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Prezime',
        name: 'customer_lastname',
        placeholder: 'Prezime putnika',
        value: this.searchValues?.customer_lastname ? this.searchValues.customer_lastname : '',
        col: 2
      },
      {
        type: 'input',
        label: 'JMBG',
        name: 'customer_personal_id',
        placeholder: 'JMBG  putnika',
        value: this.searchValues?.customer_personal_id ? this.searchValues.customer_personal_id : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Telefon',
        name: 'customer_phone',
        placeholder: 'Broj telefona',
        value: this.searchValues?.customer_phone ? this.searchValues.customer_phone : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Organizator',
        multiple: true,
        name: 'organizer',
        options: [
          {
            id: '',
            name: 'Odaberi organizatora'
          },
        ...this.organizers
        ],
        placeholder: 'Organizator',
        //validation: [Validators.required],
        value: this.searchValues.organizer !== undefined ? this.searchValues.organizer.split(',') : null,
        col: 2
      },
      {
        type: 'input',
        label: 'Br. ug. organizatora',
        name: 'org_contr',
        placeholder: 'Br. ug. organizatora',
        value: this.searchValues?.org_contr ? this.searchValues.org_contr : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Br. garancije',
        name: 'guar_no',
        placeholder: 'Br. garancije',
        value: this.searchValues?.guar_no ? this.searchValues.guar_no : '',
        col: 2
      },
      {
        type: 'checkbox',
        label: 'Sakrij prošle datume',
        name: 'hideOldDates',
        value: this.searchValues?.hideOldDates === 'true' ? true : false,
        eventMessages: {
          onChange: 'ltHideOldDatesEvt'
        },
        col: 2
      }

    ];

    // set default col size for each input
    this.filterConfig = filterConfig.map(formControl => {
      return {
        ...formControl,
        col: formControl.col || 3
      }
    });
  }

  exportPassengerList() {
    //setIsExportFlag in order no to do pagination and limit but return all records
    this.contractService.getPassengerListExport({...this.searchValues, isExport: 1}).subscribe(res => {
      if (res['data']) {
        this.downloadXlsxFile(res['data'], 'lista-putnika-' + nowDateTime());
      }
    });
  }
  //@todo dplicate
  downloadXlsxFile(data, filename) {
    const format = 'xlsx';
    var linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + data;
    var downloadLink = document.createElement("a");
    var fileName = filename + '.' + format;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}
