import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ContractService } from './contract.service';
import { MaterialModule } from './../material.module';
import { ArrangmentService } from '../arrangment/arrangment.service';
import { HotelService } from '../hotel/hotel.service';
import { ArrangmentHasServiceComponent } from '../arrangment-service/arrangment-service.component';
import { ArrangmentHasServiceEditComponent } from '../arrangment-service/arrangment-service-edit.component';
import { DynamicFormModule } from './../components/dynamic-form-master/dynamic-form.module';
import { ContractComponent } from './contract.component';
import { ContractEditNewComponent } from './contract-edit/contract-edit-new.component';
import { OrganizerService } from '../organizer/organizer.service';
import { BookOfContractsComponent } from './book-of-contracts/book-of-contracts.component';
import {SharedModule} from "../shared/shared.module";
import { ContractPrintComponent } from './print/contract-print.component';
import { FiscalReceiptsEditComponent } from './fiscal-receipts-edit/fiscal-receipts-edit.component';
import { TransactionService } from '../transaction-new/transaction.service';
import { RouterModule } from '@angular/router';
import { FiscalReceiptsListComponent } from './fiscal-receipts-edit/fiscal-receipts-list.component';
import { FiscalReceiptService } from './fiscal-receipts-edit/fiscal-receipt-service';
import { PassengerListComponent } from './passenger-list/passenger-list.component';
import { PassengerListService } from './passenger-list/passenger-list.service';
import { TravelApplicationEditComponent } from './travel-application/travel-application-edit.component';
import { TravelApplicationService } from './travel-application/travel-application.service';
import { TravelApplicationListComponent } from './travel-application/travel-application-list.comonent';
import { TaPriceEditComponent } from './travel-application/product-price/ta-price-edit.component';
import { CisStornoDialogComponent } from './cis-storno-dialog/cis-storno-dialog.component';
import { FiscalReceiptGenerateComponent } from './fiscal-receipts-edit/fiscal-receipt-generate.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        DynamicFormModule,
        SharedModule
    ],
  declarations: [
    ContractEditNewComponent,
    ContractComponent,
    ArrangmentHasServiceComponent,
    ArrangmentHasServiceEditComponent,
    BookOfContractsComponent,
    ContractPrintComponent,
    FiscalReceiptsEditComponent,
    FiscalReceiptGenerateComponent,
    FiscalReceiptsListComponent,
    PassengerListComponent,
    TravelApplicationEditComponent,
    TravelApplicationListComponent,
    TaPriceEditComponent,
    CisStornoDialogComponent
  ],
  providers: [
    ContractService,
    ArrangmentService,
    HotelService,
    OrganizerService,
    TransactionService,
    FiscalReceiptService,
    PassengerListService,
    TravelApplicationService
  ],
  exports: [
    FormsModule,
  ]
})
export class ContractModule { } 
