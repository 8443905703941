import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ContactFilter } from '../contact-filter';
import { ContactService } from '../contact.service';
import { Contact } from '../contact';
import { Subject, Subscribable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'contact',
    templateUrl: 'contact-list.component.html'
})
export class ContactListComponent implements AfterViewInit {
    pageTitle = 'Kontakti';
    filter = new ContactFilter();
    selectedContact: Contact;
    txtQueryChanged: Subject<string> = new Subject<string>();

    resultsLength = 0;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    contactList = [];
    subscriptions : Subscription[] = [];

    constructor(private contactService: ContactService, private titleService: Title) {
        this.subscriptions.push(this.txtQueryChanged
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe(model => { 
          //duplicated
        this.filter['q'] = model;
        this.paginator.firstPage();
        this.search();
          /*this.contactService.find(this.filter).subscribe(
            resp => {
              this.dataSource = new MatTableDataSource(resp['data'])
              this.resultsLength = resp['meta']['totalCount'];
              this.perPage = resp['meta']['perPage'];
            }
          );*/ 
        }));

        /*if(this.primaoci.length == 0) {
          this.orderService.getPrimaoci().subscribe(data => { this.primaoci = data['data']});
        }*/
        this.titleService.setTitle(this.pageTitle);
    }
    ngOnInit() {
    }
    ngAfterViewInit() {  
      this.paginator.page.subscribe(data => {
        this.search();
      });
      this.search();
    }

    search(): void {
      this.filter.currentPage = this.paginator.pageIndex;
      this.filter.pageSize = this.paginator.pageSize;
      this.contactService.find(this.filter).subscribe(resp => {
        this.resultsLength = resp.meta.totalCount;
        this.contactList = resp.data;
      });
    }

    select(selected: Contact): void {
        this.selectedContact = selected;
    }

    applyFilter(filterValue: string) {
      this.txtQueryChanged.next(filterValue);
    }

    ngOnDestroy(): void {
      for (const sub  of this.subscriptions) {
        if(!sub.closed) {
          sub.unsubscribe();
        }
      }
    }

}
