/* tslint:disable:prefer-const */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseBody } from 'src/app/model/response-body';


@Injectable()
export class OrganizerSettingsService {
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
    };
    url = environment.apiHost + '/lider/organizer-settings';
    
    findByOrganizerId(organizerId: string | number): Observable<any> {
        const url = this.url + '/organizer/' + organizerId;
        const params = {};
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    save(entity): Observable<any> {
        let url = this.url;
        let headers = this.headers;
        return this.http.post<any>(url, entity, {headers});
    }
}

