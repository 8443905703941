import {Component, HostBinding} from '@angular/core';
import { Field } from '../models/field.interface';


@Component({
  selector: 'app-abstract-input',
  styleUrls: [],
  template: ``
})
export abstract class AbstractInputComponent implements Field {
  @HostBinding('class') get className() {
    const col = this.config.col || 12;
    return `form-input form-input--col-${ col }`;
  }
  abstract config;
  abstract group;
}
