
<mat-toolbar color="warn">    
        <mat-toolbar-row>
            <span>Nova transakcija</span>
            <span class="example-spacer"></span>
           
        </mat-toolbar-row>
    </mat-toolbar>
    
    <mat-card-content>
        <mat-card id="file-upload-card">
            <h4>{{errors}}</h4>
            <form *ngIf="transaction" class="example-form" (submit)="save()"> 
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Iznos"  [(ngModel)]="transaction.amount" name="amount" required>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput [disabled]="dialogData.isInDialog" placeholder="Referenca"  [(ngModel)]="transaction.reference" name="reference">
                </mat-form-field>
                <!--
                <mat-form-field floatPlaceholder="always"  class="example-full-width">
                    <input matInput  style="display: none" >
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    [(ngModel)]="transaction.type"
                    name="type"
                    >
                        <mat-radio-button [checked]=" 1 === transaction.type" class="example-radio-button"  value="1">
                            Uplata
                        </mat-radio-button>
                        <mat-radio-button name="type" class="example-radio-button"  value="2">
                        Isplata
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-form-field>-->
                <mat-form-field class="example-full-width">
                    <mat-label>Tip</mat-label>
                    <mat-select [(value)]="transaction.type" [(ngModel)]="transaction.type" name="type"> 
                        <mat-option *ngFor="let transactionType of transactionTypes" [(value)]="transactionType.id">{{transactionType.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field class="example-full-width">
                    <mat-label>Svrha transakcije</mat-label>
                    <mat-select [(value)]="transaction.payment_subject" [(ngModel)]="transaction.payment_subject" name="payment_subject"> 
                        <mat-option *ngFor="let subject of paymentSubjects" [(value)]="subject.id">{{subject.name}}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field class="example-full-width">
                    <mat-label>Vrsta novca</mat-label>
                    <mat-select [(value)]="transaction.payment_method" [(ngModel)]="transaction.payment_method" name="payment_method"> 
                        <mat-option *ngFor="let moneyType of moneyTypes" [(value)]="moneyType.id">{{moneyType.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                        <textarea matInput [(ngModel)]="transaction.comment" name="comment" placeholder="Komentar"></textarea>
                </mat-form-field>

                <div class="form-group">
                    <button mat-raised-button
                    color="warn"><mat-icon>save</mat-icon> Sačuvaj</button>
                </div>

            </form>
        
        </mat-card>  
    </mat-card-content>