import { Component, OnInit } from '@angular/core';
import {environment} from './../../environments/environment';
import {HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { ContactService } from './contact.service';


@Component({
  selector: 'app-contact-upload',
  templateUrl: './contact-upload.component.html',
  //styleUrls: ['./mass-upload.component.css']
})
export class ContactUploadComponent implements OnInit {

  constructor(private router: Router,
    public contactSer: ContactService) { }

  ngOnInit() {
  }

  afuConfig = {
    theme: "dragNDrop",
    multiple: true,

    formatsAllowed: ".xlsx,.csv", //.format,.format2,...,formatn 
    maxSize: "15",
    uploadAPI:  {
      url:environment.apiHost + '/lider/contact/import-data',
      headers: {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
      }
    },
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Upload',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
  };

  DocUpload(event) {
    console.log(event.response.status);
    if(event.response){
      this.router.navigate(['/contact']);   
    }

  }

  get() {
    console.log('here');
    this.contactSer.getImport().subscribe(res => {
      console.log(res);
    });
  }

}
