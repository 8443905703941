/* tslint:disable:prefer-const */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class TravelApplicationService {
    readonly LIST_PATH = '/travel-application';
    
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem(environment.tokenName),
    };
    url = environment.apiHost + '/lider/travel-application';


    findById(id: string, expand: string = ''): Observable<any> {
        const url = this.url + '/' + id;
        const params = {};
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    getForContract(id: number, expand: string = ''): Observable<any> {
        const url = this.url + '/contract/' + id;
        const params = {};
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    find(params: any): Observable<any> {
        const url = this.url;
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    saveFee(entity) {
        let url = this.url + '/add-fee';
        const headers = this.headers;
    
        if (entity.id) {
          url += '/' + entity.id;
        }
    
        if (entity.id) {
          return this.http.put<any>(url, entity, {headers});
        } else {
          return this.http.post<any>(url, entity, {headers});
        }
      }
      
    save(entity): Observable<any> {
        let url = this.url;
        let headers = this.headers;

        if (entity.id) {
            url += '/' + entity.id;
        }

        if (entity.id) {
            return this.http.put<any>(url, entity, {headers});
        } else {
            return this.http.post<any>(url, entity, {headers});
        }
    }

    cancel(id): Observable<any> {
        let url = this.url + '/cancel/' + id;
        let headers = this.headers;
        return this.http.put<any>(url, {}, {headers});
    }

    setChangeModeStatus(id): Observable<any> {
        let url = this.url + '/change-mode-status/' + id;
        let headers = this.headers;
        return this.http.put<any>(url, {}, {headers});
    }

    setFilledInStatus(id): Observable<any> {
        let url = this.url + '/set-filled-in-status/' + id;
        let headers = this.headers;
        return this.http.put<any>(url, {}, {headers});
    }
}

