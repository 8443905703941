import { Routes } from '@angular/router';
import { InquiryDetailsComponent } from './details/inquiry-details.component';
import { InquiryListComponent } from './inquiry-list.component';

export const INQUIRY_ROUTES: Routes = [
  {
    path: '',
    component: InquiryListComponent
  },
  {
    path: ':id',
    component: InquiryDetailsComponent
  }
]
