import * as moment from "moment";

export const LT_DATE_US = 'YYYY-MM-DD';
export const LT_DATE_RS = 'DD.MM.YYYY';
export const LT_DATETIME_US = LT_DATE_US + ' HH:mm:ss';
export const LT_DATETIME_RS = LT_DATE_RS + ' HH:mm:ss';
export const LT_TIME = 'HH:mm:ss';

export function formatDate(
    date: string,
    formatFrom: string = LT_DATE_US,
    formatTo: string  = LT_DATE_RS
)
{
    return moment(date, formatFrom).format(formatTo);
}

export function formatDatetime(
    datetime: string,
    formatFrom: string = LT_DATETIME_US,
    formatTo: string  = LT_DATETIME_RS
)
{
    return moment(datetime, formatFrom).format(formatTo);
}

export function nowDateTime(
    format: string  = LT_DATETIME_RS
)
{
    return moment().format(format);
}

export function todaysDate(
    format: string  = LT_DATE_RS
)
{
    return moment().format(format);
}