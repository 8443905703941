import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {catchError, debounceTime, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { of, Subject, Subscription} from 'rxjs';
import { FieldConfig } from '../../models/field-config.interface';
import { FormEventService } from '../../form-event.service';
import { AbstractInputComponent } from '../abstract-input.component';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'app-lt-form-autocomplete',
  templateUrl: './form-autocomplete.component.html'
})
export class FormAutocompleteComponent extends AbstractInputComponent implements Field, OnInit{

  isLoading = false;
  errorMessage: string;
  filteredOptions = [];
  inputValue;
  requestBody: any = {};
  options = [];
  config: FieldConfig;
  group: FormGroup;
  control;
  focus$ = new Subject<string>();
  allSub: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private formEventService: FormEventService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allSub.push(this.focus$.subscribe(currentInputText => {
        const filterValue = currentInputText.toLowerCase();
        this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
      })
    );
    
    //custom name for name of the key which is sent in request body
    const searchStringKey = this.config.searchStringKey ? this.config.searchStringKey : 'search_string';
    this.options = this.config?.options ?? [];
    if (this.options.length > 0) {
      this.filteredOptions = this.options;
    }
    this.control = this.group.get(this.config.name);
    this.allSub.push(
      this.control.valueChanges
      .pipe(
        debounceTime(500),
        tap((value) => {
          if (this.options.length) {
            this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(value));
          }
          this.optionSelected('');
          //only remove autocomplete options for api autocomplete
          if (this.config.api){
            this.filteredOptions = [];
          }
          this.errorMessage = '';
          this.requestBody = {};
          if (typeof value !== 'string') {
            // no need to search, the value is changed because something from the list is selected
            return false;
          }

          if (this.config.api && this.config.api.dependentFields) {
            this.config.api.dependentFields.forEach(InputField => {
              const dependsOnField = this.group.get(InputField);
              dependsOnField.statusChanges.subscribe(() => {
                if (dependsOnField.invalid) {
                  this.control.reset();
                  this.control.disable();
                }
              });
              this.inputValue = dependsOnField.value;
              if (this.inputValue.value){     // Pick value if input value is object
                this.inputValue = this.inputValue.value;
              }
              this.requestBody[InputField] = this.inputValue;
            });
          }

          //put into body request aditional params which doesn't have to be in search form
          if (this.config.api && this.config.api.aditionalParams) {
            const params = this.config.api.aditionalParams;
            Object.keys(params).forEach(index => {
              this.requestBody[index] = typeof params[index] != 'function' ? params[index] : params[index]();
            });
          }

          if (this.config.minLength) {
            if (value.length < this.config.minLength) {
              this.errorMessage = this.config.minLength + ' characters minimum to search';
              return false;
            }
          }
          this.isLoading = true;
          this.requestBody[searchStringKey] = value;
        }),
        switchMap(() => {
            if (this.requestBody[searchStringKey]) {
              if (this.config.api) {
                return this.http.get<any>(this.config.api.hostUrl + this.config.api.endpoint, {
                  params: this.requestBody
                });
              } else {
                const data = this.requestBody[searchStringKey] ? this._filter(this.requestBody[searchStringKey] as string) : this.options.slice();
                return of({data});
              }
            } else {
              return of({data: this.filteredOptions});
            }
        })
      )
      /*
      .pipe((err)=> {
        console.log(err);
      })*/
      .subscribe( res => {
        this.isLoading = false;
        if (this.requestBody[searchStringKey] && (res.data === undefined || res.data.length === 0)) {
          this.errorMessage = 'No results found';
        } else {
          if(res.data && res.data.length) {
            if (this.config && this.config.callbackOnResponse) {
              res.data = this.config.callbackOnResponse(res.data);
            }
          }
          this.filteredOptions = res.data;
        }
      })
    );
  }

  optionSelected(event){
    if (event.option !== undefined) {
      if (this.config.eventMessages) {
        this.formEventService.eventEmitter$.next({
          message: this.config.eventMessages[('onChange')] ?? null,
          el: this,
          value: event.option.value
        });
      }
    }
  }

  displayFn(option: {name: string, value: string | number}) {
    return option ? option.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  addNewOption() {
    this.formEventService.eventEmitter$.next({
      message: this.config.eventMessages[('addNewEvent')] ?? null,
      el: this,
      value: this.control.value,
      eventAttributes: this.config?.eventAttributes ?? null
    });
  }
  ngOnDestroy(): void {
    for (const sub  of this.allSub) {
      if(!sub.closed) {
        sub.unsubscribe();
      }
    }
}

}


export function autocompleteValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { invalidAutocomplete: true };
    }
    return null;  /* valid option selected */
  };
}