import { Component, OnInit } from '@angular/core';
import { OrganizerService } from '../organizer.service';
import { Organizer } from '../organizer';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-organizer-list',
  templateUrl: './organizer-list.component.html',
  styleUrls: ['./organizer-list.component.css']
})
export class OrganizerListComponent implements OnInit {
  pageTitle = 'Organizatori';
  organizers: Organizer[] = [];
  constructor(
    private organizerService: OrganizerService,
    private titleService: Title
  )
  {
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.organizerService.getAll()
      .subscribe((organizes) => {
        this.organizers = organizes.success ? organizes.data : [];
      })
  }

}
