import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PeriodsService } from '../open/services/periods.service';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.css']
})
export class PeriodComponent implements OnInit {
  loaded = false;
  columns: Array<any> = [
    { name: 'id', label: 'Period ID' },
    { name: 'date', label: 'Datum', type : 'date' },
    { name: 'start_saldo_total', label: 'Saldo pocetak dana', type : 'money' },
    { name: 'end_saldo_total', label: 'Saldo na kraju dana', type : 'money'},
    { name: 'button', label: 'Prikaži transakcije', tooltip:{title: 'Prikaži transakcije'}, headerPath:'', actions : {name: 'Prikaži transakcije', path: '/periods/',param : 'id','icon': 'preview'} }, 
  ];

  displayedColumns: string[] = this.columns.map(column => column.name);
  dataSource = new BehaviorSubject([]);
    
  constructor(private periodsService: PeriodsService) { }

  ngOnInit() {
    this.periodsService.getPeriods().subscribe(res => {
      if (res['data']) {
        this.dataSource.next(res['data']);
      } 
      this.loaded = true;
    });
  }

}
