<style>
    .client-form__group {
        display: grid;
        grid-template-columns: 1fr 40px;
    }

    .client-form__group:hover {
        background-color: #f2f2f2;
    }

    .client-form__group+.client-form__group {
        padding-top: 15px;
    }

    .client-form__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .clear-field{
        height: 30px;   
        width: 48px;
    }
    .clear-field mat-icon{
        transform: scale(1.5);
    }
    .organizer-select {
        width: 300px;
    }
    @media screen and (max-width: 768px) {
        /* Item sizing */
        .organizer-select {
            width: 100%;
        }
    }
</style>
<ng-container *ngIf="dataLoaded">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <span *ngIf="true">{{title}}</span>
            <span class="example-spacer"></span>

        </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <mat-card class="example-card">
        <mat-card-title>
            <button mat-mini-fab color="primary"><mat-icon>home_work</mat-icon></button> Organizator
        </mat-card-title>
        <mat-card-content >
            <mat-form-field class="organizer-select">
                <mat-label>Odaberite organizatora</mat-label>
                <input  type="text" [formControl]="selectedOrganizer" placeholder="Organizator putovanja" aria-label="Organizator"
                    matInput [matAutocomplete]="autoOperator">
                <mat-autocomplete
                    autoActiveFirstOption #autoOperator="matAutocomplete" [displayWith]="displayOperator">
                    <mat-option *ngFor="let item of organizerList" [value]="item">
                        {{ item.name | titlecase}}
                    </mat-option>
                </mat-autocomplete>
                <button class="clear-field" (click)="emptyOrganizerField()" mat-icon-button matSuffix [attr.aria-label]="'Obriši'">
                    <mat-icon matSuffix>delete</mat-icon>
                </button>
            </mat-form-field>
        </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
        <mat-card-title>
            <button mat-mini-fab color="primary"><mat-icon>work_outline</mat-icon></button> Aranžman
        </mat-card-title>
        <mat-card-content>
            <dynamic-form #arrangementForm [config]="arrangementConfig"></dynamic-form>
        </mat-card-content>
    </mat-card>

    <mat-card id="usluge-card" class="card-item">
        <mat-toolbar class="no-padding">
        <span><button mat-mini-fab color="primary"><mat-icon>shopping_cart</mat-icon></button> Usluge</span>
        <span class="example-spacer"></span>
        <button mat-stroked-button color="primary" (click)="showPriceAddEditDialog()"><mat-icon>add</mat-icon>Dodaj uslugu</button>
        </mat-toolbar>
        <mat-card-content>
        <app-lt-table
            [columns]="priceListColumns"
            [dataSource]="priceTableData"
            (tableEvent)="onPriceTableEvent($event)"
            ></app-lt-table>
        </mat-card-content>
    </mat-card>
    
    <!-- Putnici-->
    <mat-card class="card-item">
        <mat-toolbar class="no-padding">
            <div><button mat-mini-fab color="primary"><mat-icon>perm_identity</mat-icon></button> Putnici</div>
            <span class="example-spacer"></span>
            <mat-form-field class="contact-search__item">
                <input name="kupac" matInput placeholder="Nadji putnika" aria-label="Nadji putnika"
                    [matAutocomplete]="auto" [formControl]="selectedClient">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayClient"
                    (optionSelected)="addClient($event.option)">
                    <mat-option *ngFor="let item of clientSearchList" [value]="item">
                        <span>{{item.name}}</span> |
                        <small>Detaljno: {{item.lastname}}</small>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            &nbsp;
            &nbsp;
            <button mat-stroked-button color="primary" (click)="addClient({value: {}})"><mat-icon>add</mat-icon>Dodaj
                putnika</button>
        </mat-toolbar>
        <mat-card-content>
            <div class="client-form__wrapper">
                <div *ngFor="let formGroup of clientsForm.controls; let i = index">
                    <h4>Putnik #{{i+1}}</h4>
                    <div class="client-form__group">
                        <dynamic-form #customers [form]="getClientForm(i)"
                            [config]="clientsFormConfig[i]"></dynamic-form>

                        <div class="client-form__actions">
                            <mat-icon matListAvatar>account_circle</mat-icon>
                            <div>#{{i}}</div>
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="deleteClient(i)">
                                    <mat-icon mat-list-icon>delete</mat-icon>
                                    <span>Obriši</span>
                                </button>
                            </mat-menu>
                        </div>
                        <div class="clientDetails">
                            <b (click)="clientAditionalFormShowHide[i].visible = !clientAditionalFormShowHide[i].visible"
                                class="showHideClientDetails">Dodatni podaci o putniku
                                <mat-icon>{{clientAditionalFormShowHide[i].visible ? 'arrow_drop_down' :
                                    'arrow_right'}}</mat-icon>
                            </b>
                            <dynamic-form *ngIf="clientAditionalFormShowHide[i].visible" [form]="getClientFormDetail(i)"
                                [config]="clientsAditionalFormConfig[i]"></dynamic-form>
                        </div>
                    </div>
                </div>

            </div>
        </mat-card-content>
    </mat-card>
    <!-- END  Putnici -->

    <mat-toolbar class="page-actions">

        <button [disabled]="isLoading" mat-raised-button (click)="save()" color="primary">
            <mat-icon>save</mat-icon> Sačuvaj promene
        </button>&nbsp;&nbsp;
        <button *ngIf="id && !isChangeMode && isStatusFilledIn" [disabled]="!id" mat-stroked-button (click)="setChangeModeStatus()" color="primary">
            <mat-icon>lock_open_right</mat-icon> Otključaj rez. za dopune
        </button>
        <button *ngIf="id && isChangeMode" [disabled]="!id" mat-stroked-button (click)="setFilledInStatus()" color="primary">
            <mat-icon>check_circle</mat-icon> Označi rez. kao popunjenu
        </button>
        <button [disabled]="!id" mat-button (click)="copyToClipboard()" color="primary">
            <mat-icon>content_copy</mat-icon> Kopiraj link rez.
        </button>


    </mat-toolbar>
</ng-container>