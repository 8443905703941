import { Component, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'lt-inline-select',
  styleUrls: [],
  template: `

  <mat-form-field style="display:flex">
  <mat-label>{{config.placeholder}}</mat-label>
  <mat-select (blur)="onBlur($event)" (selectionChange)="selectionChanged($event)" name="config.name" [formControl]="select" #selectId> 
      <mat-option *ngFor="let option of config.options" [value]="option.id"> {{ option.name }}</mat-option>
  </mat-select>
</mat-form-field>
  `
})
export class InlineSelectComponent implements OnInit {
  config;    
  select = new UntypedFormControl();
  oldValue;
  editedData;

  @Output() inlineEvent = new EventEmitter<{message: string, value: any, oldValue: any}>();

  @ViewChild('selectId') selectId: MatSelect;
  constructor(private formEventService: FormEventService) {
  }

  ngOnInit(): void {
    this.select.setValue(this.config.value);
    this.oldValue = this.config.value;
    setTimeout(()=>{
        this.selectId.focus();
    })
  }

  onBlur($event){
    //do not trigger blur because slectionChange event will trigger and send updated value
    if ($event.relatedTarget && $event.relatedTarget.matches('mat-option')) {
      return;
    }
    this.formEventService.eventEmitter$.next({
        message : this.config.eventName,
        value : this.select.value,
        oldValue : this.oldValue,
        editedData : this.editedData,
    });
  }
  selectionChanged($event) {
    this.formEventService.eventEmitter$.next({
        message : this.config.eventName,
        value : this.select.value,
        oldValue : this.oldValue,
        editedData : this.editedData,
    });
  }
  
}
