import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {prepareFormGroup} from '../../shared/helpers/form';
import { DynamicFormComponent } from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { TransactionService } from 'src/app/transaction-new/transaction.service';

export interface FiscalRequest {
  invoiceType: number;
  transactionType: number;
  payment: Array<{amount: number, paymentType: number}>;
  items: Array<{name: string, quantity: number, unitPrice: number, totalAmount: number, labels: Array<string>}>;
}

@Component({
  selector: 'app-fiscal-receipt-generate',
  styleUrls: ['./fiscal-receipt-generate.component.scss'],
  template: `
    <h2 mat-dialog-title>Generiši fiskalni isečak/fakturu</h2>
    <mat-dialog-content class="mat-typography">
      <h4>Način plaćanja</h4>
      <div class="payment-options">
        <mat-checkbox *ngFor="let option of paymentTypeOptions" [(ngModel)]="selectedOptions[option.value]['checked']" (change)="paymentOptionChanged(option.value)">{{option.name}}</mat-checkbox>
      </div>
      
        <div [formGroup]="paymentAmounts" class="payment-amounts-form">
          <mat-form-field  *ngFor="let item of paymentAmountKeys(); let j = index">
            <input matInput [placeholder]="selectedOptions[item]['name']" [formControlName]="item" [value]="0">
          </mat-form-field>
        </div>

        <h4>Lista usluga</h4>
        <div class="row-inline" *ngFor="let formGroup of fiscalForms.controls; let i = index">
          <dynamic-form [class]="'fiscal fiscal-' + i" [form]="getFiscalForm(i)" [config]="fiscalReceiptsConfig[i]"></dynamic-form>             
          <button mat-button class="delete-btn" (click)="deleteFiscal(i)">
              <mat-icon  mat-list-icon >delete</mat-icon>
              <span>Obriši</span>
          </button>
        </div>
        <button mat-stroked-button color="primary" (click)="addFiscal()">
       <mat-icon>add</mat-icon> Dodaj uslugu / proizvod
        </button>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Odustani</button>
        <button mat-raised-button color="primary" (click)="closeDialog()">Fiskalizuj</button>
    </mat-dialog-actions>
  `,
})
export class FiscalReceiptGenerateComponent implements OnInit {

  fiscalReceiptsConfig = [];
  fiscalForms = new UntypedFormArray([]);
  paymentTypes = new UntypedFormGroup({
    type: new UntypedFormControl([1]),
  }); //kes
  paymentAmounts = new UntypedFormGroup({})

  paymentTypeOptions = [
    {value: 1, name: 'Keš'},
    {value: 2, name: 'Kartica'},
    {value: 3, name: 'Ček'},
    {value: 4, name: 'Virman'},
    {value: 5, name: 'Vaučer'},
    {value: 0, name: 'Ostalo'},
  ];
  paymentOptionsMappings = [];

  selectedOptions = {};
  constructor(
    private matDialogRef: MatDialogRef<FiscalReceiptGenerateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string;
      quantity: string;
      unitPrice: number;
      invoiceType: number;
      transactionType: number;
      paymentType: Array<{rawData: {type: string, amount: number}}>;
    },
    private fb: UntypedFormBuilder,
    private respMsg: ResponseMessageService,
    private transactionService: TransactionService
  ) {
  }

  ngOnInit(): void {
    this.transactionService.getAccountTypes().subscribe(account => this.paymentOptionsMappings = account);
    this.paymentTypeOptions.forEach(option => {
      const moneyType = option.value;
      const moneyTypeInternal = this.paymentOptionsMappings.find(el => el.fiscalId == moneyType)?.id;
      this.selectedOptions[moneyType] = {
        checked: this.data?.paymentType && this.data.paymentType.find( el => parseInt(el.rawData.type) == moneyTypeInternal) ? true : false,
        name: option.name
      };
    });

    this.setFiscalReceiptConfig(this.data);
    this.setPaymentTypeAmounts();
  }

  paymentAmountKeys() {
    return Object.keys(this.paymentAmounts.controls);
  }
  
  paymentOptionChanged(paymentType) {
    const isSelected = this.selectedOptions[paymentType]['checked'];
    if (isSelected) {
        this.paymentAmounts.addControl(paymentType, this.fb.control(0));
    } else {
      delete this.paymentAmounts.controls[paymentType];
    }
  }

  setPaymentTypeAmounts() {
    const paymentTypes = this.paymentTypes.controls['type'].value ?? [];
    const paymentAmounts = this.paymentAmounts as UntypedFormGroup;
    Object.keys(this.selectedOptions).forEach(type => {
      if (this.selectedOptions[type]['checked'] == true) {

        const moneyTypeInternal = this.paymentOptionsMappings.find(el => el.fiscalId == type)?.id;
        const transactionsInfo = this.data.paymentType.find( el => parseInt(el.rawData.type) == parseInt(moneyTypeInternal))
        const amount = transactionsInfo ? transactionsInfo.rawData.amount : 0;
        paymentAmounts.addControl(type, this.fb.control(amount));
      }
    });
  }

  setFiscalReceiptConfig(values: {
    name: string,
    quantity: string,
    unitPrice: number,
  } | null = null) {
    
    const config: FieldConfig[] = [
      {
        type: 'input',
        label: 'Opis usluge',
        name: 'name',
        placeholder: 'Opis usluge',
        validation: [Validators.required],
        value: values?.name || '',
        col:5
      },
      {
        type: 'input',
        label: 'Količina',
        name: 'quantity',
        placeholder: 'Količina',
        validation: [Validators.required],
        value: values?.quantity || 1,
        col:2
      },
      {
        type: 'input',
        label: 'Cena',
        name: 'unitPrice',
        placeholder: 'Cena',
        validation: [Validators.required],
        value: values?.unitPrice || '',
        col:2
      },
      {
        type: 'select',
        label: 'PDV kategorija',
        name: 'labels',
        multiple: true,
        validation: [Validators.required],
        options: [
            { id: 'А' , name: 'А'}
        ],
        placeholder: 'PDV kategorija',
        col:3
      }
    ];

    this.fiscalReceiptsConfig.push(config);
    this.fiscalForms.push(prepareFormGroup(config));
  }
  
  getFiscalForm(index) {
    return this.fiscalForms.at(index) as UntypedFormGroup;
  }
  addFiscal() {
    this.setFiscalReceiptConfig();
  }
  deleteFiscal(index) {
    this.fiscalForms.removeAt(index);
    this.fiscalReceiptsConfig.splice(index,1);
  }

  closeDialog() {

    let invalid = false;
    let items  = [];
    let totalServices = 0;
    for (let form of this.fiscalForms.controls) {
      if(form.invalid) {
        invalid = true;
        form.markAllAsTouched();
      }
      let properties = form.getRawValue();
        properties.totalAmount = properties.quantity * properties.unitPrice;
        totalServices += properties.totalAmount;
        items.push(properties);
      }
    if (invalid) {
      this.respMsg.showError('Ispunite / selektujte sva obavezna polja');
      return;
    }
    const amountsPerMoneyType =this.paymentAmounts.getRawValue();
    const payments = [];
    let totalPayments = 0;
    Object.keys(amountsPerMoneyType).forEach(type => {
      const amount = parseFloat(amountsPerMoneyType[type]);
      payments.push({
        amount: amount,
        paymentType: type  
      });
      totalPayments+= amount;
    });
    if (totalServices !== totalPayments) {
      this.respMsg.showError('Ne slaže se total u načinu plaćanja: '+totalPayments+' sa totalom usluga: ' + totalServices);
      return;
    }
    let fiscalData: FiscalRequest = {
      invoiceType: 0,
      transactionType:0,
      payment: payments,
      items,
    };
    
    this.matDialogRef.close(fiscalData);
  }

}
