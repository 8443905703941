import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResponseBody } from 'src/app/model/response-body';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class GeneralDataService {
    url =  environment.apiHost + '/lider/general';
    emitter$ = new Subject();

    constructor(protected http: HttpClient) {}
    get() {
        //@todo duplicated in inquiry list
        let params = {
            'inq_status' : '10,30,40',
            'inq_status_id' : '10'
        };
        this.http.get<ResponseBody<any[]>>(this.url + '/data', {params}).subscribe({
            next: (resp: any) => {
                this.emitter$.next({data: resp.data});
            }
        });
    }
    
}