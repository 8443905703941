export const PRICE_LIST_COLUMNS = [
    { name: 'name', label: 'Naziv' },
    { name: 'quantity', label: 'Količina', className: 'align-center' },
    { name: 'price_formatted', label: 'Cena', type: 'money-object' },
    { name: 'discount_formatted', label: 'Popust', type: 'money-object' },
    { name: 'total_formatted', label: 'Ukupno', type: 'money-list' },
    { name: 'supplier_price_formatted', label: 'Cena dobavljača', type: 'money-object' },
    { name: 'supplier_total_formatted', label: 'Dobavljač ukupno', type: 'money-object' },
    { name: 'comment', label: 'Komentar' },
    {
        name: 'button',
        label: 'Akcije',
        actions: [
            {
                type: 'event',
                actionName: 'edit-fee',
                name: 'Uredi',
                icon: 'edit'
            },
            {
                type: 'event',
                actionName: 'delete-fee',
                name: 'Obriši',
                icon: 'delete'
            }
        ]
    }
];