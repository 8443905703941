import { Component, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contract-print',
  templateUrl: './contract-print.component.html',
  styleUrls: ['./contract-print.component.scss']
})
export class ContractPrintComponent implements OnInit {
    html: SafeHtml = '';
    contractId: string;
    constructor(
        private contractService: ContractService,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
    }

  ngOnInit() {
    this.contractId = this.route.snapshot.paramMap.get('id')
    this.contractService.getContractPrintHtml(this.contractId).subscribe(
      resp => {
        this.html = this.domSanitizer.bypassSecurityTrustHtml(resp.data);
      },
      err => {
        alert(err?.error?.message ?? 'Nepoznata greška se desila');
      }
      );
  }

}
