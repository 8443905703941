import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Organizer} from './organizer';

@Injectable()
export class OrganizerService {
    url = environment.apiHost + '/lider/organizer';
    cisUrl = environment.apiHost + '/lider/cis'; //@todo on two places

    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };

    constructor(private http: HttpClient) {
    }

    dataList: any[] = [];

    getAll(): Observable<{
        success: boolean;
        data: Organizer[]
    }> {
        return this.http.get<any>(this.url, {
            params: {
                sort: '-id'
            }, headers: {
                ...this.headers
            }
        });
    }

    findById(id: number | string): Observable<{
        success: boolean;
        data: Organizer
    }> {
        let params = {};
        let headers = this.headers;
        return this.http.get<any>(this.url + '/' + id, {params, headers});
    }
    
    load(filter: object): void {
        this.find(filter).subscribe(
            result => {
                this.dataList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: object): Observable<any[]> {

        let params = {
            sort: '-id'
        };
        if(filter['name']){
            params['name'] = filter['name'];
        }
        //params['page'] = data['page'];
        //params['per-page'] = data['perPage'];
        let headers = this.headers;

        return this.http.get<any[]>(this.url, {params, headers});
    }

    save(entity: any): Observable<any> {
        const headers = this.headers;
        if (entity.id) {
            //update
            return this.http.put<any>(this.url + '/' + entity.id, entity, {headers});
        } else {
            //create
            return this.http.post<any>(this.url, entity, {headers});
        }
    }

    findOrganizerByJib(id: number | string): Observable<{
        success: boolean;
        data: any
    }> {
        let params = {};
        let headers = this.headers;
        return this.http.get<any>(this.cisUrl + '/fetch-organizer/' + id, {params, headers});
    }
}

