import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TransactionFilter } from '../transaction-filter';
import { TransactionService } from '../transaction.service';
import { Transaction } from '../transaction';

@Component({
    selector: 'transaction',
    templateUrl: 'transaction-list.component.html'
})
export class TransactionListComponent {

    filter = new TransactionFilter();
    selectedTransaction: Transaction;
    get transactionList(): Transaction[] {
        return this.transactionService.transactionList;
    }

    constructor(private transactionService: TransactionService) {
    }

    ngOnInit() {
      this.search();

    }

    search(): void {
        this.transactionService.load(this.filter);
    }

    select(selected: Transaction): void {
        this.selectedTransaction = selected;
    }

}
