/* tslint:disable:prefer-const */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseBody } from 'src/app/model/response-body';


@Injectable()
export class AgencySettingsService {
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
    };
    url = environment.apiHost + '/lider/agency';
    
    findById(id: number | string): Observable<{
        success: boolean;
        data: any
    }> {
        let params = {};
        let headers = this.headers;
        return this.http.get<any>(this.url + '/' + id, {params, headers});
    }

    saveSettings(entity): Observable<any> {
        let url = this.url + '/update-agency-settings';
        let headers = this.headers;
        return this.http.post<any>(url, entity, {headers});
    }
}

