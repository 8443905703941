<mat-progress-bar *ngIf="isSearching" mode="indeterminate"></mat-progress-bar>
<app-lt-filter *ngIf="filterConfig.length > 0" [filterConfig]="filterConfig" (filterChanged)="onFilterChanged($event)"></app-lt-filter>
<app-lt-table
    [columns]="tableColumns"
    [toolbarButtons]="toolbarButtons"
    [dataSource]="dataSource"
    [options]="tableOptions"
    (tableEvent)="onTableEvent($event)"
    ></app-lt-table>
    
    <app-lt-pagination
        [totalCount]="totalRowsCount"
        [pageSize]="pageSize"
        (paginationEvent)="paginationChanged($event)"></app-lt-pagination>