import { Component, OnInit, ViewChild } from '@angular/core';
import { TableColumn, TableEvent } from '../../shared/lt-table/lt-table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { finalize } from 'rxjs/operators';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { LtFilterComponent } from 'src/app/shared/lt-filter/lt-filter.component';
import { LtPaginationComponent } from 'src/app/shared/pagination/lt-pagination.component';
import { OrganizerService } from 'src/app/organizer/organizer.service';
import { TravelApplicationService } from './travel-application.service';
import { formatDate, formatDatetime } from 'src/app/shared/helpers/date';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { environment } from 'src/environments/environment';

export enum ReservationStatus {
  UNCONFIRMED = 0,
  FILLED_IN = 1,
  CANCELED = 10,
  CONTRACT_CREATED = 20,
  CHANGE_MODE = 30,
}

@Component({
  selector: 'app-lt-travel-application-list',
  templateUrl: './travel-application-list.component.html',
})
export class TravelApplicationListComponent implements OnInit {
  readonly CANCEL_EVENT_MSG = 'lt-cancel-reservation';
  pageTitle = 'Rezervacije';
  LIST_PATH = this.travelApplicationService.LIST_PATH;
  dataSource: any[] = [];
  subscriptions: Subscription[] = [];
  filterConfig: FieldConfig[] = [];
  isSearching: boolean = false;
  tableColumns: TableColumn[] = [
    {
      name: 'actions',
      label: '',
      headerPath: '/travel-application/new',
      tooltip : {
        title: 'Dodaj novu rezervaciju',
        position: 'above'
      },
      actions: [
        {
          type: 'event',
          actionName: this.CANCEL_EVENT_MSG,
          name: 'Storniraj',
          icon: 'cancel'
        }]
    },
    {
      name: 'applicationNo', label: 'Br.Prijave', type: 'url', sort: true,
      url: {
        route: '/travel-application/',
        idParam: 'id'
      }
    },
    {
      name: 'createContract',
      label: 'Ugovor',
      type: 'url',
      icon: 'add'
    },
    { name: 'dateTime', label: 'Datum/Vreme' },
    { name: 'statusprijave', label: 'Status', type: 'chip' },
    {
      name: 'applicationUrl', label: 'Link prijave', type: 'url'
    },
    { name: 'name', label: 'Putnik', sort: true },
    { name: 'destination', label: 'Aranžman', sort: true },
    { name: 'entryCity', label: 'Mesto ulaska putnika', sort: true },
    { name: 'departureDate', label: 'Polazak', sort: true },
    { name: 'returnDate', label: 'Povratak', sort: true },
    { name: 'room', label: 'Soba', sort: true },
    { name: 'service', label: 'Usluga', sort: true },
    { name: 'hotel', label: 'Hotel', sort: true },
    { name: 'phone', label: 'Telefon' },
    { name: 'email', label: 'Email' },
    { name: 'jmbg', label: 'JMBG' },
    { name: 'passport', label: 'Broj pasoša' },
    { name: 'address', label: 'Adresa' },
    {
      name: 'idInquiry', label: 'Br. upita'
    },
    /*
    {
      name: 'button',
      label: 'Akcije',
      actions: [
        {
          type: 'event',
          actionName: 'lt-edit-book-of-contracts',
          name: 'Izmeni',
          icon: 'edit'
        }
      ]
    },
    */
  ]

  totalRowsCount = 0;
  pageSizeDefault = 100;
  pageSize;
  searchValues: any = {};
  organizers = [];
  readonly LIDER_TRAVEL_APPLY_URL = environment.reservation_url; //@todo use environment

  @ViewChild(LtFilterComponent) filter: LtFilterComponent;
  @ViewChild(LtPaginationComponent) pagination: LtPaginationComponent;

  constructor(
    private travelApplicationService: TravelApplicationService,
    private organizerService: OrganizerService,
    private formEventService: FormEventService,
    private _snackBar: MatSnackBar,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private responseMessageService: ResponseMessageService
  ) {

    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(queryParams => {
        this.pageSize = queryParams.pageSize ?? this.pageSizeDefault;
        this.searchValues = { pageSize: this.pageSize, ...queryParams };
        this.getRows(this.searchValues);
      })
    );
  }

  getRows(filter?: any) {
    this.isSearching = true;
    forkJoin({
      organizers: this.organizers.length ?
        ((): Observable<any> => { return of({ data: this.organizers }) })() : this.organizerService.getAll(),
      mainData: this.travelApplicationService.find(filter)
    })
      .pipe(
        finalize(() => {
          this.isSearching = false;
        })
      )
      .subscribe({
        next: (response) => {
          //only load first time
          if (!this.organizers.length) {
            this.organizers = this.organizers = response.organizers.data.map(el => {
              return {
                id: el.id,
                name: el.name,
              }
            });
          }
          //set here because it depends on organizers
          this.setFilterFormConfig();

          let mainData = response.mainData;
          if (mainData.data) {
            this.totalRowsCount = mainData?.meta?.totalCount;
            this.dataSource = mainData.data.map(row => {
              row.applicationNo = {
                text: row.id ? '#' + row.id : '__',
                idParam: row.id
              };
              let checklist = {};
              if (row.checklist) {
                row.checklist.forEach(item => {
                  checklist[Number(item.checklist_type_id)] = item.checklist_value;
                });
              }
              const arrangement = row.arrangements[0] ? row.arrangements[0] : {};
              const customer = row.customers.find(customer => customer.contract_holder == 1) ?? {};
              const statusObj = { text: '', class: '', icon: '' };
              let statusName = 'Nepopunjena';
              let statusClass = 'mat-chip-orange';
              let statusIcon = 'alarm';
              if (row.status == ReservationStatus.FILLED_IN) {
                statusName = 'Popunjena';
                statusClass = 'mat-chip-blue';
                statusIcon = 'check_circle';
              }
              if (row.status == ReservationStatus.CANCELED) {
                statusName = 'Stornirano';
                statusClass = 'mat-chip-red';
                statusIcon = 'cancel';
              }
              if (row.status == ReservationStatus.CONTRACT_CREATED) {
                statusName = 'Kreiran ugovor';
                statusClass = 'mat-chip-green';
                statusIcon = 'done';
              }

              if (row.status == ReservationStatus.CHANGE_MODE) {
                statusName = 'Delimično popunjeno';
                statusClass = 'mat-chip-purple';
                statusIcon = 'incomplete_circle';
              }

              statusObj.text = statusName;
              statusObj.class = statusClass;
              statusObj.icon = statusIcon;
              const contract = row.contract;
              const contractId = contract?.id ?? 'fromApplication-' + row.id;
              const contractUrlText = contract?.id ? '#' + contract.incremental_number : 'Kreiraj ugovor';
              const columnActions = this.tableColumns.find(el => el.name == 'actions');

              return {
                ...row,
                applicationUrl: {
                  text: row.id ? row.hash_id : '__',
                  href: this.LIDER_TRAVEL_APPLY_URL + row.hash_id,
                },
                createContract: {
                  href: '/contract/' + contractId,
                  text: contractUrlText,
                  target: '_self'
                },
                actions: row.status != ReservationStatus.CANCELED ? columnActions.actions : columnActions.actions.filter(el => el.actionName != this.CANCEL_EVENT_MSG),
                statusprijave: statusObj,
                dateTime: formatDatetime(row.created_at),
                name: customer['customer_lastname'] + ' ' + customer['customer_name'],
                entryCity: customer?.entryCity?.name ?? '',
                destination: arrangement?.arrangement_name,
                hotel: arrangement?.arrangment_accomodation,
                departureDate: formatDate(arrangement?.date_from),
                returnDate: formatDate(arrangement?.date_to),
                //provider: element.organizer_agency,
                //organizer_contract_no: element.organizer_contract_no,
                //organizer_guaranty_number: element.organizer_guaranty_number,
                //departureDate: formatDate(arrangement?.date_from),
                room: arrangement?.room_name,
                service: arrangement?.service,
                phone: customer['customer_phone'],
                email: customer['customer_email'],
                jmbg: customer['personal_id'],
                passport: customer['customer_passport_no'],
                address: customer['customer_address'] + ', ' + (customer['customer_city'] ?? ''),
                idInquiry: row.inquiry_id,
              };
              /*
              { name: 'entryCity', label: 'Mesto ulaska putnika', sort: true },
              
              { name: 'phone', label: 'Telefon' },
              { name: 'email', label: 'Email' },
              { name: 'jmbg', label: 'JMBG' },
              { name: 'passport', label: 'Broj pasoša' },
              { name: 'address', label: 'Adresa' },
              */
            });
          }
        },
        error: (err) => {
          this.showError(err.error.message);
        }
      });
  }

  paginationChanged(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          pageIndex: event.data.pageIndex,
          pageSize: event.data.pageSize
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  showError(message: string, action = null) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }

  onTableEvent(event: TableEvent) {
    if (event.name == 'ltTableSortChanged') {
      const sortedCol = event?.event?.active;
      const sortOrder = event?.event?.direction;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { sortCol: sortedCol, sortOrder: sortOrder },
          queryParamsHandling: 'merge'
        }
      );
    }

    if (event.name == this.CANCEL_EVENT_MSG) {
      const data = event.data;
      const reservationId = data.id ?? null;
      if (reservationId) {
        this.travelApplicationService.cancel(reservationId).subscribe(res => {
          if (res['success']) {
            this.responseMessageService.showSuccess();
            this.router.navigate(
              [],
              {
                relativeTo: this.route,
                queryParams: { saved: Date.now()},
                queryParamsHandling: 'merge'
              }
            );
          }
        }, err => {
          this.responseMessageService.showError(err?.error?.message);
        });
      }
    }
  }

  onFilterChanged(filter) {
    filter = {
      ...filter,
      ...this.pagination.defaultValues,
      pageSize: this.pageSize
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: filter,
        queryParamsHandling: 'merge'
      }
    );
  }

  setFilterFormConfig() {
    /*
    const filterConfig: FieldConfig[] = [
      {
        type: 'input',
        label: 'Br. Ugovora',
        name: 'id',
        placeholder: 'Br Ugovora',
        value: this.searchValues?.id ? this.searchValues.id : '',
        col: 1
      },
      {
        type: 'datepicker',
        label: 'Polazak od',
        name: 'departure_from',
        placeholder: 'Polazak od',
        value: this.searchValues?.departure_from ? this.searchValues.departure_from : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Polazak do',
        name: 'departure_to',
        placeholder: 'Polazak do',
        value: this.searchValues?.departure_to ? this.searchValues.departure_to : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Povratak od',
        name: 'return_from',
        placeholder: 'Povratak od',
        value: this.searchValues?.return_from ? this.searchValues.return_from : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Povratak do',
        name: 'return_to',
        placeholder: 'Povratak do',
        value: this.searchValues?.return_to ? this.searchValues.return_to : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Prevoz',
        name: 'transport',
        options: [
          {
            id: '',
            name: 'Odaberi prevoz',
          },
          {
            id: 1,
            name: 'Bus'
          },
          {
            id: 2,
            name: 'Avio'
          },
          {
            id: 3,
            name: 'Sopstveni'
          }],
        placeholder: 'Prevoz',
        //validation: [Validators.required],
        value: this.searchValues?.transport ? Number(this.searchValues.transport) : '',
        col: 1
      },
      {
        type: 'input',
        label: 'Aranžman',
        name: 'arr',
        placeholder: 'Aranžman',
        value: this.searchValues?.arr ? this.searchValues.arr : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Smeštaj',
        name: 'hotel',
        placeholder: 'Smeštaj',
        value: this.searchValues?.hotel ? this.searchValues.hotel : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Ime',
        name: 'customer_name',
        placeholder: 'Ime putnika',
        value: this.searchValues?.customer_name ? this.searchValues.customer_name : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Prezime',
        name: 'customer_lastname',
        placeholder: 'Prezime putnika',
        value: this.searchValues?.customer_lastname ? this.searchValues.customer_lastname : '',
        col: 2
      },
      {
        type: 'input',
        label: 'JMBG',
        name: 'customer_personal_id',
        placeholder: 'JMBG  putnika',
        value: this.searchValues?.customer_personal_id ? this.searchValues.customer_personal_id : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Telefon',
        name: 'customer_phone',
        placeholder: 'Broj telefona',
        value: this.searchValues?.customer_phone ? this.searchValues.customer_phone : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Organizator',
        name: 'organizer',
        options: [
          {
            id: '',
            name: 'Odaberi organizatora'
          },
        ...this.organizers
        ],
        placeholder: 'Organizator',
        //validation: [Validators.required],
        value: this.searchValues?.organizer ? this.searchValues.organizer : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Br. ug. organizatora',
        name: 'org_contr',
        placeholder: 'Br. ug. organizatora',
        value: this.searchValues?.org_contr ? this.searchValues.org_contr : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Br. garancije',
        name: 'guar_no',
        placeholder: 'Br. garancije',
        value: this.searchValues?.guar_no ? this.searchValues.guar_no : '',
        col: 2
      },
      {
        type: 'checkbox',
        label: 'Sakrij prošle datume',
        name: 'hideOldDates',
        value: this.searchValues?.hideOldDates === 'true' ? true : false,
        eventMessages: {
          onChange: 'ltHideOldDatesEvt'
        },
        col: 2
      }

    ];*/

    // set default col size for each input
    /*
    this.filterConfig = filterConfig.map(formControl => {
      return {
        ...formControl,
        col: formControl.col || 3
      }
    });
    */
  }
  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}
