import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';

@Component({
  selector: 'form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
    <div 
      class="dynamic-field form-button"
      [formGroup]="group">
      <button
        [disabled]="config.disabled"
        [color]="config.color ? config.color : 'warn'"
        mat-raised-button>
        <mat-icon *ngIf="config.icon" >{{config.icon}}</mat-icon>
        {{ config.label }}
      </button>
    </div>
  `
})
export class FormButtonComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
