import { Component, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';

@Component({
  selector: 'form-input-hidden',
  //styleUrls: ['form-input.component.scss'],
  template: `
  <section [formGroup]="group" >
      <input type="hidden"  [(value)]="config.value" [formControlName]="config.name">
  </section>   
  `
})
export class FormInputHiddenComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
 