import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from './../../environments/environment';
import {JWT_OPTIONS,JwtHelperService} from '@auth0/angular-jwt';

import {AuthenticationService} from '../_services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authenticationService: AuthenticationService, private router: Router, private jwtHelperService: JwtHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {
        let token = localStorage.getItem(environment.tokenName);
        if (token) {
            if(this.jwtHelperService.isTokenExpired(token)){
                localStorage.removeItem(environment.tokenName);
                localStorage.removeItem('LtMotherToken');

                return false;
            }
            // logged in so return true
            return true;
        }

        // Store the attempted URL for redirecting
        this.authenticationService.returnUrl = url;

        // Navigate to the login page with extras
        this.router.navigate(['/login'], {queryParams: {r: url}});
        return false;
    } 
}
