<style>
  table{
     width: 100%;
 }
</style>
<mat-toolbar >    
 <mat-toolbar-row>
     <span>Arhiva | LiderTravel</span>
     <span class="example-spacer"></span>
 </mat-toolbar-row>
</mat-toolbar>
<ng-container *ngIf="loaded">
  <table mat-table [dataSource]="dataSource" class="general-table">
    <ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}">
    <ng-container *ngIf="column.name != 'actions'">
        <th mat-header-cell *matHeaderCellDef [class.align-right]="column.type && column.type == 'money'">
            {{column.label}}
        </th>
    </ng-container>
    <ng-container *ngIf="column.name == 'actions'">
        <th mat-header-cell *matHeaderCellDef>
            
        </th>
    </ng-container>
    
    <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column.name != 'actions'">
            <div *ngIf="column.type && column.type == 'money'">
                <span style="display:block;text-align:right;padding-right: 10px;">
                    {{element[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}
                </span>
            </div>
            <div *ngIf="column.type && column.type == 'date'">
                    {{element[column.name] | date: 'dd.MM.yyyy'}}
            </div>
            <div *ngIf="!column.type">
                {{element[column.name]}}
            </div>
        </ng-container>
        <ng-container *ngIf="column.name == 'button'">
            <a class="row-action" [routerLink]="[column.actions.path+element[column.actions.param]]"> 
                <button mat-raised-button color="warn">
                    <mat-icon>{{column.actions.icon}}</mat-icon>
                    <span>{{column.actions.name}}</span>
                </button>
            </a>
        </ng-container>
        <div *ngIf="column.name == 'actions'">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <a class="row-action" *ngFor="let action of column.actions" target="_blank" [routerLink]="[action.path+element[action.param]]">
                    <button mat-button color="primary">
                            <mat-icon>{{action.icon}}</mat-icon>
                            <span>{{action.name}}</span>
                    </button>
                </a>
            </mat-menu>
        </div>          
    </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-container>
