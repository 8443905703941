import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class PassengerListService {
    readonly CANCELLED_CONTRACT = 4;
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
    };
    url = environment.apiHost + '/lider/passenger-list';

    findById(id: string): Observable<any> {
        const url = this.url + '/' + id;
        const params = {};
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    updateChecklistItem(id, checklistTypeId, status): Observable<unknown> {
        let url =  this.url + '/set-or-delete';
        let headers = this.headers;
        return this.http.post<unknown>(url, {id, checklistTypeId, status}, {headers});
    }
}

