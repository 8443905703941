import { Component, Inject, OnInit, Optional } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {setFormValue} from '../../shared/helpers/form';
import { Transaction } from '../Transaction';
import { TransactionService } from '../transaction.service';

interface DialogDataInterface {
  contractId?: number;
  isInDialog?: boolean;
  referenceEntity?: number | null;
  reasonType?: number | null;
  account_type?: number | null;
  supplierId?: number | null;
  in_out?: number | null;
  entityId?: number | null;
  suppliers?: any[] | null;
  comment?: string;
}

@Component({
  selector: 'app-transaction-edit',
  templateUrl: './transaction-edit.component.html',
  //styleUrls: ['./transaction-edit.component.css']
})
export class TransactionEditComponent implements OnInit {

  entityForm = new UntypedFormGroup({});
  entity: Transaction | null = null;
  entityId: string | null = null;
  dialogData: DialogDataInterface = {};
  suppliers = [];
  isLoading = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private entityService: TransactionService,
    private _snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<TransactionEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogDataInterface,
  ) {
    this.dialogData = data ? data : {};
    const entityId = this.activatedRoute.snapshot.params.id;
    this.entityId = entityId !== 'new' && !this.dialogData.isInDialog  ? entityId : null;
    if (this.dialogData.suppliers) {
      this.suppliers = this.dialogData.suppliers;
    }
  }

  ngOnInit(): void {
    if(this.entityId) {
      this.entityService.findById(this.entityId)
        .subscribe((response)=> {
          if(response.success) {
            this.entity = response.data;
            setFormValue(this.entityForm, this.entity);
          }
        })
    } else {
      this.entity = {
        reason_type: this.dialogData.reasonType,
        account_type: this.dialogData.account_type,
        in_out: this.dialogData.in_out,
        supplier_id: this.dialogData.supplierId,
        reference_id: this.dialogData.entityId,
        reference_entity: this.dialogData.referenceEntity,
        comment: this.dialogData?.comment ?? '',
      };
      setFormValue(this.entityForm, this.entity);
    }
  }

  submitForm() {
    this.isLoading = true;
    if(this.entityForm.invalid) {
      this.entityForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    const formValue = this.entityForm.getRawValue();
    if (formValue.account_type === this.entityService.TEKUCI) {
      if (!formValue.original_date || !formValue.original_date.length) {
        this.showError('Datum izvoda je obavezno polje za uplatu preko tekućeg računa');
        this.isLoading = false;
        return;
      }
    }

    const payload = {
      ...formValue,
      id: this.entity.id
    }


    this.entityService.save(payload)
      .subscribe((response) => {
        if(response.success) {
          if (this.dialogData.isInDialog) {
            this.dialogRef.close({event:'success', data: response['data']});
          } else {
            if (!this.entityId) {
              this.entityForm.reset();
              this.router.navigate(['/transaction-new']);
            } else {
              this._snackBar.open('Uspešno sačuvano','', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
            }

          }
        } else {
          this.showError(response.message);
        }
        this.isLoading = false;
    },
    (err) => {
      this.showError(err.error.message);
      this.isLoading = false;
    }
    );
  }

  showError(message: string, action = null) {
    this._snackBar.open(message, action, {
        duration: 5000,
        panelClass: ['red-snackbar']
    });
  }

}
