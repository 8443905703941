<mat-card>
    <mat-card-title>
        <button mat-mini-fab color="primary"><mat-icon>home_work</mat-icon></button> Organizator
    </mat-card-title>
    <mat-card-content>
        <app-organizer [organizerForm]="organizerForm" [organizer]="organizer"></app-organizer>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button (click)="submitForm()" color="primary">
            <mat-icon>save</mat-icon>Sačuvaj promene</button>
    </mat-card-actions>
</mat-card>
<br>
<app-organizer-settings-edit [organizerId]="organizerId"></app-organizer-settings-edit>