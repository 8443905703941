<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" /> 
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Priznanica</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap">
  </head>

  <body>
    <div id="template_cont">
      <header>
        <img [src]="logo" />
        <div id="header_info_cont">
          <h4>{{companyName}} | <span>{{address}}</span></h4>
          <h4>
            PIB:<span> {{pib}} |</span> Matični broj:
            <span>{{jib}} |</span> Tekući račun:
            <span>{{bankAccount}}</span>
          </h4>
          <h4><span>{{phone}} | {{email}}</span></h4>
        </div>
      </header>
      <router-outlet> </router-outlet> 
    </div>
  </body>
</html>
