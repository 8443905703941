import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ResponseMessageService {
    constructor(private _snackBar: MatSnackBar) {}

    showSuccess(message: string = 'Uspešno sačuvano' , action = null, options = {}) {
        this._snackBar.open(message, action, {
            duration: 3000,
            panelClass: ['success-snackbar'],
            ...options
        });
    }
    
    showError(message: string, action = null, options = {}) {
        const finialMsg = message ?? 'Nepoznata greška. Molimo pokušajte ponovo ili kontaktirajte podršku.';
        this._snackBar.open(finialMsg, action, {
            duration: 5000,
            panelClass: ['red-snackbar'],
            ...options
        });
    }
}