<mat-card>
	<mat-card-title>
		<button mat-mini-fab color="primary"><mat-icon>hotel</mat-icon></button> Smeštaj {{hotel.name}}
	</mat-card-title>
	<mat-card-content>
		<br>
		<dynamic-form *ngIf="filterConfig.length > 0" #filtersForm [config]="filterConfig"></dynamic-form>
	</mat-card-content>
	<mat-card-actions align="end">
        <button mat-raised-button (click)="submitForm()" color="primary">
            <mat-icon>save</mat-icon>Sačuvaj promene</button>
    </mat-card-actions>
</mat-card>