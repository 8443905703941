import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';
import { FormEventService } from '../../form-event.service';

@Component({
  selector: 'form-checkbox',
  template: `
  <section [formGroup]="group" >
      <mat-checkbox  [formControlName]="config.name" (change)="onChange($event)" [(value)]="config.value">{{config.label}}</mat-checkbox>
  </section>
  `
})
export class FormCheckboxComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor (private eventService : FormEventService) {
    super();
  }

  onChange(event){
    if (this.config?.eventMessages?.onChange) {
      this.eventService.eventEmitter$.next({
        message : this.config.eventMessages.onChange,
        value : event,
      });
    }
  }
}
