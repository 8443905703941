import { Card } from './card';
import { CardFilter } from './card-filter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from './../../environments/environment';


@Injectable()
export class CardService {
    
    constructor(private http: HttpClient) {
    }

    cardList: Card[] = [];
  
    findById(id: string): Observable<Card> {
        let url = environment.apiHost + '/card'; 
        let params = { "id": id };
        let headers = {
            'enctype': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
        };

        return this.http.get<Card>(url, {params, headers});
    }
    
    load(filter: CardFilter): void {
        this.find(filter).subscribe(
            result => {
                this.cardList = result;
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: CardFilter): Observable<Card[]> {
        let url = environment.apiHost + '/card';
        let headers = {
            'enctype': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
        };

        let params = {
            "id": filter.id,
            "naziv": filter.naziv,
        };

        return this.http.get<Card[]>(url, {params, headers});
    }

    save(entity: Card): Observable<Card> {
        let url = environment.apiHost + '/card';
        let headers = {
            'enctype': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
        };
        return this.http.post<Card>(url, entity, {headers});
    }
}

