import { Component, OnInit } from '@angular/core';
import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankCardTransactionService } from './bank-card-transaction.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bank-card-transaction-archive',
  templateUrl: './bank-card-transaction-list.component.html',
  //styleUrls: ['./open-transaction.component.css']
})
export class BankCardTransactionArchiveComponent implements OnInit {
  columns: Array<any> = [
    { name: 'date', label: 'Datum' },
    { name: 'previous', label: 'Saldo početak dana', type : 'money' },
    { name: 'amount', label: 'Saldo na kraju dana', type : 'money' },
    { name: 'button', label: 'Prikaži transakcije', tooltip:{title: 'Prikaži transakcije'},
     headerPath:'', actions : {name: 'Prikaži transakcije', path: '/bank-card-transaction/archive/',param : 'date','icon': 'preview'} }
  ];

  displayedColumns: string[] = this.columns.map(column => column.name);
  dataSource = new BehaviorSubject([]);
  loaded = false; 
  title = 'Arhiva bankovne kartice';
  periodId = null;

  constructor(
    private service: BankCardTransactionService
    ) { }

  ngOnInit() {
    this.loadArchive();
  } 

  loadArchive() {
    this.service.getArchive().subscribe(resp => {
        if (resp.success === true ) {
            this.dataSource.next(resp.data);
            this.loaded = true;
        }
    });
  }
} 
