import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface FiscalRequest {
  invoiceType: number;
  transactionType: number;
  payment: Array<{amount: number, paymentType: number}>;
  items: Array<{name: string, quantity: number, unitPrice: number, totalAmount: number, labels: Array<string>}>;
}

@Component({
  selector: 'app-fiscal-receipt-preview',
  styleUrls: ['./fiscal-receipt-preview.component.scss'],
  template: `
    <mat-dialog-content class="mat-dialog-preview" ><!--style="white-space: pre-line;text-align:center;"-->
      <pre style="text-align:center" [innerHTML]="data.content"></pre>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Zatvori</button>
    </mat-dialog-actions>
  `,
})
export class FiscalReceiptPreviewComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<FiscalReceiptPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string;
      content: string;
    },
  ) {
  }

  ngOnInit(): void {
    
  }

}
