<mat-toolbar class="page-toolbar">
    <mat-form-field >
      <input matInput placeholder="Pretraga">
    </mat-form-field>
    <span class="toolbar-spacer"></span>
    <a [routerLink]="['/transaction/new']">
      <button mat-raised-button class="add-new-btn" color="warn"><mat-icon >add</mat-icon>Dodaj</button>  
    </a> 
  </mat-toolbar>
  
  <div *ngIf="transactionList.length > 0" class="cardList" > 
      <mat-list>
          <mat-list-item *ngFor="let item of transactionList">
            <mat-icon [ngStyle]="{'color':  item.type == 1 ? '#01B25A' : '#FF4348'}" mat-list-icon>{{ item.type == 1 ? 'call_received' : 'call_made'}}</mat-icon>
            <p class="list-line-title" mat-line>
              <b>Milos Mihajlovic - Kes | {{item.paymentSubjectName  | uppercase }}</b> | {{item.comment  | uppercase }}
            </p>
            <p mat-line style="font-family:serif">Vreme unosa: {{item.timestamp}}</p>
            <h3 mat-subheader [ngStyle]="{'color':  item.type == 1 ? '#01B25A' : '#FF4348'}">
              {{item.amount | currency:" ":true:'2.2-4'}}
            </h3>
          <mat-divider></mat-divider>

          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
  </div>
  
    
  