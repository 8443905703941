import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ContractService } from '../contract.service';
import { BookOfContractsModel } from './book-of-contracts';
import { TableColumn, TableEvent } from '../../shared/lt-table/lt-table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { formatDate } from 'src/app/shared/helpers/date';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-book-of-contracts',
  templateUrl: './book-of-contracts.component.html',
  styleUrls: ['./book-of-contracts.component.css']
})
export class BookOfContractsComponent implements OnInit {
  pageTitle = 'Knjiga evidencije ugovora';
  dataSource: BookOfContractsModel[] = [];
  subscriptions: Subscription[] = [];

  contractStatusMap = new Map();
  tableColumns: TableColumn[] = [
    { name: 'id', label: '#' },
    { name: 'customer_name', label: 'Ime i prezime putnika/nosioca ugovora' },
    { name: 'contract_number', label: 'Broj potvrde o putovanju' },
    { name: 'contract_date', label: 'Datum izdavanja potvrde o putovanju', type: 'date' },
    { name: 'guaranty_number', label: 'Broj izdate garancije putovanja' },
    { name: 'guaranty_date', label: 'Datum izdavanja garancije putovanja', type: 'date' },
    // { name: 'fiscal_receipts', label: 'Fiskalni isečci/fakture', type: 'list' },
    { name: 'fiscal_receipt_numbers', label: 'Broj fiskalnog isečka/fakture', type: 'list' },
    { name: 'fiscal_receipt_dates', label: 'Datum izdavanja fiskalnog isečka/fakture', type: 'list' },
    { name: 'fiscal_receipt_amounts', label: 'Iznos fiskalnog isečka/fakture', type: 'money-list' },
    { name: 'total_contract_amount', label: 'Ukupno uplaćen iznos u dinarima', type: 'money' },

    { name: 'traveller_count', label: 'Broj putnika' },
    { name: 'organizer_name', label: 'Organizator putovanja/broj licence' },
    { name: 'sold_by_name', label: 'Naziv i svojstvo subjekta prodaje' },
    { name: 'sold_at_location', label: 'Mesto/adresa prodaje' },
    { name: 'notice', label: 'Napomena' },
    { name: 'status', label: 'Status', type: 'chip' },
    {
      name: 'button',
      label: 'Akcije',
      actions: [
        {
          type: 'event',
          actionName: 'lt-edit-book-of-contracts',
          name: 'Izmeni',
          icon: 'edit'
        }
      ]
    },
  ]
  editFormConfig: Array<any> = [];
  fiscalCurrency: string = TransactionService.MAIN_CURRENCY.name;
  totalRowsCount = 0;
  pageSize;
  pageSizeDefault = 100;
  editDialogRef;
  searchValues = {};

  @ViewChild('editForm') editForm: TemplateRef<any>;

  constructor(
    private contractService: ContractService,
    private _snackBar: MatSnackBar,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.contractService.getStatuses().subscribe(statuses => {
      statuses.forEach(status => {
        this.contractStatusMap.set(status.id, status);
      });
    });
    this.subscriptions.push(
      this.route.queryParams.subscribe(queryParams => {
        this.pageSize = queryParams.pageSize ?? this.pageSizeDefault;
        this.searchValues = { pageSize: this.pageSize, ...queryParams };
        this.getContarctsBookData(this.searchValues);
      })
    );
  }

  getContarctsBookData(filter?: any) {
    this.contractService.getContractsBook(filter)
      .subscribe((response) => {
        if (response.data.length) {
          this.totalRowsCount = response.meta.count;
          this.dataSource = response.data.map(contract => {
            const contractStatus = contract?.contract?.contract_status;
            const fiscalData = contract?.contract?.fiscals ?? [];
            const fiscalNumbers = [];
            const fiscalDates = [];
            const fiscalAmounts = [];
            fiscalData.forEach(el => {
              fiscalNumbers.push(el.number);
              fiscalDates.push(formatDate(el.date));
              fiscalAmounts.push({ value: el.amount, currencyName: this.fiscalCurrency });
            });
            contract.fiscal_receipt_dates = fiscalDates;
            contract.fiscal_receipt_numbers = fiscalNumbers;
            contract.fiscal_receipt_amounts = fiscalAmounts;
            let status = null;
            if (contractStatus == this.contractService.CANCELLED_CONTRACT) {
              status = this.contractStatusMap.get(Number(contractStatus));
            }
            contract.status = {
              text: status && status.name ? status.name : '',
              class: status && status.class ? status.class : '',
              icon: status && status.icon ? status.icon : ''
            };
            contract.contract_number = contract?.contract?.contract_number;
            return new BookOfContractsModel(contract);
          });
        }
      });
  }

  setEditFormConfig(values) {
    this.editFormConfig = [
      {
        type: 'hidden',
        name: 'id',
        value: values?.id ?? null,
      },
      {
        type: 'textarea',
        label: 'Napomena',
        name: 'notice',
        value: values?.notice ?? '',
        placeholder: 'Napomena',
      },
      {
        label: 'Sačuvaj',
        name: 'submit',
        type: 'button',
      }
    ]
  }

  onTableEvent(event: TableEvent) {
    if (event?.name && event.name == 'lt-edit-book-of-contracts') {
      const rowData = event.data as unknown as BookOfContractsModel;
      this.setEditFormConfig(rowData);
      this.editDialogRef = this.dialog.open(this.editForm, {
        data: event.data
      });
    }
  }

  saveChanges(event) {
    if (!event?.id) {
      this.showError('Greška! Nedostaje ID knjige ugovora');
      return;
    }
    if (event.notice === undefined) {
      this.showError('Greška! Nedostaje polje napomene');
      return;
    }
    const params = { notice: event.notice };
    this.http.post(environment.apiHost + '/lider/contracts-book/update-item/' + event.id, params, {}).subscribe(
      (data) => {
        if (data['success']) {
          this.editDialogRef.close();
          this._snackBar.open('Uspešno sačuvano', '', {
            duration: 3000,
            panelClass: ['green-snackbar']
          });
          this.getContarctsBookData(this.searchValues);
        } else {
          this.showError('Desila se greška, molimo pokušajte ponovo');
        }
      },
      (err) => {
        this.showError(err.error.message);
      }
    );
  }

  paginationChanged(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          pageIndex: event.data.pageIndex,
          pageSize: event.data.pageSize
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  showError(message: string, action = null) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}
