import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizerService } from '../organizer.service';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';

export interface FiscalRequest {
    invoiceType: number;
    transactionType: number;
    payment: Array<{ amount: number, paymentType: number }>;
    items: Array<{ name: string, quantity: number, unitPrice: number, totalAmount: number, labels: Array<string> }>;
}

@Component({
    selector: 'app-seasrch-common-organizer',
    template: `
    <mat-dialog-content>
        <div style="display:flex;justify-content: center;align-items:center">
            <mat-form-field>    
                <input matInput placeholder="Unesite matični broj organizatora" [formControl]="jib" >
            </mat-form-field>
            <div style="margin-left: 20px;height: 45px;"> 
                <button *ngIf="!isSearching" mat-raised-button color="primary" (click)="search()">Pretraži</button>
                <mat-spinner diameter="30" *ngIf="isSearching"></mat-spinner>
            </div>
        </div>
        <div *ngIf="companyData?.jib" >
            <div><b>Naziv: </b>{{companyData.name}}</div>
            <div><b>Matični broj: </b>{{companyData.jib}}</div>
            <div><b>Broj licence: </b>{{companyData.licence}}</div>
            <div><b>Pib: </b>{{companyData.pib}}</div>
            <div><b>Licenca važi od: </b>{{companyData.licence_from}}</div>
            <div><b>Licenca važi do: </b>{{companyData.licence_to}}</div>
            <div><b>Email: </b>{{companyData.email}}</div>
            <div><b>Adresa: </b>{{companyData?.address}}</div>
            <div><b>Mesto: </b>{{companyData?.city}}</div>
            <div><b>Poštanski broj: </b>{{companyData?.postcode}}</div>
            <div><b>Tel: </b>{{companyData?.tel}}</div>
            <button mat-raised-button color="primary" (click)="closeDialog()">Odaberi</button>

        </div>
        
    </mat-dialog-content>
  `,
})
export class SearchCommonOrganizerComponent implements OnInit {
    jib = new UntypedFormControl();
    isSearching = false;
    companyData = {
        jib: "",
        name: "",
        licence: "",
        licence_from: "",
        licence_to: "",
        email: "",
        pib: "",
        address: "",
        tel: "",
        city: "",
    };

    constructor(
        private matDialogRef: MatDialogRef<SearchCommonOrganizerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            name: string;
            content: string;
        },
        private organizerService: OrganizerService,
        private rspMsgService: ResponseMessageService,
    ) {
    }

    ngOnInit(): void {
    }

    search() {
        if (this.jib?.value?.length < 6) {
            this.rspMsgService.showError('Minimalna dužina matičnog broja je 6 karaktera');
            return;
        }
        this.isSearching = true;
        this.organizerService.findOrganizerByJib(this.jib.value).subscribe(res => {
            if (res.success && res.data) {
                this.companyData = res.data;
            } else {
                this.rspMsgService.showError(null);
            }
        },
        err => {
            this.rspMsgService.showError(err?.error?.message);
        }).add(()=> {
            this.isSearching = false;
        });
    }

    closeDialog() {
        this.matDialogRef.close(this.companyData);
    }

}
