import { Component, OnInit } from '@angular/core';
import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankCardTransactionService } from './bank-card-transaction.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-bank-card-transaction-list',
  templateUrl: './bank-card-transaction-list.component.html',
  //styleUrls: ['./open-transaction.component.css']
})
export class BankCardTransactionListComponent implements OnInit {
  columns: Array<any> = [
    { name: 'id', label: 'ID' },
    { name: 'reason_type', label: 'Svrha' },
    { name: 'comment', label: 'Komentar' },
    { name: 'user', label: 'User' },
    { name: 'amount', label: 'Iznos (RSD)', type : 'money' },
    { name: 'provider', label: 'Organizator' },
    { name: 'reference_number', label: 'Referentni broj' },
    { name: 'timestamp', label: 'Vreme unosa' },
    { 
      name: 'actions', 
      label: '',
      tooltip:{title: 'Dodaj novi unos'},
      headerPath:'/bank-card-transaction/new',
      actions : [
        {
          type: 'goto-action',
          name: 'PDF',
          callback: 'goToReceipt',
          'icon': 'picture_as_pdf'
        }
      ]
    },
  ];

  displayedColumns: string[] = this.columns.map(column => column.name);
  dataSource = new BehaviorSubject([]);
  loaded = false;
  title = 'Bankovne kartice transakcije'  
  periodId = null;
  canAddTransaction = true;
  totals = {};

  constructor(
    private service: BankCardTransactionService,
    private _route: ActivatedRoute,
    private _router: Router,
    ) { }

  ngOnInit() {
    this._route.params
    .subscribe((value) => {
        this.periodId = value.id;
        if (this.periodId) {
          this.canAddTransaction = false;
          this.title = 'Bankovne kartice | ARHIVA za ' + this.periodId;
        }
        this.loadTransactions(this.periodId);
    });

  } 

  loadTransactions(id = null) {
    this.service.getAll(id).subscribe(resp => {
        if (resp.success === true ) {
          this.totals['amount'] = 0;
          resp.data.forEach(element => {
            this.totals['amount'] += parseFloat(element.amount);
          });
          this.dataSource.next(resp.data);
          this.loaded = true;
        }
    });
  }
  goToReceipt(element) {
    this._router.navigate(['transaction/print/receipt/' + element['id'] , { type: 'bank-card' }]);
  }
} 
