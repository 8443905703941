import { ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import { Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { DynamicFormComponent } from "src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component";
import { HelperDataService } from "src/app/services/helper-data.service";
import { BankCardTransactionService } from "./bank-card-transaction.service";

@Component({
  selector: "bank-card-transaction-new",
  templateUrl: "./bank-card-transaction-new.component.html",
})
export class BankCardTransactionNewComponent{
    providers = [];
    config = [];
    loaded = false;
    constructor(
        private helperDataService: HelperDataService,
        private service: BankCardTransactionService,
        private router: Router,
        private _snackBar: MatSnackBar,
    ) {
      this.service.getProviders().subscribe( resp =>{
        if (resp && resp.data) {
          this.providers = resp.data;
          this.setFormConfig(this.providers);

          this.loaded = true;
        }
      });
    }

    @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
    
    /*
    ngOnInit() {
      //this.form.form.get('reason_type').setValue(1); //Putovanje
    }
    */
    onSubmit($event) {
      this.service.save($event).subscribe(
        resp => {
          if (resp && resp.success == true) {
            this._snackBar.open('Unos uspešan', 'Sačuvano', {
              duration: 2000,
              panelClass: ['green-snackbar']
            });
              this.router.navigate(['/bank-card-transaction']);
          } else {
            let message = resp.message ? resp.message : null;
            if (!message) {
              message = resp.data && resp.data.message ? resp.data.message : 'Unos nije uspeo'; 
            }
            this._snackBar.open(message, 'Greška', {
              duration: 3000,
              panelClass: ['red-snackbar']
            });
          }
        },
        err => {
          this._snackBar.open(err['error']['message'], 'Greška', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
        }
      );
      //value: {[name: string]: any}
        //this.serviceAdded.emit($event);
    }

    setFormConfig(providers) {
      this.config = [
        {
            type: 'input',
            label: 'Iznos',
            name: 'amount',
            placeholder: 'Iznos',
            validation: [Validators.required]
        },
        {
          type: 'input',
          label: 'Referentni broj',
          name: 'reference_number',
          placeholder: 'Referentni broj',
          validation: [Validators.required]
        },
        {
            
          type: 'select',
          label: 'Organizator',
          name: 'provider',
          options: providers,
          placeholder: 'Odaberi organizatora',
        },
        {
            
          type: 'select',
          label: 'Svrha transakcije',
          name: 'reason_type',
          options: this.helperDataService.getPaymentSubjects(),
          placeholder: 'Odaberi svrhu',
        },
        {          
            type: 'textarea',
            label: 'Komentar',
            name: 'comment',
            placeholder: 'Komentar',
        },
        {
          label: 'Sačuvaj',
          name: 'submit',
          type: 'button',
          icon: 'save'
        },
      ];
    }
}
