import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren, ViewContainerRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {TableColumn, TableEvent} from './lt-table';
import localeFr from '@angular/common/locales/sr';
import { registerLocaleData } from '@angular/common';
import { InlineSelectComponent } from '../lt-inline-edit/inline-select.component';
registerLocaleData(localeFr, 'sr');
@Component({
  selector: 'app-lt-table',
  templateUrl: './lt-table.component.html',
  styleUrls: ['./lt-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LtTableComponent implements OnInit, OnChanges {

  @Input()
  get columns(): TableColumn[] {
    return this._columns;
  }
  
  set columns(columns: TableColumn[]) {
    this._columns = columns.map(col => {
      /*
        When first time view is loaded, usually column visibility is not set in parent,so default is visible
      */
      col.isVisible = col.isVisible !== undefined ? col.isVisible: true;
      return col;
    });

    const onlyVisible = this._columns.filter(col => col.isVisible);
    this.displayedColumns = onlyVisible.map(col => {return col.name});
  }
  _columns: TableColumn[];

  @Input() dataSource: unknown[] = [];
  @Input() options?;
  @Input() toolbarButtons? = [];

  @Input() footerTotals: Record<string, string | number> | null = null;

  @Output() tableEvent = new EventEmitter<TableEvent>();

  displayedColumns: string[];
  expandedElement: any | null;
  expandAll = true;
  isExpandable = false;
  activeColumns;
  allSub: Subscription[] = [];
  queryParams = null;
  hiddenColumns = [];
  selection = new SelectionModel<any>(true, []);
  
  activatedComponent = null;
  activeInlineIndex = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @ViewChildren('cellElem', { read: ViewContainerRef })cellsElemsList: QueryList<ViewContainerRef>;
  
  ngOnInit(): void {
    
    this.allSub.push(
      this.route.queryParams.subscribe(params => {
        this.queryParams = {...params};
        if (params.ltHidden !== undefined && this.options?.hasHideShowCol === true) {
          if (typeof params.ltHidden === 'string') {
            this.hiddenColumns = params.ltHidden.split(',');
            if (this.hiddenColumns.length > 0) {
              this.hideTableColumns();
            }
          } else {
            console.log('ltHidden in $_GET should be string');
          }
        }
      })
    );

  }

  onCellClick = (rowIdx, colIdx, options) => {
    const element = options.element;
    //const cellIdx = (rowIdx * 1) + colIdx;
    const cellIdx = rowIdx + '-' + colIdx;
    if (this.activeInlineIndex == cellIdx) {
      return false;
    }
    this.activeInlineIndex = cellIdx;
   
    const compRef = this.cellsElemsList.filter((el, idx) => {
      const currentElementClass = el?.element?.nativeElement?.classList?.value ?? '';
      if (currentElementClass.indexOf('editable-' + this.activeInlineIndex) != -1){
        return true;
      }
    })[0]
      .createComponent(InlineSelectComponent);
    this.activatedComponent = compRef;
    compRef.instance.config = element.config;
    compRef.instance.editedData = options.row;
    compRef.instance.inlineEvent.subscribe(event => {
      this.destroyInlineComponent();
    });
    /*
    compRef.instance.submit.subscribe(resp => {
      console.log('Form submited', resp);
    });
    */
    //console.log(compRef.instance.form); 
    //.nativeElement.focus()
  }

  destroyInlineComponent() {
    this.activatedComponent ? this.activatedComponent.destroy() : '';
    this.activatedComponent = null;
    this.activeInlineIndex = null;
  }

  emitEvent(actionName, rowData, $event = null) {
    this.tableEvent.emit({
      name: actionName,
      data: rowData,
      event: $event
    })
  }

  onExpandIconClick(element) {
    this.expandedElement = this.expandedElement === element ? null : element
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.options?.currentValue?.expandable === true) {
      this.isExpandable = true;
    } else {
      this.isExpandable = false;
    }
  }

  columnSettingChanged(event) {
    this.columns = this.columns.map(col => {
      if (col.name === event.source.name) {
        col.isVisible = event.checked;
      }
      return col;
    });
  }

  onColumnSettingMenuClosed() { 
    this.columns = [...this.columns];
    const hiddenColumns = this.columns.filter(col => !col.isVisible);
    if (hiddenColumns && hiddenColumns.length > 0) {
      const hiddenColNames = hiddenColumns.map(col => {return col.name});
      this.queryParams['ltHidden'] = hiddenColNames.join(',');
      this.router.navigate(
        [], 
        {
          relativeTo: this.route,
          queryParams: this.queryParams, 
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    } else {
      this.queryParams['ltHidden'] = '';
      this.router.navigate(
        [], 
        {
          relativeTo: this.route,
          queryParams: this.queryParams, 
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    /*
    this.matButtonRef.focus();
    this.triggerButtonText = 'Select a vehicle';

    this.formattedSelectedVehicles =
      (this.selectedVehicles.length === 0
        ? 'No vehicles selected'
        : 'You selected ' + this.selectedVehicles.join(', ')) + '.';
        */
  }

  onColumnSettingMenuOpened() {

  }

  hideTableColumns() {
    this.columns = this.columns.map(col => {
      return {
        ...col,
        isVisible: this.hiddenColumns.indexOf(col.name) !== -1 ? false : true
      }
    });
  }

  sortData($event) {
    this.emitEvent('ltTableSortChanged', {}, $event);
  }

  barButtonClick(action){
    if (action) {
      this.emitEvent(action, {});
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }

  ngOnDestroy(): void {
    for (const sub  of this.allSub) {
      if(!sub.closed) {
        sub.unsubscribe();
      }
    }
  }

}
