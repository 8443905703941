<mat-toolbar class="page-toolbar">
<!--    <mat-form-field >-->
<!--        <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="filter.q" placeholder="Pretraga">-->
<!--    </mat-form-field>-->
    <span class="toolbar-spacer"></span>
    <a [routerLink]="['/organizer/add']">
        <button mat-raised-button class="add-new-btn" color="warn"><mat-icon >add</mat-icon>Dodaj Organizatora</button>
    </a>
</mat-toolbar>

<mat-list>
    <mat-list-item *ngFor="let organizer of organizers">
        <mat-icon mat-list-icon>folder</mat-icon>
        <div mat-line>{{organizer.name}}</div>
        <div mat-line> {{organizer.email}} </div>

        <a [routerLink]="['/organizer/', organizer.id]" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </a>
        <mat-divider></mat-divider>
    </mat-list-item>
</mat-list>