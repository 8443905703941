export class ContactFilter {
    id: string = '';
    name: string = '';
    lastname: string = '';
    sex: string = '';
    phome: string = '';
    email: string = '';
    personal_id: string = '';
    birthday: string = '';
    source: string = '';
    q: string = '';
    currentPage: number;
    pageSize: number;
}