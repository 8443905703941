<style>

  table{
      width: 100%;
  }
  .row-action {
      text-decoration: none;
  }

  .fab-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

        > div {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            margin-bottom: 5px;

            button {
            margin-bottom: 17px;
            }
        }
    }

    .fab-toggler {
    float: right;
    z-index: 100;
    }
    mat-toolbar {
        color: #525252;
        border-bottom: 2px solid #3f51b5;
        font-weight: bold;
    }
</style>
<ng-container *ngIf="loaded">
    <mat-toolbar >    
        <mat-toolbar-row>
            <span>Dnevnik blagajne <span *ngIf="currentPeriod">za {{currentPeriod.date | date : 'dd.MM.yyyy'}}</span> | LiderTravel</span>
            <span class="example-spacer"></span>
            
            <mat-spinner diameter="30" *ngIf="openingNewPeriod || closingCurrentPeriod"></mat-spinner>

            <button mat-raised-button color="warn"  *ngIf="!currentPeriod" (click)="openNewPeriod()">
                <span *ngIf="!openingNewPeriod">Otvori blagajnu</span>
            </button>
            <button [disabled]="closingCurrentPeriod" mat-raised-button color="warn"  *ngIf="currentPeriod && !currentPeriod.closed" (click)="closeCurrentPeriod()">
                <span *ngIf="!closingCurrentPeriod">Zatvori blagajnu</span>
            </button>
            <h6 *ngIf="currentPeriod && currentPeriod.closed">Blagajna je zatvorena u {{currentPeriod.closed | date : 'dd.MM.yyyy HH:mm:ss'}} </h6>
        </mat-toolbar-row>
      </mat-toolbar>
      
      <table mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}">
      <ng-container *ngIf="column.name != 'actions'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{column.label}}
          </th>
      </ng-container>
      <ng-container *ngIf="column.name == 'actions'">
          <th mat-header-cell *matHeaderCellDef>
              <a *ngIf="canAddTransaction" [routerLink]="[column.headerPath]">
                  <button mat-mini-fab color="primary"
                      matTooltip="{{column.tooltip.title}}"
                      [matTooltipPosition]="((column.tooltip && column.tooltip.position ) ? column.tooltip.position : 'below')"
                  >
                      <i class="material-icons">add</i> 
                  </button>
              </a>
          </th>
      </ng-container>

      <!-- Footer cell -->
        <ng-container *ngIf="column.type === 'money'">
            <td mat-footer-cell *matFooterCellDef>{{totals[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}</td>
        </ng-container>
        <ng-container *ngIf="!column.type ">
            <td mat-footer-cell *matFooterCellDef>{{totals[column.name]}}</td>
        </ng-container>
        <ng-container *ngIf="column.type && column.type == 'checkbox-action'">
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      <!-- END Footer cell -->
      
      <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="column.name != 'actions'">
              <div *ngIf="column.type && column.type == 'money'">
                  {{element[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}
              </div> 
              <div *ngIf="column.type && column.type == 'checkbox-action'">
                <mat-checkbox [disabled]="!canAddTransaction || element['receipt']" (change)="sendChecked($event, element)" class="example-margin" [value]="element" [(ngModel)]="element['receipt']">{{column.label}}</mat-checkbox>
              </div>
              <div *ngIf="!column.type">
                  {{element[column.name]}}
              </div>
          </ng-container>
          <div *ngIf="column.name == 'actions'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <a class="row-action" *ngFor="let action of column.actions" target="_blank" [routerLink]="[action.path+element[action.param]]">
                      <button mat-menu-item>
                              <mat-icon>{{action.icon}}</mat-icon>
                              <span>{{action.name}}</span>
                      </button>
                  </a>
              </mat-menu>
          </div>          
      </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      
       <br>
        <h4>Gotovina trenutno stanje RSD : {{cashTotal() | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
        <h4>Ček trenutno stanje RSD : {{cekTotal() | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
      <div class="fab-container">
      <a *ngIf="canAddTransaction" [routerLink]="['/transaction/new']">
          <button mat-fab class="fab-toggler" color="primary">
              <i class="material-icons">add</i>
          </button>
      </a>
      </div>
      
      <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
</ng-container>