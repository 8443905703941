import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactService} from '../contact.service';
import {setFormValue} from '../../shared/helpers/form';
import {Contact} from '../contact';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent implements OnInit {

  contactForm = new UntypedFormGroup({});
  contact: Contact | null = null;
  contactId: string | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contactService: ContactService,
    private rspMsgService: ResponseMessageService,
  ) {
    const contactId = activatedRoute.snapshot.params.id;
    this.contactId = contactId !== 'add' ? contactId : null;
  }

  ngOnInit(): void {
    if(this.contactId) {
      this.contactService.findById(this.contactId)
        .subscribe((response)=> {
          if(response.success) {
            this.contact = response.data;
            setFormValue(this.contactForm, this.contact);
          }
        })
    }
  }

  submitForm() {
    if(this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      // return;
    }

    const formValue = this.contactForm.getRawValue();
    const payload = {
      ...formValue,
      id: this.contactId
    }


    this.contactService.save(payload)
      .subscribe((response) => {
        if(response.success) {
          this.contactForm.reset();
          this.router.navigate(['/contact/']);
        } else {
          console.error('Handle create errors');
        }
      }, error => {
        this.rspMsgService.showError(error?.error?.message);
      } )
  }
}
