<ng-template #editForm>
    <h3 class="filter-title"><mat-icon>edit</mat-icon>Izmeni napomenu</h3>
    <dynamic-form
        *ngIf="editFormConfig.length"
        [config]="editFormConfig"
        (submit)="saveChanges($event)"></dynamic-form>
</ng-template>
<app-lt-table
    [columns]="tableColumns"
    [dataSource]="dataSource"
    (tableEvent)="onTableEvent($event)"></app-lt-table>
    
    <app-lt-pagination
        [totalCount]="totalRowsCount"
        [pageSize]="pageSize"
        (paginationEvent)="paginationChanged($event)"></app-lt-pagination>