import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { forkJoin, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { DynamicFormComponent } from "../components/dynamic-form-master/containers/dynamic-form/dynamic-form.component";
import { FormEventService } from "../components/dynamic-form-master/form-event.service";
import { HotelService } from "../hotel/hotel.service";
import { DomesticCityService } from "../inventory/domestic-city/domestic-city.service";
import { OrganizerService } from "../organizer/organizer.service";
import { INQUIRY_STATUSES, PRERESERVATION_OPTIONS } from "./inquiry-constants";
import { InquiryService } from "./inquiry.service";

@Component({
    selector: 'app-lt-inquiry-satus-changelog',
    template: `
    <dynamic-form #inquiryForm *ngIf="inquiryFormConfig.length > 0" (submit)="saveInquiry($event)" [config]="inquiryFormConfig"></dynamic-form>
    `
})
export class NewInquiryComponent implements OnInit {
    inquiryFormConfig = [];
    organizerList = [];
    private INQUIRY_STATUSES = [];

    allSub: Subscription[] = [];

    hotelOptions = [];
    domesticCities = [];
    //todo common value, in contract also used
    transportationTypes: object[] = [
        {
            id: 1,
            name: 'Bus'
        },
        {
            id: 2,
            name: 'Avio'
        },
        {
            id: 3,
            name: 'Sopstveni'
        },
    ];

    @ViewChild('inquiryForm') inquiryForm: DynamicFormComponent;


    constructor(
        private operatorService: OrganizerService,
        private domesticCityService: DomesticCityService,
        private hotelService: HotelService,
        private inquiryService: InquiryService,
        private formEventService: FormEventService,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<NewInquiryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
     ) {
        //this.list = this.data.list;
        this.INQUIRY_STATUSES = INQUIRY_STATUSES;
     }
    ngOnInit(): void {
        forkJoin(
            {
                organizers: this.operatorService.find({limit: 20}),
                domesticCities: this.domesticCityService.find({limit: 20}),
            }
        ).subscribe(res => {
            this.organizerList = res.organizers['data'].map( val => {
                return {
                    name: val.name,
                    value: val.id,
                };
            });

            if (res.domesticCities['data']) {
                this.domesticCities = res.domesticCities['data'].map(city => {
                    return {
                        value: city.id,
                        name: city.name,
                    }
                });
            }
            let values = {};
            this.setNewInquiryFormConfig(values);
        }, err => {
          this.showError(err.error.message);
        });

        //listening events
        this.allSub.push(this.formEventService.eventEmitter$.subscribe(event => {
            if (event.message == 'inquiry-arrangement-selected') {
                const cityName = event.value?.city?.name ? event.value.city.name : '';
                const countryName = event.value?.city?.country?.name ? event.value.city.country.name : '';
                this.inquiryForm.setValue('arrangement_city', cityName);
                this.inquiryForm.setValue('arrangement_country', countryName);
                //@todo maybe show mesage also no hotel found
                if (event?.value?.id) {
                    this.getHotels(event.value.id);
                }
                
            }

            if (event.message == 'inquiry-hotel-selected') {
                const data = event.value;

                if (data.service !== undefined) {
                    this.inquiryForm.setValue('service', data.service);
                }
                if (data.room !== undefined) {
                    this.inquiryForm.setValue('room_name', data.room);
                }
                if (data.hotelName !== undefined) {
                    this.inquiryForm.setValue('arrangment_accomodation', { value: data.id, name: data.hotelName});
                }
                if (data.id !== undefined) {
                    this.inquiryForm.setValue('arrangment_accomodation_id', data.id);
                }
                if (data.from !== undefined) {
                    this.inquiryForm.setValue('date_from', data.from);
                }
                if (data.to !== undefined) {
                    this.inquiryForm.setValue('date_to', data.to);
                }
                if (data.check_in !== undefined) {
                    this.inquiryForm.setValue('check_in_date', data.check_in);
                }
                if (data.check_out !== undefined) {
                    this.inquiryForm.setValue('check_out_date', data.check_out);
                }
                if (data.days_number !== undefined) {
                    this.inquiryForm.setValue('days_number', data.days_number);
                }
                if (data.nights_number !== undefined) {
                    this.inquiryForm.setValue('nights_number', data.nights_number);
                }
            }
        }));
    }

    getHotels(arrangementId) {
        this.hotelService.getHotels(arrangementId).subscribe(resp => {
            if (resp.data) {
                if (resp.data.length == 0) {
                    this.showError('Za odabrani aranžman nije pronadjen ni jedan hotel');
                }
                this.hotelOptions = resp.data;
                const formValues = this.inquiryForm.form.getRawValue();
                console.log(formValues);
                this.setNewInquiryFormConfig(formValues);
            } else {
                this.showError('Greška! Učitavanje liste hotela nije uspelo')
            }
        }, err => {
            this.showError(err.error.message);
        });
    }

    setNewInquiryFormConfig(values) {
        this.inquiryFormConfig = [
            {
                type: 'hidden',
                label: 'Arrangment ID',
                name: 'arrangement_id',
                value: values['arrangement_id'] ? values['arrangement_id'] : null ,
            },
            {
                type: 'hidden',
                label: 'Arrangment Accomodation ID',
                name: 'arrangment_accomodation_id',
                value: values['arrangment_accomodation_id'] ? values['arrangment_accomodation_id'] : null ,
            },
            {
                type: 'autocomplete',
                label: 'Oranizator',
                name: 'organizer',
                placeholder: 'Oranizator',
                validation: [Validators.required],
                value: values.organizer ?? null,
                col: 3,
                options: this.organizerList
            },
            {
                type: 'autocomplete',
                label: 'Naziv aranžmana',
                name: 'arrangement_name',
                placeholder: 'Naziv aranžmana',
                validation: [Validators.required],
                searchStringKey: 'name',
                api: {
                    hostUrl: environment.apiHost,
                    endpoint: '/lider/arrangment?status=1',
                },
                eventMessages: {
                    onChange: 'inquiry-arrangement-selected'
                },
                col: 4,
                value: values.arrangement_name,
            },
            {
                type: 'input',
                label: 'Zemlja',
                name: 'arrangement_country',
                placeholder: 'Zemlja',
                validation: [Validators.required],
                //value: values['arrangement_country'],
                col: 2,
                value: values.arrangement_country,
            },
            {
                type: 'input',
                label: 'Mesto',
                name: 'arrangement_city',
                placeholder: 'Mesto',
                value: values.arrangement_city,
                col: 2
            },
            /*
            {
                type: 'input',
                label: 'Smeštaj',
                name: 'arrangment_accomodation',
                placeholder: 'Smeštaj',
                value: values['arrangment_accomodation'],
                col: 2  
            },
            */
            {
                type: 'autocomplete',
                label: 'Smeštaj',
                name: 'arrangment_accomodation',
                placeholder: 'Smeštaj',
                validation: [Validators.required],
                options: this.hotelOptions,
                eventMessages: {
                    onChange: 'inquiry-hotel-selected'
                },
                col: 3,
                value: values.arrangment_accomodation,
            },
            {
                type: 'input',
                label: 'Tip sobe',
                name: 'room_name',
                placeholder: 'Tip sobe',
                value: values.room_name,
                col: 1
            },
            {
                type: 'input',
                label: 'Usluga',
                name: 'service',
                placeholder: 'Usluga',
                validation: [Validators.required],
                value: values.service,
                col: 2
            },
            /*
            {
                type: 'select',
                label: 'Prevoz',
                name: 'transport_type',
                options: this.transportationTypes,
                placeholder: 'Prevoz',
                validation: [Validators.required],
                value: values.transport_type,
                col: 2
            },
            {
                type: 'autocomplete',
                label: 'Mesto polaska',
                name: 'departure_city',
                placeholder: 'Mesto polaska',
                validation: [Validators.required],
                searchStringKey: 'name',
                value: {value: values?.departureCity?.id?? null, name: values.departureCity?.name?? null},
                eventMessages: {
                    onChange: 'inquiry-departure-city-selected'
                },
                options:this.domesticCities,
                col: 2
            },
            */
            {
                type: 'input',
                label: 'Broj dana',
                name: 'days_number',
                placeholder: 'Broj dana',
                value: values.days_number,
                col: 1
            },
            {
                type: 'input',
                label: 'Broj noći',
                name: 'nights_number',
                placeholder: 'Broj noći',
                value: values.nights_number,
                col: 1
            },

            {
                type: 'datepicker',
                label: 'Datum od',
                name: 'date_from',
                placeholder: 'Datum od',
                value: values.date_from,
                col: 2
            },
            {
                type: 'datepicker',
                label: 'Datum do',
                name: 'date_to',
                placeholder: 'Datum do',
                value: values.date_to,
                col: 2
            },
            /*
            {
                type: 'datepicker',
                label: 'Datum prijave',
                name: 'check_in_date',
                placeholder: 'Datum prijave',
                value: values.check_in_date,
                col: 2
            },
            
            {
                type: 'datepicker',
                label: 'Datum odjave',
                name: 'check_out_date',
                placeholder: 'Datum odjave',
                value: values.check_out_date,
                col: 2
            },
            */
            {
                type: 'select',
                label: 'Odrasli',
                name: 'adults',
                placeholder: 'Odrasli',
                value: values.adults ?? '',
                options: this.optionsAdultNumber(),
                validation: [Validators.required],
                col: 1
            },
            {
                type: 'select',
                label: 'Deca',
                name: 'children',
                placeholder: 'Deca',
                value: values.children ?? '',
                options: this.optionsKidsNumber(),
                col: 1
            },
            {
                type: 'select',
                label: 'Dete 1',
                name: 'child1',
                placeholder: 'Dete 1',
                value: values.child1 ?? '',
                options: this.optionsKidsAge(),
                col: 1
            },
            {
                type: 'select',
                label: 'Dete 2',
                name: 'child2',
                placeholder: 'Dete 2',
                value: values.child2 ?? '',
                options: this.optionsKidsAge(),
                col: 1
            },
            {
                type: 'select',
                label: 'Dete 3',
                name: 'child3',
                placeholder: 'Dete 3',
                value: values.child3,
                options: this.optionsKidsAge(),
                col: 1
            },
            {
                type: 'select',
                label: 'Dete 4',
                name: 'child4',
                placeholder: 'Dete 4',
                value: values.child4 ?? '',
                options: this.optionsKidsAge(),
                col: 1
            },
            /*
            {
                type: 'select',
                label: 'Dete 5',
                name: 'child5',
                placeholder: 'Dete 5',
                value: values.child5 ?? '',
                options: this.optionsKidsAge(),
                col: 1
            },
            {
                type: 'select',
                label: 'Dete 6',
                name: 'child6',
                placeholder: 'Dete 6',
                value: values.child6 ?? '',
                options: this.optionsKidsAge(),
                col: 1
            },
            */
            {
                type: 'select',
                label: 'Status upita',
                name: 'status_id',
                options: this.INQUIRY_STATUSES,
                placeholder: 'Status upita',
                col: 3,
                value: values.status_id ?? '40' , //poslate instrukcije
            },
            {
                type: 'select',
                label: 'Predrezervacija',
                name: 'prereserved',
                options: PRERESERVATION_OPTIONS,
                placeholder: 'Predrezervacija',
                col: 3,
                value: values.prereserved ?? null ,
            },
            {
                type: 'input',
                label: 'Ime i prezime',
                name: 'name_lastname',
                placeholder: 'Ime i prezime',
                validation: [Validators.required],
                value: values.name_lastname,
                col: 3
            },
            {
                type: 'input',
                label: 'Email',
                name: 'email',
                placeholder: 'Email',
                validation: [Validators.required],
                value: values.email,
                col: 2
            },{
                type: 'input',
                label: 'Tel.',
                name: 'phone',
                placeholder: 'Tel.',
                value: values.phone,
                validation: [Validators.required],
                col: 2
            },
            {
                type: 'textarea',   
                label: 'Napomena',
                name: 'notice',
                placeholder: 'Napomena',
                validation: [Validators.required],
                value: values.notice,
                col: 5
            },
            {
                label: 'Sačuvaj',
                name: 'submit',
                type: 'button',
                col: 12
            }
        ];
    }

    saveInquiry(event) {
        this.inquiryService.create(event).subscribe( res => {
            event['success'] = true;
            this.dialogRef.close(event);
        }, err => {
            this.showError(err.error.message);
        });
    }

    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }

    optionsAdultNumber() {
        const options = [];
        options.push({
            id : '',
            name: ''
        });
        for (let i = 1; i <= 6; i++) {
            options.push({
                id : i,
                name : i,
            });
        }
        return options;
    }

    optionsKidsNumber() {
        const options = [];
        options.push({
            id : '',
            name: ''
        });
        for (let i = 1; i <= 4; i++) {
            options.push({
                id : i,
                name : i,
            });
        }
        return options;
    }

    optionsKidsAge() {
        const options = [];
        for (let i = 0; i <= 12; i++) {
            options.push({
                id : i,
                name : i,
            });
        }
        return options;
    }

}