import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-lt-inquiry-satus-changelog',
    template: `
        <style>
        .mat-list-item {
            border: 1px solid grey;
            border-radius: 5px;
            margin-bottom: 5px;
            height: 40px;
        }
        </style>
        <h2>Istorija promene statusa</h2>
        <mat-list role="list">
            <mat-list-item *ngFor="let item of list" role="listitem">{{item.text}}</mat-list-item>
        </mat-list>
    `
})
export class InquiryStatusChangelogComponent implements OnInit {
    list = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
     ) {
        this.list = this.data.list;
     }
    ngOnInit(): void {
    }

}