import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../transaction.service';
import { Transaction } from '../transaction';
import { map, switchMap} from 'rxjs/operators';
import { of } from 'rxjs';
import { HelperDataService } from 'src/app/services/helper-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogDataInterface {
    contractId?: number;
    isInDialog?: boolean;
}
@Component({
  selector: 'transaction-edit',
  templateUrl: './transaction-edit.component.html'
})
export class TransactionEditComponent implements OnInit {

    id: string;
    transaction: Transaction;
    errors: string;
    tips: [
        {
            id: 1,
            name: 'Uplata',
            checked: 'checked'
        },
        {
            id: 2,
            name: 'Isplata',
            checked:''
        }
    ];

    paymentSubjects: any;
    moneyTypes: any;
    transactionTypes: any;
    dialogData: DialogDataInterface = {};

    constructor(
        private route: ActivatedRoute,
        private transactionService: TransactionService,
        private helperDataService: HelperDataService,
        private router: Router,
        @Optional() public dialogRef: MatDialogRef<TransactionEditComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogDataInterface,
        ) {
            this.dialogData = data ? data : {};
    }

    ngOnInit() {
        this.paymentSubjects = this.getPaymentSubjects();
        this.moneyTypes = this.getMoneyTypes();
        this.transactionTypes = this.getTransactionTypes();
        this
            .route
            .params
            .pipe(
                map(p => p['id']),
                switchMap(id => {

                    let newTransaction = new Transaction();
                    newTransaction.payment_method = 1;
                    newTransaction.payment_subject = 1;
                    newTransaction.reference = this.dialogData.contractId ? this.dialogData.contractId : null;
                    newTransaction.type = '1';
                    if (id === 'new' || this.dialogData.isInDialog) return of(newTransaction);
                    return this.transactionService.findById(id)
                })
            )
            .subscribe(
                transaction => { 
                    this.transaction = transaction; 
                    this.errors = ''; 
                },
                err => { 
                    console.log(err);
                    if (err.error && err.error.message){
                        this.errors = err.error.message;
                        return;
                    } else if (err.error && err.error[0] && err.error[0]['message']) {
                        this.errors = err.error[0]['message'];
                        return;
                    }
                    this.errors = 'Error loading'; 
                }
            );   
                        
    }

    save() {
        
        this.transactionService.save(this.transaction).subscribe(
            transaction => { 
                console.log(transaction);
                if(transaction['success'] === true) {
                    if (this.dialogData.isInDialog) {
                        this.dialogRef.close({event:'success', data: transaction['data']});
                    } else {
                        this.router.navigate(['/transaction/open']);
                    }
                }
            },
            err => { 
                console.log(err.error);
               if (err.error && err.error.message){
                this.errors = err.error.message;
                return;
               } else if (err.error && err.error[0] && err.error[0]['message']) {
                this.errors = err.error[0]['message'];
                return;
               }

                if(err['success'] != true) {
                    if(typeof err['data'] === 'object' && err['data'].name ) {
                        this.errors = err['data'].name; 
                    } else if(Array.isArray(err['data']) &&  err['data'][0]['message']){
                        this.errors = err['data'][0]['message'];
                    } else if (err['error']['data']['message']) {
                        this.errors = err['error']['data']['message'];
                    } else {
                        this.errors = 'Nepoznata greška';
                    }
                }
            }
        );
    }

    getPaymentSubjects(){
        return this.helperDataService.getPaymentSubjects();
    }
    getMoneyTypes(){
        return this.helperDataService.getMoneyTypes();
    }

    getTransactionTypes(){
        return this.helperDataService.getTransactionTypes();
    }
}