<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<mat-card>
    <mat-card-title>
        <button mat-mini-fab color="primary"><mat-icon>swap_vert</mat-icon></button> Transakcija
    </mat-card-title>
    <mat-card-content>
        <app-transaction *ngIf="entity" [suppliers]="suppliers" [entityForm]="entityForm" [entity]="entity"></app-transaction>
    </mat-card-content>
    <mat-card-actions>
        <button [disabled]="isLoading" mat-raised-button (click)="submitForm()" color="primary">
            <mat-icon>save</mat-icon>Sačuvaj</button>
    </mat-card-actions>
</mat-card>