import { Component, EventEmitter, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, merge, Observable, of, Subscription } from 'rxjs';
import { debounceTime, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
    DynamicFormComponent
} from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { Contact } from 'src/app/contact/contact';
import { ContactFilter } from 'src/app/contact/contact-filter';
import { ContactService } from 'src/app/contact/contact.service';
import { OrganizerService } from 'src/app/organizer/organizer.service';
import { SERVICE_TYPE } from 'src/app/shared/constants/service-type';
import { ContractService } from '../contract.service';
import { prepareFormGroup } from "../../shared/helpers/form";
import { ClientObj } from "../client";
import { MatDialog } from '@angular/material/dialog';
import { Arrangment } from 'src/app/arrangment/arrangment';
import { environment } from 'src/environments/environment';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { getDateFromPersonalId } from 'src/app/shared/helpers/jmbg';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { DomesticCityService } from 'src/app/inventory/domestic-city/domestic-city.service';
import { GENDER_OPTIONS } from 'src/app/contact/sex-options';
import { Title } from '@angular/platform-browser';
import { InquiryService } from 'src/app/inquiry/inquiry.service';
import { ArrangmentService } from 'src/app/arrangment/arrangment.service';
import { TravelApplicationService } from './travel-application.service';
import { PRICE_LIST_COLUMNS } from './columns/price-list-columns';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { TaPriceEditComponent } from './product-price/ta-price-edit.component';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ReservationStatus } from './travel-application-list.comonent';

export interface Client {
    ime: string;
    prezime: string;
}

@Component({
    selector: 'app-travel-application-edit',
    templateUrl: './travel-application-edit.component.html',
})
export class TravelApplicationEditComponent implements OnInit, OnDestroy {
    readonly LIDER_TRAVEL_APPLY_URL = environment.reservation_url; //@todo use environment

    id;
    title = '';
    hash_id = null;
    errors: any;
    inquiryId = null;
    LIST_PATH = this.travelApplicationService.LIST_PATH;
    arrangementConfig = [];
    isChangeMode = false;
    isStatusFilledIn = false;

    clientSearchList: Observable<Client[]>;
    selectedClient = new UntypedFormControl();
    clients: ClientObj[] = [];

    clientsForm = new UntypedFormArray([]);
    clientsFormConfig = [];

    clientsFormAditional = new UntypedFormArray([]);
    clientsAditionalFormConfig = [];
    clientAditionalFormShowHide = [];
    genderMap = new Map();

    organizerList: any[] = [];
    domesticCities: any[] = [];
    arrangmentList: any[] = [];
    selectedOrganizer = new UntypedFormControl();

    priceListColumns = PRICE_LIST_COLUMNS;
    priceTotal = null;
    priceTableData = [];

    currenciesMap = new Map();
    currencyList = [];

    feeTypes = [
        { value: 1, name: 'Aranžman' },
        { value: 2, name: 'Prevoz' },
        { value: 3, name: 'Osiguranje' },
        { value: 4, name: 'Drugo' }
    ];

    transportationTypes: object[] = [
        {
            id: 1,
            name: 'Bus'
        },
        {
            id: 2,
            name: 'Avio'
        },
        {
            id: 3,
            name: 'Sopstveni'
        },
    ];

    serviceTypesOptions: object[] = [
        {
            name: "Noćenje",
            id: "1",
        },
        {
            name: "Noćenje sa doručkom",
            id: "2"
        },
        {
            name: "Polupansion",
            id: "3",
        },
        {
            name: "Pun pansion",
            id: "4",
        },
        {
            name: "All inclusive",
            id: "5",
        },
        {
            name: "Ultra all inclusive",
            id: "6",
        },
        {
            name: "Ostalo",
            id: "7",
        }
    ];

    serviceType = SERVICE_TYPE;

    allSub: Subscription[] = [];
    isLoading: boolean = false;
    dataLoaded = false;
    reload: EventEmitter<any> = new EventEmitter();

    hotelOptions = [];
    jsonData = null; //from inquiry
    @ViewChild('organizerForm') organizerForm: DynamicFormComponent;
    @ViewChild('arrangementForm') arrangementForm: DynamicFormComponent;

    constructor(
        private _snackBar: MatSnackBar,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private contactService: ContactService,
        private contractService: ContractService,
        private travelApplicationService: TravelApplicationService,
        private arrangementService: ArrangmentService,
        private inquiryService: InquiryService,
        private operatorService: OrganizerService,
        private domesticCityService: DomesticCityService,
        private transactionService: TransactionService,
        private formEventService: FormEventService,
        private responseMsgService: ResponseMessageService,
        private router: Router,
        private titleService: Title,
        private clipboard: Clipboard,
        private responseMessageService: ResponseMessageService,
    ) {
    }

    ngOnInit() {
        GENDER_OPTIONS.forEach(g => {
            this.genderMap.set(g.id, g);
        });

        this.transactionService.getCurrencies().subscribe(currencies => {
            this.currencyList = currencies;
            currencies.forEach(currency => {
                this.currenciesMap.set(currency.id, currency);
            });
        });

        forkJoin(
            {
                organizers: this.operatorService.find({ limit: 20 }),
                domesticCities: this.domesticCityService.find({ limit: 20 }),
            }
        ).subscribe(res => {
            if (res.organizers['data']) {
                this.organizerList = res.organizers['data'].map(one=> {
                    return {
                        name: one.name,
                        value: one.id,
                    }
                });
            }
            if (res.domesticCities['data']) {
                this.domesticCities = res.domesticCities['data'].map(city => {
                    return {
                        value: city.id,
                        name: city.name,
                    }
                });
            }

            const obs1$ = this.reload;
            const obs2$ = combineLatest([this.route.params,
            this.route.queryParams]);

            const eventKeys = {
                obs1$,
                obs2$,
            };
            // Dummy eventsGuestsLookup observable.
            this.allSub.push(of(eventKeys)
                .pipe(mergeMap(ev => {
                    // We merge all observables in new one.
                    return merge(...Object.keys(ev).map(k => ev[k]));
                }))
                .pipe(
                    switchMap(p => {
                        const id = p[0]?.id ?? null;
                        if (id === 'new') {
                            this.inquiryId = p[1]?.fromInquiry ?? null;
                            if (this.inquiryId) {
                                return this.inquiryService.findById(this.inquiryId, 'data,arrangement');
                            } else {
                                return of({});
                            }
                        } else {
                            this.id = id;
                            return this.travelApplicationService.findById(this.id);
                        }
                    }),
                    tap((p: any) => {
                        if (!this.id) {
                            this.loadTravelApplicationByInquiry(p);
                        } else {
                            this.loadTravelApplication(p);
                        }
                    })
                )
                .subscribe(
                    contract => {
                        if (contract['success']) {
                            this.setContractData(contract['data']);
                        } else {
                            //@todo error
                            this.setContractData({});
                            //this.errors = 'Error loading';
                        }
                        this.dataLoaded = true;
                    },
                    err => {
                        this.showError(err?.error?.message);
                        this.dataLoaded = true; //@todo will it fail ßin case of error
                    }
                ));
        });



        this.allSub.push(
            this.selectedOrganizer
                .valueChanges
                .pipe(
                    debounceTime(300),
                    switchMap(value => this.operatorService.find({ name: value }))
                )
                .subscribe(results => this.organizerList = results['data'].map(one=> {
                    return {
                        name: one.name,
                        value: one.id,
                    }
                }))
        );

        this.allSub.push(
            this.selectedClient
                .valueChanges
                .pipe(
                    debounceTime(300),
                    switchMap(value => this.getClients(value))
                )
                .subscribe(clients => this.clientSearchList = clients['data'])
        );

        this.allSub.push(this.formEventService.eventEmitter$.subscribe(event => {
            if (event.message == 'contract-arrangement-selected') {
                //this.cisArrangementOptions = event.value.cisArrangements.map(item => { return { id: item.cis_id, name: item?.program?.program_name ?? item.cis_id } })
                const cityName = event.value?.city?.name ? event.value.city.name : '';
                const countryName = event.value?.city?.country?.name ? event.value.city.country.name : '';
                this.arrangementForm.setValue('arrangement_id', event.value.id);
                this.arrangementForm.setValue('arrangement_city', cityName);
                this.arrangementForm.setValue('arrangement_country', countryName);
                //@todo maybe show mesage also no hotel found
                if (event?.value?.id) {
                    this.getHotels(event.value.id);
                }
                this.arrangementForm.setValue('cis_program_id', null);
                /*
                if (this.cisArrangementOptions.length == 1) {
                    this.arrangementForm.setValue('cis_program_id', this.cisArrangementOptions[0]['id']);
                }*/
            }

            if (event.message == 'contract-hotel-selected') {
                const data = event.value;
                this.setSelectedHotelData(data);
            }

            if (event.message == 'lt_personal_id_clicked' || event.message == 'lt_personal_id_changed') {
                const customerBirthControl = event.el.group.controls.customer_birth || null;
                const personalIdControl = event.el.group.controls.personal_id;
                //if (!customerBirthControl?.value ) {
                const personalId = personalIdControl.value;
                if (personalId && personalId.length === 13) {
                    const date = getDateFromPersonalId(personalId);
                    customerBirthControl.setValue(date);
                }
                //}
            }
            //fiscal updated refresh the data
            if (event.message == 'lt-contract-fiscal-updated') {
                this.reloadData();
            }

        }));
    }

    setSelectedHotelData(data) {
        if (data.service !== undefined) {
            this.arrangementForm.setValue('service', data.service);
        }
        if (data.room !== undefined) {
            this.arrangementForm.setValue('room_name', data.room);
        }
        if (data.hotelName !== undefined) {
            this.arrangementForm.setValue('arrangment_accomodation', { value: data.id, name: data.hotelName });
        }
        if (data.id !== undefined) {
            this.arrangementForm.setValue('arrangment_accomodation_id', data.id);
        }
        if (data.from !== undefined) {
            this.arrangementForm.setValue('date_from', data.from);
        }
        if (data.to !== undefined) {
            this.arrangementForm.setValue('date_to', data.to);
        }
        if (data.check_in !== undefined) {
            this.arrangementForm.setValue('check_in_date', data.check_in);
        }
        if (data.check_out !== undefined) {
            this.arrangementForm.setValue('check_out_date', data.check_out);
        }
        if (data.days_number !== undefined) {
            this.arrangementForm.setValue('days_number', data.days_number);
        }
        if (data.nights_number !== undefined) {
            this.arrangementForm.setValue('nights_number', data.nights_number);
        }
    }
    fetchContractData(id) {
        return of({});
        //@todo
        //return this.contractService.findById(id, 'arrangements,customers,fees,bookOfContracts,insurance');
    }
    setContractData(contractResponse) {
        if (this.id) {
            this.title = 'Prijavni list #' + this.id;
            this.hash_id = contractResponse.hash_id;
            //this.cisId = Number(contractResponse['cis_contract_id']);
        } else {
            this.title = 'Novi prijavni list';
        }
        this.titleService.setTitle(this.title);

        //@todo on edit set selected organizer

        //this.cisArrangementOptions = arrangement?.cisArrangements ? arrangement.cisArrangements.map(item => { return { id: item.cis_id, name: item?.program?.program_name ?? item.cis_id } }) : [];
        //if ther eis only one option select it
        /*
        if (this.cisArrangementOptions.length == 1 && !arrangement.cis_program_id) {
            arrangement.cis_program_id = this.cisArrangementOptions[0]['id'];
        }
        */
    }

    getClients(event) {
        let filter = new ContactFilter();
        filter.q = event;
        return this.contactService.find(filter);
    };

    addClient(client) {
        const tmpClient = client.value;
        const mappedClient: ClientObj = {
            customer_id: tmpClient.id,
            customer_address: tmpClient.address,
            customer_city: tmpClient.city,
            customer_postcode: tmpClient.postcode,
            customer_birth: tmpClient.birthday,
            customer_email: tmpClient.email,
            customer_name: tmpClient.name,
            customer_lastname: tmpClient.lastname,
            customer_passport_expires: tmpClient.passport_expiry,
            customer_passport_no: tmpClient.passport_number,
            personal_id: tmpClient.personal_id,
            customer_phone: tmpClient.phone,
            // nema na api
            id: null,
            contract_id: null,
            customer_city_id: 0,
            sex: tmpClient.sex,
            contract_holder: tmpClient.contract_holder || this.clients.length == 0 ? 1 : 0 //if it is first passenger set it as holder
        }
        this.clients.push(mappedClient);
        this.setClientsFormConfig(mappedClient);
        this.setClientsAditionalFormConfig(mappedClient);
        this.selectedClient.setValue('');
    }

    deleteClient(index) {
        var confirmDialog = confirm('Stavka će biti bespovratno obrisana. Da li ste sigurni?');
        if (confirmDialog) {
            const client = this.getClientForm(index);
            if (!!this.id && client.value.id) {
                this.contractService.deleteCustomer(this.id, client?.value?.id).subscribe(
                    result => {
                        if (result['success'] == true) {
                            this.deleteClientFromList(index);
                        } else {
                            this.showError('Nepoznata greška', 'U redu');
                        }
                    },
                    err => {
                        if (err?.error?.message) {
                            this.showError(err.error.message, 'U redu');
                        } else {
                            this.showError('Nepoznata greška', 'U redu');
                        }
                    }
                );
            } else {
                this.deleteClientFromList(index);
            }
        }
    }

    deleteClientFromList(index) {
        this.clientsForm.removeAt(index);
        this.clients.splice(index, 1);
        this.clientsFormConfig.splice(index, 1);
        this.clientAditionalFormShowHide.splice(index, 1);
        this.clientsAditionalFormConfig.splice(index, 1);
        this.clientsFormAditional.removeAt(index);
    }

    getClientForm(index) {
        return this.clientsForm.at(index) as UntypedFormGroup;
    }
    getClientFormDetail(index) {
        return this.clientsFormAditional.at(index) as UntypedFormGroup;
    }

    displayClient(kupac: Contact) {
        if (kupac) {
            return kupac.name + ' ' + kupac.lastname;
        }
    }

    loadTravelApplicationByInquiry(p) {
        const arrangementId = p?.data?.arrangement_id ?? null;
        const organizerId = p?.data?.organizer_id ?? null;
        this.setOrganizerId(organizerId);

        const jsonData = p?.data?.data ?? null;
        this.jsonData = jsonData;
        const customerNameLastname = jsonData?.ime ?? '';
        let customerArray = customerNameLastname.split(' ');
        const customerName = (customerArray[0] ?? '').trim();
        const customerLastName = (customerArray[1] ?? '').trim();

        const singleCustomer: ClientObj = {};
        singleCustomer.customer_name = customerName;
        singleCustomer.customer_lastname = customerLastName;
        singleCustomer.customer_phone = jsonData && jsonData?.telefon ? jsonData?.telefon : '';
        singleCustomer.customer_email = jsonData && jsonData?.email ? jsonData?.email : '';
        singleCustomer.contract_holder = 1;

        this.setCustomersForm([singleCustomer]);
        if (arrangementId) {
            this.arrangementService.find({ id: arrangementId }).subscribe(resp => {
                let arrangement = resp.data[0] ?? {};
                const cityName = arrangement?.city?.name ?? '';
                const countryName = arrangement?.city?.country?.name ?? '';
                let arrangementConfig = {
                    arrangement_name: arrangement?.name ?? '',
                    arrangement_id: arrangement?.id ?? '',
                    arrangement_city: cityName,
                    arrangement_country: countryName,
                    arrangment_accomodation: this.jsonData?.smestaj ?? '',
                    room_name: this.jsonData?.tipSobe ?? '',
                };

                this.setArrangementFormConfig(arrangementConfig, 'form arrangment find body');
                this.getHotels(arrangementId);
            });
        } else {
            this.setArrangementFormConfig({});
        }
    }

    loadTravelApplication(p) {
        const item = p.data ?? {};
        const arrangement = item?.arrangements[0] ? item?.arrangements[0] : {};
        const customers = item?.customers;
        const fees = item?.fees ?? [];
        const organizerId = item?.organizer_id ?? 0;
        const arrangementId = arrangement.arrangement_id ?? null;
        this.setOrganizerId(organizerId);

        const customersList = [];
        customers.forEach(singleCustomer => {
            customersList.push(singleCustomer);
        });

        this.setCustomersForm(customersList);
        this.setArrangementFormConfig(arrangement);
        this.getHotels(arrangementId);
        this.priceTableData = [];
        fees.forEach((fee, idx) => {
            this.addFee(fee);
        });
        this.isChangeMode = ReservationStatus.CHANGE_MODE == item.status;
        this.isStatusFilledIn = ReservationStatus.FILLED_IN == item.status;
    }

    setOrganizerId(organizerId) {
        const selectedOrganizer = this.organizerList.find(el => el.value == organizerId);
        if (selectedOrganizer) {
            this.selectedOrganizer.setValue(selectedOrganizer);
        }
    }
    setClientsFormConfig(client: ClientObj | null = null) {
        const clientFormConfig: FieldConfig[] = [
            {
                type: 'checkbox',
                label: 'Nosilac ugovora',
                name: 'contract_holder',
                placeholder: 'Nosilac ugovora',
                value: Number(client?.contract_holder || '0'),
                col: 2
            },
            //should be disabled
            {
                type: 'input',
                label: 'UID Putnik',
                name: 'customer_id',
                placeholder: 'UID Putnik',
                validation: [Validators.required],
                value: client?.customer_id || null,
                disabled: true,
                col: 1
            },
            {
                type: 'input',
                label: 'Ime',
                name: 'customer_name',
                placeholder: 'Ime',
                validation: [Validators.required],
                value: client?.customer_name || '',
                col: 2
            },
            {
                type: 'input',
                label: 'Prezime',
                name: 'customer_lastname',
                placeholder: 'Prezime',
                validation: [Validators.required],
                value: client?.customer_lastname || '',
                col: 2
            },
            {
                type: 'input',
                label: 'Telefon',
                name: 'customer_phone',
                placeholder: 'Telefon',
                //validation: [Validators.required],
                value: client?.customer_phone || null,
                col: 2
            },
            {
                type: 'input',
                label: 'Email',
                name: 'customer_email',
                placeholder: 'Email',
                validation: [Validators.required],
                value: client?.customer_email || null,
                col: 3
            },
            {
                type: 'select',
                label: 'Pol',
                name: 'sex',
                value: Number(client.sex) || null,
                placeholder: 'Pol',
                //validation: [Validators.required],
                options: GENDER_OPTIONS,
                col: 1
            },
            {
                type: 'input',
                label: 'JMBG',
                name: 'personal_id',
                placeholder: 'JMBG',
                // validation: [Validators.required],
                value: client?.personal_id || null,
                col: 2,
                eventMessages: {
                    onClick: 'lt_personal_id_clicked',
                    onKeyup: 'lt_personal_id_changed',
                }
            },
            {
                type: 'datepicker',
                label: 'Datum rodjenja',
                name: 'customer_birth',
                placeholder: 'Datum rodjenja',
                //validation: [Validators.required],
                value: client?.customer_birth || null,
                col: 2
            },
            {
                type: 'input',
                label: 'Broj pasoša',
                name: 'customer_passport_no',
                placeholder: 'Broj pasoša',
                // validation: [Validators.required],
                value: client?.customer_passport_no || null,
                col: 2
            },
            {
                type: 'input',
                label: 'Ulica',
                name: 'customer_address',
                placeholder: 'Ulica',
                //validation: [Validators.required],
                value: client?.customer_address || null,
                col: 3
            },
            {
                type: 'input',
                label: 'Grad',
                name: 'customer_city',
                placeholder: 'Grad',
                //validation: [Validators.required],
                value: client?.customer_city || null,
                col: 2
            },
            {
                type: 'hidden',
                name: 'id',
                value: client?.id || null,
            }
        ];
        this.clientsFormConfig.push(clientFormConfig);
        this.clientsForm.push(prepareFormGroup(clientFormConfig));
    }

    setClientsAditionalFormConfig(client) {
        const clientAditionalFormConfig: FieldConfig[] = [
            {
                type: 'autocomplete',
                label: 'Mesto ulaska',
                name: 'sett_entry_city',
                placeholder: 'Mesto ulaska',
                //validation: [Validators.required],
                searchStringKey: 'name',
                value: { value: client.entryCity?.id ?? null, name: client.entryCity?.name ?? null },
                eventMessages: {
                    onChange: 'contract-entry-city-selected'
                },
                options: this.domesticCities,
                col: 3
            },
            {
                type: 'input',
                label: 'Broj busa/aviona',
                name: 'sett_transport_no',
                placeholder: 'Broj busa/aviona',
                value: client?.sett_transport_no || '',
                col: 1
            },
            {
                type: 'input',
                label: 'Broj sedišta',
                name: 'sett_seat_no',
                placeholder: 'Broj sedišta',
                value: client?.sett_seat_no || null,
                col: 1
            },
            {
                type: 'input',
                label: 'Broj sobe',
                name: 'sett_room_no',
                placeholder: 'Broj sobe',
                value: client?.sett_room_no || null,
                col: 2
            },
            {
                type: 'select',
                label: 'Osiguranje',
                name: 'sett_insurance',
                placeholder: 'Osiguranje',
                value: typeof client?.sett_insurance != 'undefined' ? Number(client?.sett_insurance) : 1,
                col: 2,
                options: [
                    {
                        id: 1,
                        name: 'DA'
                    },
                    {
                        id: 0,
                        name: 'NE'
                    },
                ]
            },
            {
                type: 'input',
                label: 'Napomena',
                name: 'sett_notice',
                placeholder: 'Napomena',
                value: client?.sett_notice || null,
                col: 3
            },
        ];
        this.clientAditionalFormShowHide.push({ visible: true });
        this.clientsAditionalFormConfig.push(clientAditionalFormConfig);
        this.clientsFormAditional.push(prepareFormGroup(clientAditionalFormConfig));
    }

    setArrangementFormConfig(values, where = null) {
        if (values.departureCity) {
            values.departure_city = values.departureCity;
        }
        const transportObj = this.transportationTypes.find(item => item['id'] == values['transport_type']);
        const transportVal = transportObj && transportObj['id'] ? transportObj['id'] : null;
        let accomodation = values['arrangment_accomodation'];
        let accomodationValue = {
            value: null,
            name: ''
        };
        if (typeof accomodation == 'object') {
            accomodationValue = {
                value: accomodation?.id ?? null,
                name: accomodation?.name ?? '',
            };
        } else {
            accomodationValue = {
                value: values['arrangment_accomodation_id'],
                name: values['arrangment_accomodation'],
            }
        }
        this.arrangementConfig = [
            {
                type: 'hidden',
                label: 'Contract Has Arrangment ID',
                name: 'id',
                value: values['id'] ? values['id'] : null,
            },
            {
                type: 'hidden',
                label: 'Arrangment ID',
                name: 'arrangement_id',
                value: values['arrangement_id'] ? values['arrangement_id'] : null,
            },
            {
                type: 'hidden',
                label: 'Arrangment Accomodation ID',
                name: 'arrangment_accomodation_id',
                value: values['arrangment_accomodation_id'] ? values['arrangment_accomodation_id'] : null,
            },
            {
                type: 'autocomplete',
                label: 'Naziv aranžmana',
                name: 'arrangement_name',
                placeholder: 'Naziv aranžmana',
                validation: [Validators.required],
                searchStringKey: 'name',
                value: {
                    value: values['id'],
                    name: values['arrangement_name']?.name ?? values['arrangement_name']
                },//can be object or string
                api: {
                    hostUrl: environment.apiHost,
                    endpoint: '/lider/arrangment?status=1',
                    aditionalParams: {
                        organizer: () => {
                            return this.selectedOrganizer.value?.value ?? '';
                        }
                    }
                },
                eventMessages: {
                    onChange: 'contract-arrangement-selected'
                },
                col: 3
            },
            /*
            {
                type: 'select',
                label: 'Aranžman CIS',
                name: 'cis_program_id',
                placeholder: 'Aranžman CIS',
                value: values['cis_program_id'] ?? null,
                options: this.cisArrangementOptions,
                col: 2
            },
            */
            {
                type: 'input',
                label: 'Zemlja',
                name: 'arrangement_country',
                placeholder: 'Zemlja',
                validation: [Validators.required],
                value: values['arrangement_country'],
                col: 2
            },
            {
                type: 'input',
                label: 'Mesto',
                name: 'arrangement_city',
                placeholder: 'Mesto',
                value: values['arrangement_city'],
                col: 2
            },

            {
                type: 'autocomplete',
                label: 'Smeštaj',
                name: 'arrangment_accomodation',
                placeholder: 'Smeštaj',
                validation: [Validators.required],
                value: accomodationValue,
                options: this.hotelOptions,
                eventMessages: {
                    onChange: 'contract-hotel-selected'
                },
                col: 2
            },
            {
                type: 'input',
                label: 'Tip sobe',
                name: 'room_name',
                placeholder: 'Tip sobe',
                value: values['room_name'],
                col: 2
            },
            {
                type: 'input',
                label: 'Usluga',
                name: 'service',
                placeholder: 'Usluga',
                validation: [Validators.required],
                value: values['service'],
                col: 1
            },
            {
                type: 'select',
                label: 'Usluga CIS',
                name: 'cis_service',
                placeholder: 'Usluga CIS',
                value: values['cis_service'],
                options: this.serviceTypesOptions,
                validation: [Validators.required],
                col: 2
            },
            {
                type: 'select',
                label: 'Prevoz',
                name: 'transport_type',
                options: this.transportationTypes,
                placeholder: 'Prevoz',
                validation: [Validators.required],
                value: transportVal,
                col: 2
            },
            {
                type: 'autocomplete',
                label: 'Mesto polaska',
                name: 'departure_city',
                placeholder: 'Mesto polaska',
                validation: [Validators.required],
                searchStringKey: 'name',
                value: { value: values?.departure_city?.id ? values?.departure_city?.id : (values?.departure_city?.value ? values.departure_city.value : null), name: values.departure_city?.name ?? null },
                eventMessages: {
                    onChange: 'contract-departure-cityselected'
                },
                options: this.domesticCities,
                col: 2
            },
            {
                type: 'input',
                label: 'Broj dana',
                name: 'days_number',
                placeholder: 'Broj dana',
                value: values['days_number'],
                col: 1
            },
            {
                type: 'input',
                label: 'Broj noći',
                name: 'nights_number',
                placeholder: 'Broj noći',
                value: values['nights_number'],
                col: 1
            },
            {
                type: 'datepicker',
                label: 'Datum od',
                name: 'date_from',
                placeholder: 'Datum od',
                value: values['date_from'],
                col: 2
            },
            {
                type: 'datepicker',
                label: 'Datum do',
                name: 'date_to',
                placeholder: 'Datum do',
                value: values['date_to'],
                col: 2
            },
            {
                type: 'datepicker',
                label: 'Datum prijave',
                name: 'check_in_date',
                placeholder: 'Datum prijave',
                value: values['check_in_date'],
                col: 2
            },
            {
                type: 'datepicker',
                label: 'Datum odjave',
                name: 'check_out_date',
                placeholder: 'Datum odjave',
                value: values['check_out_date'],
                col: 2
            },
            {
                type: 'textarea',
                label: 'Napomena',
                name: 'notice',
                placeholder: 'Napomena',
                validation: [Validators.required],
                value: values['notice'],
                col: 4
            },
            {
                type: 'textarea',
                label: 'Interna napomena',
                name: 'notice_intern',
                placeholder: 'Interna napomena',
                value: values.notice_intern ?? null,
                col: 4
            },
        ];
    }

    openSnackBar(message: string, action = null, options = {}) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['success-snackbar'],
            ...options
        });
    }

    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }

    displayOperator(item: any) {
        if (item) {
            return item.name;
        }
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    getHotels(arrangementId) {
        this.contractService.getHotels(arrangementId).subscribe(resp => {
            if (resp.data) {
                if (resp.data.length == 0) {
                    this.showError('Za odabrani aranžman nije pronadjen ni jedan hotel');
                }
                this.hotelOptions = resp.data;
                const jsonData = this.jsonData ?? null;
                if (jsonData) {
                    const termin = jsonData?.termin ?? null;
                    const roomType = jsonData?.tipSobe ?? null;
                    const hotelName = jsonData?.smestaj ?? null;
                    if (termin && roomType && hotelName) {
                        //check is normal travel date format not custom or Big blue
                        if (termin.indexOf('-') != -1 && termin.indexOf('.') != -1) {
                            let expl = termin.split('-');
                            let from = expl[0].trim();
                            let to = expl[1].trim();
                            let explFrom = from.split('.');
                            let dayFrom = explFrom[0];
                            let monthFrom = explFrom[1];
                            let explTo = to.split('.');
                            let dayTo = explTo[0];
                            let monthTo = explTo[1];
                            let result = this.hotelOptions.find(hotel => hotel.hotelName.indexOf(hotelName) == 0
                                && hotel.from.indexOf(monthFrom + '-' + dayFrom) != -1
                                && hotel.to.indexOf(monthTo + '-' + dayTo) != -1
                                && (hotel.room + ' ' + hotel.service) == roomType);
                            if (result) {
                                this.setSelectedHotelData(result);
                            }
                        }
                    }
                }
                let arrFormValue = this.arrangementForm.form.getRawValue();
                this.setArrangementFormConfig(arrFormValue, 'fromHotels');
            } else {
                this.showError('Greška! Učitavanje liste hotela nije uspelo')
            }
        }, err => {
            this.showError(err.error.message);
        });
    }
    
    displayArrangment(item: Arrangment) {
        if (item) { return item.name; }
    }

    reloadData() {
        this.reload.emit([{
            id: this.id
        }]);
    }

    emptyOrganizerField() {
        this.selectedOrganizer.reset();
    }
    save(action = '') {
        const arrForm = this.arrangementForm.value;
        const arrangementName = arrForm?.arrangement_name?.name ? arrForm?.arrangement_name?.name : arrForm?.arrangement_name;
        const accomodationName = arrForm?.arrangment_accomodation?.name ? arrForm?.arrangment_accomodation?.name : arrForm?.arrangment_accomodation;
        const accomodationId = arrForm?.arrangment_accomodation?.value ? arrForm?.arrangment_accomodation.value : arrForm?.arrangment_accomodation_id;
        let customers = this.clientsForm.getRawValue();
        let customerAditionalDetails = this.clientsFormAditional.getRawValue();

        let customerError = '';
        customers = customers.map((customer, index) => {
            let entryCityId = customerAditionalDetails[index]?.sett_entry_city?.value ?? null;
            return {
                ...customer,
                ...customerAditionalDetails[index],
                sett_entry_city: entryCityId
            };
        });
        if (customerError) {
            this.showError(customerError);
            this.isLoading = false;
            return;
        }
        const fees = this.priceTableData;

        this.travelApplicationService.save({
            id: this.id,
            organizer: this.selectedOrganizer?.value?.value ?? null,
            inquiryId: this.inquiryId,
            //arrangment name can bi object(from autocomplete or free text in input)
            arrangment: {
                ...arrForm,
                arrangement_name: arrangementName,
                arrangment_accomodation: accomodationName,
                arrangment_accomodation_id: accomodationId,
                arrangement_id: arrForm.arrangement_name?.id ? arrForm.arrangement_name?.id : arrForm.arrangement_id,
                departure_city: arrForm.departure_city?.value ? arrForm.departure_city?.value : null,
            },
            customers: customers,
            fees: fees,
        }).subscribe(resp => {
            if (resp['success'] && resp['success'] === true) {
                this.openSnackBar('Uspešno sačuvano', 'U redu', {
                    panelClass: ['green-snackbar'],
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right',
                });
                //when saving new contract then take from response
                if (!this.id) {
                    this.router.navigate([this.LIST_PATH]);
                } else {
                    this.reloadData();
                }
            } else {
                if (resp.message) {
                    this.showError(resp.message, 'U redu');
                } else {
                    this.showError('Desila se greška. Molimo pokušajte ponovo', 'U redu');
                }
            }
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
            this.showError(err?.error?.message);
        });
    }

    setCustomersForm(customers) {
        this.clients = [];
        this.clientsForm = new UntypedFormArray([]);
        this.clientsFormConfig = [];

        this.clientsFormAditional = new UntypedFormArray([]);
        this.clientsAditionalFormConfig = [];
        this.clientAditionalFormShowHide = [];
        customers.forEach((client: ClientObj) => {
            this.clients.push(client);
            this.setClientsFormConfig(client);
            this.setClientsAditionalFormConfig(client);
            //@todomilos crate method for this and generaly adding clients
        });
    }

    showPriceAddEditDialog(formData?: any): void {
        if (!formData) {
            formData = {
                id: null,
                type: null,
                quantity: '',
                price: '',
                currency: null,
                discount: '',
                supplier_price: '',
                supplier_currency: null,
                comment: ''
            };
        }

        let data = {
            form: formData,
            isInDialog: true,
            parentId: this.id,
            currencySelectOptions: this.currencyList,
            productTypeOptions: this.feeTypes
        };
        const dialogRef = this.dialog.open(TaPriceEditComponent, {
            width: 'auto',
            data: data,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.event === 'success') {
                let priceItem = result.data;
                if (typeof priceItem.type == 'object') {
                    priceItem.name = priceItem.type?.name ?? '';
                    priceItem.type = priceItem.type?.value ?? null;
                } else {
                    priceItem.name = priceItem.type;
                    priceItem.type = 0;
                    console.log(priceItem)
                }

                if (this.id) {
                    priceItem.travel_application_id = this.id;
                    this.travelApplicationService.saveFee(priceItem).subscribe(
                        resp => {
                            if (resp.success) {
                                this.responseMsgService.showSuccess();
                                this.reloadData();
                            }
                        },
                        err => {
                            this.responseMsgService.showError(err?.error?.message);
                        }
                    );
                } else {
                    this.addFee(priceItem);
                }
            }
        });
    }

    addFee(priceItem) {
        priceItem.currency = Number(priceItem.currency);
        priceItem.supplier_currency = Number(priceItem.supplier_currency);
        const price = parseFloat(Number(priceItem.price).toFixed(2));
        const discount = parseFloat(Number(priceItem.discount).toFixed(2));
        const quantity = priceItem.quantity;
        const total = (price * quantity - discount).toFixed(2);
        const currencyName = this.currencyList.find(item => item.id == priceItem.currency)?.symbol || '';

        const supplierPrice = parseFloat(Number(priceItem.supplier_price ?? 0).toFixed(2));
        const supplierCurrencyId = Number(priceItem.supplier_currency);
        const supplierCurrency = this.currencyList.find(item => item.id == supplierCurrencyId)?.symbol || '';
        let supplierTotal = (supplierPrice * quantity).toFixed(2);

        priceItem.price_formatted = {
            currencyName: currencyName,
            value: price
        };
        priceItem.discount_formatted = {
            currencyName: currencyName,
            value: discount
        };
        priceItem.total_formatted = [{
            currencyName: currencyName,
            value: total
        }];

        priceItem.supplier_price_formatted = {
            currencyName: supplierCurrency,
            value: supplierPrice
        };
        priceItem.supplier_total_formatted = {
            currencyName: supplierCurrency,
            value: supplierTotal
        };
        /*
         * used to recognize is row edited and to find it in list of rows and update
         * in case there is no ltRowIndex itmeans row should be added to rowslist (new item added)
         * This is all in case when travelapplication is being craeted when it exists fee is added/edited directly to database 
         */
         if (priceItem.ltRowIndex) {
            const index = this.priceTableData.findIndex(el => el.ltRowIndex == priceItem.ltRowIndex);
            this.priceTableData[index] = priceItem;
            this.priceTableData = [...this.priceTableData];
        } else {
            priceItem.ltRowIndex = priceItem.ltRowIndex ?? this.priceTableData.length + 1;
            this.priceTableData = [...this.priceTableData, priceItem];
        }
    }
    onPriceTableEvent(event) {
        if (event.name == 'edit-fee') {

            if (typeof event.data.type != 'object') {
                event.data.type = this.feeTypes.find(item => item.value == event.data.type);
                //event.data.ltRowIndex = this.feeTypes.find(item => item.value == event.data.type);
            } else {
                event.data.type = {
                    value: 0,
                    name: event.data?.name ?? '',
                };
            }
            this.showPriceAddEditDialog(event.data);
        }
    }
    
    setChangeModeStatus() {
        if (this.id) {
            this.travelApplicationService.setChangeModeStatus(this.id).subscribe(res => {
              if (res['success']) {
                this.responseMessageService.showSuccess('Rezervacija je otključana za promene');
                this.reloadData();
              }
            }, err => {
              this.responseMessageService.showError(err?.error?.message);
            });
        }
    }

    setFilledInStatus() {
        if (this.id) {
            this.travelApplicationService.setFilledInStatus(this.id).subscribe(res => {
              if (res['success']) {
                this.responseMessageService.showSuccess('Rezervacija je otključana za promene');
                this.reloadData();
              }
            }, err => {
              this.responseMessageService.showError(err?.error?.message);
            });
        }
    }
/*
    async sharePrice() {
        try {
          await navigator.share({
            title: 'Rezervacija putovanja',
            text:  this.hash_id ? this.LIDER_TRAVEL_APPLY_URL + this.hash_id : ''
          });
        } catch (err) {
          console.log(`Error: ${err}`);
        }
      }
*/
    copyToClipboard() {
        this.clipboard.copy(this.hash_id ? this.LIDER_TRAVEL_APPLY_URL + this.hash_id : '');
    }

    ngOnDestroy(): void {
        for (const sub of this.allSub) {
            if (!sub.closed) {
                sub.unsubscribe();
            }
        }
    }
}

interface PriceFormConfig {
    id?;
    type?;
    quantity?;
    price?;
    currency?;
    discount?;
    supplier_price?;
    supplier_currency?;
    comment?;
    submit?;
}