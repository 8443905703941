<style>
    table{
       width: 100%;
   }
  </style>
  <mat-toolbar >    
   <mat-toolbar-row>
       <span>{{title}} | LiderTravel</span>
       <span class="example-spacer"></span>
   </mat-toolbar-row>
  </mat-toolbar>
  <ng-container *ngIf="loaded">
    <table mat-table [dataSource]="dataSource" class="general-table">
      <ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}">
      <ng-container *ngIf="column.name != 'actions'">
          <th mat-header-cell *matHeaderCellDef [class.align-right]="column.type && column.type == 'money'">
              {{column.label}}
          </th>
      </ng-container>
      <ng-container *ngIf="column.name == 'actions'">
        <th mat-header-cell *matHeaderCellDef>
            <a *ngIf="canAddTransaction" [routerLink]="[column.headerPath]">
                <button mat-mini-fab color="primary"
                    matTooltip="{{column.tooltip.title}}"
                    [matTooltipPosition]="((column.tooltip && column.tooltip.position ) ? column.tooltip.position : 'below')"
                >
                    <i class="material-icons">add</i> 
                </button>
            </a>
        </th>
    </ng-container>

    <!-- Footer cell -->
    <ng-container *ngIf="totals && column.type === 'money'">
        <td mat-footer-cell *matFooterCellDef>
            <span style="display:block;text-align:right;padding-right: 10px;">Ukupno: {{totals[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}</span>
        </td>
    </ng-container>
    <ng-container *ngIf="!column.type">
        <td mat-footer-cell *matFooterCellDef>{{totals[column.name]}}</td>
    </ng-container>
  <!-- END Footer cell -->
      
      <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="column.name != 'actions'">
              <div *ngIf="column.type && column.type == 'money'">
                  <span style="display:block;text-align:right;padding-right: 10px;">
                      {{element[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}
                  </span>
              </div>
              <div *ngIf="column.type && column.type == 'date'">
                      {{element[column.name] | date: 'dd.MM.yyyy'}}
              </div>
              <div *ngIf="!column.type">
                  {{element[column.name]}}
              </div>
          </ng-container>
          <ng-container *ngIf="column.name == 'button'">
              <a class="row-action" [routerLink]="[column.actions.path+element[column.actions.param]]"> 
                  <button mat-raised-button color="warn">
                      <mat-icon>{{column.actions.icon}}</mat-icon>
                      <span>{{column.actions.name}}</span>
                  </button>
              </a>
          </ng-container>
          <div *ngIf="column.name == 'actions'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <ng-container *ngFor="let action of column.actions" >
                    <a *ngIf="!action.type" class="row-action" target="_blank" [routerLink]="[action.path+element[action.param]]">
                        <button mat-button color="primary">
                                <mat-icon>{{action.icon}}</mat-icon>
                                <span>{{action.name}}</span>
                        </button>
                    </a>
                    <button *ngIf="action.type && action.type == 'goto-action'" (click)="this[action.callback](element)" mat-button color="primary">
                            <mat-icon>{{action.icon}}</mat-icon>
                            <span>{{action.name}}</span>
                    </button>
                  </ng-container>
              </mat-menu>
          </div>          
      </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container  *ngIf="totals">
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </ng-container>
      </table>
  </ng-container>
  