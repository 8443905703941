<style>
    .fab-container {
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 100;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        >div {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            margin-bottom: 5px;

            button {
                margin-bottom: 17px;
            }
        }
    }
</style>
<mat-progress-bar *ngIf="isSearching" mode="indeterminate"></mat-progress-bar>
<h2>Rezervacije</h2>
<app-lt-table [columns]="tableColumns" [dataSource]="dataSource" (tableEvent)="onTableEvent($event)"></app-lt-table>

<app-lt-pagination [totalCount]="totalRowsCount" [pageSize]="pageSize"
    (paginationEvent)="paginationChanged($event)"></app-lt-pagination>
<div class="fab-container">
    <a [routerLink]="[LIST_PATH +'/new']">
        <button mat-fab class="fab-toggler" color="warn">
            <i class="material-icons">add</i>
        </button>
    </a>
</div>