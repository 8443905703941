import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
    FormControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, merge, Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import {
    DynamicFormComponent
} from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { Contact } from 'src/app/contact/contact';
import { ContactFilter } from 'src/app/contact/contact-filter';
import { ContactService } from 'src/app/contact/contact.service';
import { OrganizerService } from 'src/app/organizer/organizer.service';
import { SERVICE_TYPE } from 'src/app/shared/constants/service-type';
import { Contract } from '../contract';
import { ContractService } from '../contract.service';
import { prepareFormGroup } from "../../shared/helpers/form";
import { ClientObj } from "../client";
import { MatDialog } from '@angular/material/dialog';
import { TransactionEditComponent } from 'src/app/transaction-new/transaction-edit/transaction-edit.component';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { TableColumn, TableEvent } from 'src/app/shared/lt-table/lt-table';
import { Arrangment } from 'src/app/arrangment/arrangment';
import { environment } from 'src/environments/environment';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { getDateFromPersonalId } from 'src/app/shared/helpers/jmbg';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { ContractComponent } from '../contract.component';
import { formatDate, formatDatetime } from 'src/app/shared/helpers/date';
import { DomesticCityService } from 'src/app/inventory/domestic-city/domestic-city.service';
import { autocompleteValidator } from 'src/app/components/dynamic-form-master/components/form-autocomplete/form-autocomplete.component';
import { GENDER_OPTIONS } from 'src/app/contact/sex-options';
import { formatMoney } from 'src/app/shared/helpers/currency';
import { CURRENCY_OWE_LIMIT } from 'src/app/shared/constants/owe-limit';
import { InsuranceStatus } from 'src/app/insurance/insurance-type';
import { Title } from '@angular/platform-browser';
import { TravelApplicationService } from '../travel-application/travel-application.service';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { CisStornoDialogComponent } from '../cis-storno-dialog/cis-storno-dialog.component';
import { DomesticCityAssignComponent } from 'src/app/inventory/domestic-city/domestic-city-assign.component';
//import { saveAs } from 'file-saver';

export interface Client {
    ime: string;
    prezime: string;
}

@Component({
    selector: 'contract-edit-new',
    templateUrl: './contract-edit-new.component.html',
    //host: {'(window:scroll)': 'track($event)'},
    styleUrls: ['contract-edit-new.component.scss']
})
export class ContractEditNewComponent implements OnInit, OnDestroy {
    readonly OWE_LIMIT = CURRENCY_OWE_LIMIT;
    mainCurrencyName = TransactionService.MAIN_CURRENCY.name;
    readonly FINALIZE_ACTION = 'finalize';
    id;
    cisId;
    incrementalNumber;
    contractStatus = new FormControl('');
    title = '';
    viewMode: null | 'clone' | 'fromApplication';
    contractsBookId: number;
    reservationId: number;
    contract: any;
    errors: any;

    currencyList = [];
    organizerConfig = [];
    arrangementConfig = [];
    reservationConfirmationConfig = [];

    clientSearchList: Observable<Client[]>;
    selectedClient = new UntypedFormControl();
    clients: ClientObj[] = [];

    clientsForm = new UntypedFormArray([]);
    clientsFormConfig = [];

    clientsFormAditional = new UntypedFormArray([]);
    clientsAditionalFormConfig = [];
    clientAditionalFormShowHide = [];
    genderMap = new Map();

    organizerList: any[] = [];
    domesticCities: any[] = [];
    arrangmentList: any[] = [];
    selectedOperator = new UntypedFormControl();

    showAddFeeForm: boolean = false;
    feeListingColumns = [
        { name: 'name', label: 'Naziv' },
        { name: 'quantity', label: 'Količina', className: 'align-center' },
        { name: 'price_formatted', label: 'Cena', type: 'money-object' },
        { name: 'discount_formatted', label: 'Popust', type: 'money-object' },
        { name: 'total_formatted', label: 'Ukupno', type: 'money-list' },
        { name: 'supplier_price_formatted', label: 'Cena dobavljača', type: 'money-object' },
        { name: 'supplier_total_formatted', label: 'Dobavljač ukupno', type: 'money-object' },
        { name: 'comment', label: 'Komentar' },
        {
            name: 'button',
            label: 'Akcije',
            actions: [
                {
                    type: 'event',
                    actionName: 'edit-fee',
                    name: 'Uredi',
                    icon: 'edit'
                },
                {
                    type: 'event',
                    actionName: 'delete-fee',
                    name: 'Obriši',
                    icon: 'delete'
                }
            ]
        }
    ];
    contractHasFee = [];
    feeTableDataSource = new BehaviorSubject([]);
    feeTotal = {};
    feeFormConfig = [];
    feeTypes = [
        { value: 1, name: 'Aranžman' },
        { value: 2, name: 'Prevoz' },
        { value: 3, name: 'Osiguranje' },
        { value: 4, name: 'Drugo' },
        { value: 5, name: '2x noćenje' }
    ];

    supplierTransactionColumns: any[] = [
        { name: 'increment_id', label: '#' },
        { name: 'in_out', label: 'Upl./Ispl.' },
        { name: 'amount', label: 'Iznos', className: 'align-right' },
        { name: 'amountInCurrencies', label: 'Iznos pregled', className: 'align-right' },
        { name: 'account_type', label: 'Metod' },
        { name: 'paymentSubjectName', label: 'Vrsta' },
        { name: 'reference_text', label: 'Referenca' },
        { name: 'original_date', label: 'Datum izvoda' },
        { name: 'user', label: 'Službenik' },
        { name: 'dateTime', label: 'Dat./Vreme' },
        { name: 'comment', label: 'Komentar' },
    ];
    supplierTransactionDataSource = [];
    supplierTransactionFooterTotal = {};
    supplierMainCurrencyOweTotal = {
        amount: 0,
        error: '',
    };
    financeStatusSupplier = [];

    mainCurrencyOweTotal = {
        originalAamount: '',
        amount: '', //main curr
        error: '',
        paidIn: '', //main curr
        servicesTotal: '', //EUR curr
    };

    fiscalReceipts = [];

    //general contract info properties
    financeStatus = [];
    payedInTotal = [];
    totalPayedInMainCurrency = 0;
    totalPayedInMainCurrencyFormated: string;

    transportationTypes: object[] = [
        {
            id: 1,
            name: 'Bus'
        },
        {
            id: 2,
            name: 'Avio'
        },
        {
            id: 3,
            name: 'Sopstveni'
        },
    ];

    serviceTypesOptions: object[] = [
        {
            name: "Noćenje",
            id: "1",
        },
        {
            name: "Noćenje sa doručkom",
            id: "2"
        },
        {
            name: "Polupansion",
            id: "3",
        },
        {
            name: "Pun pansion",
            id: "4",
        },
        {
            name: "All inclusive",
            id: "5",
        },
        {
            name: "Ultra all inclusive",
            id: "6",
        },
        {
            name: "Ostalo",
            id: "7",
        }
    ];

    serviceType = SERVICE_TYPE;

    transactionDataSource: any[] = [];
    transactionsTotalPerMoneyType = [];
    transactionColumns: TableColumn[] = [
        { name: 'increment_id', label: '#' },
        { name: 'in_out', label: 'Upl./Ispl.' },
        TransactionService.TRANSACTION_PRINT_COL,
        { name: 'amount', label: 'Iznos', className: 'align-right' },
        { name: 'amountInCurrencies', label: 'Iznos pregled', className: 'align-right' },
        { name: 'account_type', label: 'Metod' },
        { name: 'paymentSubjectName', label: 'Vrsta' },
        { name: 'reference_text', label: 'Referenca' },
        { name: 'original_date', label: 'Datum izvoda' },
        { name: 'user', label: 'Službenik' },
        { name: 'dateTime', label: 'Dat./Vreme' },
        { name: 'comment', label: 'Komentar' },
    ];
    transactionFooterTotal = {};

    inOrOutMap = new Map();
    accountTypeMap = new Map();
    currenciesMap = new Map();
    contractStatusMap = new Map();

    allSub: Subscription[] = [];
    isLoading: boolean = false;
    dataLoaded = false;
    reload: EventEmitter<any> = new EventEmitter();
    sendingAnnounce = false;
    sendingToCis = false;
    requestingGuarantee = false;

    hotelOptions = [];
    cisArrangementOptions = [];
    cisArrangementList = [];
    cisCityOptions = [];

    insuranceData = [];

    //toolbarSticky: boolean = false;
    //toolbarPosition: any;

    @ViewChild('organizerForm') organizerForm: DynamicFormComponent;
    @ViewChild('arrangementForm') arrangementForm: DynamicFormComponent;
    @ViewChild('reservationConfirmationForm') reservationConfirmationForm: DynamicFormComponent;
    @ViewChild('addFeeForm') addFeeForm: DynamicFormComponent;
    //@ViewChild('stickyMenu') menuElement: ElementRef;

    constructor(
        private _snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private travelApplicationService: TravelApplicationService,
        private contactService: ContactService,
        private contractService: ContractService,
        private operatorService: OrganizerService,
        private domesticCityService: DomesticCityService,
        private transactionService: TransactionService,
        private formEventService: FormEventService,
        private titleService: Title,
        private responseMsgService: ResponseMessageService,
    ) {
    }

    ngOnInit() {
        GENDER_OPTIONS.forEach(g => {
            this.genderMap.set(g.id, g);
        });

        this.transactionService.getCurrencies().subscribe(currencies => {
            this.currencyList = currencies;
            currencies.forEach(currency => {
                this.currenciesMap.set(currency.id, currency);
            });
        });
        // this.clientsForm.valueChanges.subscribe(value => console.log(value));
        const paymentTypes = this.transactionService.getInOut().subscribe(inOut => {
            inOut.forEach(type => {
                this.inOrOutMap.set(Number(type.id), type);
            });
        });

        const payMethod = this.transactionService.getAccountTypes().subscribe(accountTypes => {
            accountTypes.forEach(accountType => {
                this.accountTypeMap.set(accountType.id, accountType);
            });
        });

        forkJoin(
            {
                organizers: this.operatorService.find({ limit: 20 }),
                domesticCities: this.domesticCityService.find({ limit: 20 })
            }
        ).subscribe(res => {
            if (res.organizers['data']) {
                this.organizerList = res.organizers['data'];
            }
            if (res.domesticCities['data']) {
                this.setDomesticCities(res.domesticCities['data']);
            }

            const obs1$ = this.reload;
            const obs2$ = this.route.params;

            const eventKeys = {
                obs1$,
                obs2$,
            };
            // Dummy eventsGuestsLookup observable.
            this.allSub.push(of(eventKeys)
                .pipe(mergeMap(ev => {
                    // We merge all observables in new one.
                    return merge(...Object.keys(ev).map(k => ev[k]));
                }))
                .pipe(
                    map(p => { return p['id'] }),
                    switchMap(id => {
                        this.viewMode = null;
                        if (id === 'new') {
                            return of(new Contract());
                        } else if (id.indexOf(ContractComponent.CLONE_KWD) !== -1) {
                            this.viewMode = ContractComponent.CLONE_KWD;
                            const cloneId = id.split('-');
                            const idClone = cloneId[1];
                            return this.fetchContractData(idClone + '?' + ContractComponent.CLONE_KWD + '=1');
                        } else if (id.indexOf('fromApplication') !== -1) {
                            this.viewMode = 'fromApplication';
                            const applicationIdParts = id.split('-');
                            const travelApplicationId = applicationIdParts[1];
                            this.reservationId = travelApplicationId;
                            return this.travelApplicationService.getForContract(this.reservationId);
                        } else {
                            this.id = id;
                            //load passenger transactions
                            this.loadTransactions();
                            //load for supplier transactions
                            this.loadTransactions(1);
                            return this.fetchContractData(id);
                        }
                    })
                )
                .subscribe(
                    contract => {
                        if (contract['success']) {
                            this.setContractData(contract['data']);
                        } else {
                            //@todo error
                            this.setContractData({});
                            //this.errors = 'Error loading';
                        }
                        this.dataLoaded = true;
                    },
                    err => {
                        this.showError(err.error.message);
                        this.dataLoaded = true; //@todo will it fail ßin case of error
                    }
                ));
        });

        this.allSub.push(
            this.selectedOperator
                .valueChanges
                .pipe(
                    debounceTime(300),
                    switchMap(value => this.operatorService.find({ name: value }))
                )
                .subscribe(results => this.organizerList = results['data'])
        );

        this.allSub.push(
            this.selectedClient
                .valueChanges
                .pipe(
                    debounceTime(300),
                    switchMap(value => this.getClients(value))
                )
                .subscribe(clients => this.clientSearchList = clients['data'])
        );

        this.allSub.push(this.formEventService.eventEmitter$.subscribe(event => {
            /*
            if (event.message == 'content-scrolled') {
                if (this.menuElement.nativeElement.getBoundingClientRect().y < 0) {
                    if (!this.toolbarSticky) {
                        this.toolbarSticky = true;
                    }
                }
            }
            */
            if (event.message == 'contract-arrangement-selected') {
                this.cisArrangementOptions = event.value.cisArrangements.map(item => { return { id: item.cis_id, name: item?.program?.program_name ?? item.cis_id } });
                this.cisArrangementList = event.value.cisArrangements;
                const cityName = event.value?.city?.name ? event.value.city.name : '';
                const countryName = event.value?.city?.country?.name ? event.value.city.country.name : '';
                //this.arrangementForm.setValue('id', cityName);
                this.arrangementForm.setValue('arrangement_id', event.value.id);
                this.arrangementForm.setValue('arrangement_city', cityName);
                this.arrangementForm.setValue('arrangement_country', countryName);
                //@todo maybe show mesage also no hotel found
                if (event?.value?.id) {
                    this.getHotels(event.value.id);
                }
                this.arrangementForm.setValue('cis_program_id', null);
                this.arrangementForm.setValue('cis_destination_id', null);
                
                if (typeof this.arrangementForm.form.controls['cis_program_id_search'] != 'undefined') {
                    this.arrangementForm.setValue('cis_program_id_search', null); // ??? should we do it here
                }

                if (this.cisArrangementOptions.length == 1) {
                    const cisProgramId = this.cisArrangementOptions[0]['id'];
                    this.arrangementForm.setValue('cis_program_id', cisProgramId);
                    const mappedArrangments = event.value.cisArrangements.find(arr => arr.program.program_id == cisProgramId);
                    const destinations = JSON.parse(mappedArrangments?.program?.destinations ?? '[]');
                    this.setCisDestinations(destinations);
                    
                    if (destinations.length == 1) {
                        this.arrangementForm.setValue('cis_destination_id', [destinations[0]['jedinstveniIdentifikator']]);
                    }
                }
                //arrangment selected
            }

            if (event.message == 'contract-hotel-selected') {
                const data = event.value;

                if (data.service !== undefined) {
                    this.arrangementForm.setValue('service', data.service);
                }
                if (data.room !== undefined) {
                    this.arrangementForm.setValue('room_name', data.room);
                }
                if (data.hotelName !== undefined) {
                    this.arrangementForm.setValue('arrangment_accomodation', { value: data.id, name: data.hotelName });
                }
                if (data.id !== undefined) {
                    this.arrangementForm.setValue('arrangment_accomodation_id', data.id);
                }
                if (data.from !== undefined) {
                    this.arrangementForm.setValue('date_from', data.from);
                }
                if (data.to !== undefined) {
                    this.arrangementForm.setValue('date_to', data.to);
                }
                if (data.check_in !== undefined) {
                    this.arrangementForm.setValue('check_in_date', data.check_in);
                }
                if (data.check_out !== undefined) {
                    this.arrangementForm.setValue('check_out_date', data.check_out);
                }
                if (data.days_number !== undefined) {
                    this.arrangementForm.setValue('days_number', data.days_number);
                }
                if (data.nights_number !== undefined) {
                    this.arrangementForm.setValue('nights_number', data.nights_number);
                }
            }

            if (event.message == 'lt_personal_id_clicked' || event.message == 'lt_personal_id_changed') {
                const customerBirthControl = event.el.group.controls.customer_birth || null;
                const personalIdControl = event.el.group.controls.personal_id;
                //if (!customerBirthControl?.value ) {
                const personalId = personalIdControl.value;
                if (personalId && personalId.length === 13) {
                    const date = getDateFromPersonalId(personalId);
                    customerBirthControl.setValue(date);
                }
                //}
            }
            //fiscal updated refresh the data
            if (event.message == 'lt-contract-fiscal-updated') {
                this.reloadContractData();
            }

            if (event.message == 'contract-service-selected') {
                //console.log(event);
            }
            if (event.message == 'cis-program-id-search-selected') {
                const destinations = JSON.parse(event?.value?.destinations ?? '[]');
                this.setCisDestinations(destinations);
                const formValue = this.arrangementForm.form.getRawValue();
                //if there is only one option , make it as selected
                if (destinations.length == 1) {
                    formValue.cis_destination_id = [destinations[0]['jedinstveniIdentifikator']];
                }
                this.setArrangementFormConfig(formValue);
            }

            if (event.message == 'cis-program-selected') {
                const programIdCis = event.value;
                const mappedArrangments = this.cisArrangementList.find(arr => arr.program.program_id == programIdCis);
                const destinations = JSON.parse(mappedArrangments?.program?.destinations ?? '[]');
                this.setCisDestinations(destinations);
                const formValueCisProgramSelected = this.arrangementForm.form.getRawValue();
                if (destinations.length == 1) {
                    formValueCisProgramSelected.cis_destination_id =  [destinations[0]['jedinstveniIdentifikator']];
                }
                this.setArrangementFormConfig(formValueCisProgramSelected);
            }
            if (event.message == 'new-departure-city-clicked') {
                const dialogRef = this.dialog.open(DomesticCityAssignComponent, {
                    width: '450px',
                });
        
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result.data) {
                        this.domesticCityService.find({}).subscribe({
                            next: (res: any) =>{
                                this.setDomesticCities(res.data);
                                this.arrangementForm.setValue('departure_city', {value: result.data.id, name: result.data.name});
                                this.setArrangementFormConfig(this.arrangementForm.form.getRawValue());
                            }
                        });
                    }
                    //this.animal = result;
                });
            }

            if(event.message === 'contractDateFromToChanged') {
                this.arrangementForm.setValue('check_in_date', event.value.value.date_from);
                this.arrangementConfig.find(el => el.name === 'check_in_date_from_to').minDate = event.value.value.date_from;
            }

            if (event.message == 'new-passenger-entry-city-clicked') {
                const clientFormIndex = event.eventAttributes.formIndex;
                const dialogRef = this.dialog.open(DomesticCityAssignComponent, {
                    width: '450px',
                });
        
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result.data) {
                        this.clients[clientFormIndex].entryCity = result.data;
                        this.domesticCityService.find({}).subscribe({
                            next: (res: any) =>{
                                this.setDomesticCities(res.data);
                                //this.setValue({value: result.data.id, name: result.data.name});
                                this.clientsFormAditional = new UntypedFormArray([]);
                                this.clientsAditionalFormConfig = [];
                                this.clientAditionalFormShowHide = [];
                                this.clients.forEach((client: ClientObj, i) => {
                                    this.setClientsAditionalFormConfig(client, i);
                                });
                            }
                        });
                    }
                });
            }
        }));
    }

    setCisDestinations(destinations) {
        const destinationOptions = destinations.map(el => {
            return {
                name: el.nazivMesta,
                id: el.jedinstveniIdentifikator,
            }
        });
        this.cisCityOptions = destinationOptions;
    }

    fetchContractData(id) {
        return this.contractService.findById(id, 'arrangements,customers,fees,bookOfContracts,insurance');
    }

    setDomesticCities(cities) {
        this.domesticCities = cities.map(city => {
            return {
                value: city.id,
                name: city.name,
            }
        });
    }
    setContractData(contractResponse) {
        if (this.viewMode === ContractComponent.CLONE_KWD) {
            this.title = 'Kloniranje ugovora #' + contractResponse['contract_number'];
        } else if (this.viewMode === 'fromApplication') {
            this.title = 'Kreiranje ugovora iz rezervacije #' + this.reservationId;
        } else if (this.id) {
            if (contractResponse['contract_number']) {
                this.incrementalNumber = contractResponse['contract_number'];
                this.title = 'Ugovor - #' + contractResponse['contract_number'];
            } else {
                this.title = 'Ugovor privremeni broj #' + this.id;
            }
            this.cisId = contractResponse['cis_contract_id'] ? Number(contractResponse['cis_contract_id']) : null;
        } else {
            this.title = 'Novi ugovor';
        }
        this.titleService.setTitle(this.title);
        if (this.viewMode == 'fromApplication') {
            const organizerId = contractResponse['organizer_id'] ?? null;
            const selectedOrganizer = this.organizerList.find(el => el.id == organizerId);
            if (selectedOrganizer) {
                this.selectedOperator.setValue(selectedOrganizer);
                this.providerChanged();
            }
        } else {
            this.setOrganizerFormConfig(contractResponse);
        }
        this.setFeeFormConfig({});
        this.setReservationConfirmationFormConfig(contractResponse);
        const arrangement = contractResponse?.arrangements && contractResponse.arrangements[0] ?
            contractResponse.arrangements[0] : {};
        if (arrangement.arrangement_id != undefined) {
            this.contractService.getHotels(arrangement.arrangement_id).subscribe(resp => {
                this.hotelOptions = resp.data;
                this.setArrangementFormConfig(this.arrangementForm.form.getRawValue(), 'settingArrangement');
            });
        }
        this.cisArrangementList = arrangement?.cisArrangements ? arrangement.cisArrangements : [];
        this.cisArrangementOptions = this.cisArrangementList.map(item => { return { id: item.cis_id, name: item?.program?.program_name ?? item.cis_id } });
        //if ther eis only one option select it
        if (this.cisArrangementOptions.length == 1 && !arrangement.cis_program_id) {
            arrangement.cis_program_id = this.cisArrangementOptions[0]['id'];
        }
        arrangement.cis_program_id_search = arrangement.cisProgram;
        const destinations =  JSON.parse(arrangement?.cisProgram?.destinations ?? '[]');
        this.setCisDestinations(destinations);
        if (arrangement.cis_destination_id && arrangement.cis_destination_id.length > 0) {
            arrangement.cis_destination_id = JSON.parse(arrangement.cis_destination_id);
        }
        if (destinations.length == 1 && !arrangement.cis_destination_id) {
            arrangement.cis_destination_id = [destinations[0]['jedinstveniIdentifikator']];
        }
        
        this.setArrangementFormConfig(arrangement, 'regularOne');

        this.contractHasFee = [];
        if (contractResponse['fees']) {
            contractResponse['fees'].forEach(fee => {
                this.addFee(fee);
            });
        }

        let feeTotalText = [];
        for (let totalCurrencyId in contractResponse['feeTotal']) {
            const oneCurrencyTotal = contractResponse['feeTotal'][totalCurrencyId];
            const totalCurrencyName = this.currenciesMap.get(Number(totalCurrencyId)).symbol;
            feeTotalText.push({
                value: oneCurrencyTotal,
                currencyName: totalCurrencyName
            });
        }
        this.feeTotal =
        {
            //comment: 'UKUPNO',
            total_formatted: feeTotalText,
        };

        this.contractsBookId = contractResponse['bookOfContracts']?.id ? contractResponse['bookOfContracts'].id : null;
        const customers = [];
        //clone does not have this
        if (contractResponse['customers']) {
            this.clients = [];
            this.clientsForm = new UntypedFormArray([]);
            this.clientsFormConfig = [];

            this.clientsFormAditional = new UntypedFormArray([]);
            this.clientsAditionalFormConfig = [];
            this.clientAditionalFormShowHide = [];
            contractResponse['customers'].forEach((client: ClientObj , i) => {
                this.clients.push(client);
                this.setClientsFormConfig(client);
                this.setClientsAditionalFormConfig(client, i);
                //@todomilos crate method for this and generaly adding clients
            });
        }
        //clone does not have this
        if (contractResponse['financeStatus']) {
            this.financeStatus = contractResponse['financeStatus'].map(oneCurrency => {
                const currencyName = this.currenciesMap.get(Number(oneCurrency.currency))?.name;
                return {
                    ...oneCurrency,
                    owe: formatMoney(oneCurrency.owe, currencyName),
                    paidTotal: formatMoney(oneCurrency.paidTotal, currencyName),
                    servicesTotal: formatMoney(oneCurrency.servicesTotal, currencyName),
                    status: Number(oneCurrency.owe) <= this.OWE_LIMIT[currencyName],
                }
            });
        }
        if (contractResponse?.totalPayedInByCurrency) {
            const totalCurr = contractResponse.totalPayedInByCurrency;
            this.payedInTotal = [];
            for (let currId in totalCurr) {
                this.payedInTotal.push(formatMoney(totalCurr[currId], this.currenciesMap.get(Number(currId))?.name))
            }
        }
        
        if (contractResponse?.totalPayedInMainCurrency) {
            this.totalPayedInMainCurrency = contractResponse.totalPayedInMainCurrency;
            this.totalPayedInMainCurrencyFormated = formatMoney(contractResponse.totalPayedInMainCurrency, this.mainCurrencyName);
        }

        if (contractResponse?.totalPayedInByAccount) {
            const totalByAccount = contractResponse.totalPayedInByAccount;

            this.transactionsTotalPerMoneyType = [];
            for (let accTypeId in totalByAccount) {
                let currenciesOrig = totalByAccount[accTypeId]['currencyTotals'];
                let currencies = currenciesOrig.map(el => {
                    return formatMoney(el.amount, this.currenciesMap.get(Number(el.currencyId))?.name);
                });
                this.transactionsTotalPerMoneyType.push({
                    type: this.accountTypeMap.get(Number(accTypeId))?.name ?? '',
                    currencies: currencies,
                    rawData: {type: accTypeId, amount: parseFloat(currenciesOrig[0].amount)}
                });
            }
        }

        if (contractResponse['financeStatusSupplier']) {
            this.financeStatusSupplier = contractResponse['financeStatusSupplier'].map(oneCurrency => {
                const currencyName = this.currenciesMap.get(Number(oneCurrency.currency))?.name;
                return {
                    ...oneCurrency,
                    currencyName: currencyName,
                    status: Number(oneCurrency.owe) <= this.OWE_LIMIT[currencyName],
                    servicesTotal: formatMoney(oneCurrency.servicesTotal, currencyName),
                    paidTotal: formatMoney(oneCurrency.paidTotal, currencyName),
                    owe: formatMoney(oneCurrency.owe, currencyName),
                }
            });
        }

        if (contractResponse['oweTotal']) {
            this.mainCurrencyOweTotal = {
                ...contractResponse['oweTotal'],
                originalAmount: contractResponse['oweTotal'].amount,
                amount: formatMoney(contractResponse['oweTotal'].amount, this.mainCurrencyName),
                paidIn: formatMoney(contractResponse['oweTotal'].paidIn, this.mainCurrencyName),
                servicesTotal: formatMoney(contractResponse['oweTotal'].servicesTotal, TransactionService.EUR),
                oweInEur: formatMoney(contractResponse['oweTotal'].oweInEur, TransactionService.EUR),
            };
        }

        if (contractResponse['oweTotalSupplier']) {
            this.supplierMainCurrencyOweTotal = contractResponse['oweTotalSupplier'];
        }
        const contractStatus = contractResponse['contract_status'] ?? '1';
        this.contractService.getStatuses().subscribe(statuses => {
            this.contractStatusMap.clear();
            if (contractStatus != 1) {
                statuses = statuses.filter(status => status.id != 1);
            }
            statuses.forEach(status => {
                this.contractStatusMap.set(status.id, status);
            });
            this.contractStatusMap = this.contractStatusMap;
        });
        this.contractStatus.setValue(contractStatus);

        //insurance
        if (contractResponse['insurance']) {
            this.insuranceData = contractResponse['insurance'].map(item => {

                let insFinance = contractResponse['insuranceFinance'][item.id]?.multiCurrencyOwe ?? [];
                insFinance = insFinance.map(oneCur => {
                    //usually no currency when there is no service/fee added to isnurance
                    const currCode = oneCur.currency ? this.currenciesMap.get(Number(oneCur.currency))?.name : TransactionService.MAIN_CURRENCY.name;

                    return {
                        ...oneCur,
                        paidTotalCurr: formatMoney(oneCur.paidTotal, currCode)
                    }
                });

                return {
                    ...item,
                    isCanceled: item.status == InsuranceStatus.CANCELED,
                    mainCurrencyOwe: contractResponse['insuranceFinance'][item.id]?.mainCurrencyOwe ?? {},
                    multiCurrencyOwe: insFinance
                }
            });
        }

        if (contractResponse['fiscals']) {
            this.fiscalReceipts = contractResponse['fiscals'];
        }

    }

    getClients(event) {
        let filter = new ContactFilter();
        filter.q = event;
        return this.contactService.find(filter);
    };

    addClient(client) {
        const tmpClient = client.value;
        const mappedClient: ClientObj = {
            customer_id: tmpClient.id,
            customer_address: tmpClient.address,
            customer_city: tmpClient.city,
            customer_postcode: tmpClient.postcode,
            customer_birth: tmpClient.birthday,
            customer_email: tmpClient.email,
            customer_name: tmpClient.name,
            customer_lastname: tmpClient.lastname,
            customer_passport_expires: tmpClient.passport_expiry,
            customer_passport_no: tmpClient.passport_number,
            personal_id: tmpClient.personal_id,
            customer_phone: tmpClient.phone,
            // nema na api
            id: null,
            contract_id: null,
            customer_city_id: 0,
            sex: tmpClient.sex,
            contract_holder: tmpClient.contract_holder || this.clients.length == 0 ? 1 : 0 //if it is first passenger set it as holder
        }
        this.clients.push(mappedClient);
        this.setClientsFormConfig(mappedClient);
        this.setClientsAditionalFormConfig(mappedClient, (this.clients.length - 1));
        this.selectedClient.setValue('');
    }

    deleteClient(index) {
        var confirmDialog = confirm('Stavka će biti bespovratno obrisana. Da li ste sigurni?');
        if (confirmDialog) {
            const client = this.getClientForm(index);
            if (!!this.id && client.value.id) {
                this.contractService.deleteCustomer(this.id, client?.value?.id).subscribe(
                    result => {
                        if (result['success'] == true) {
                            this.deleteClientFromList(index);
                        } else {
                            this.showError('Nepoznata greška', 'U redu');
                        }
                    },
                    err => {
                        if (err?.error?.message) {
                            this.showError(err.error.message, 'U redu');
                        } else {
                            this.showError('Nepoznata greška', 'U redu');
                        }
                    }
                );
            } else {
                this.deleteClientFromList(index);
            }
        }
    }

    deleteClientFromList(index) {
        this.clientsForm.removeAt(index);
        this.clients.splice(index, 1);
        this.clientsFormConfig.splice(index, 1);
        this.clientAditionalFormShowHide.splice(index, 1);
        this.clientsAditionalFormConfig.splice(index, 1);
        this.clientsFormAditional.removeAt(index);
    }

    getClientForm(index) {
        return this.clientsForm.at(index) as UntypedFormGroup;
    }
    getClientFormDetail(index) {
        return this.clientsFormAditional.at(index) as UntypedFormGroup;
    }

    displayClient(kupac: Contact) {
        if (kupac) {
            return kupac.name + ' ' + kupac.lastname;
        }
    }

    setClientsFormConfig(client: ClientObj | null = null) {
        const clientFormConfig: FieldConfig[] = [
            {
                type: 'checkbox',
                label: 'Nosilac ugovora',
                name: 'contract_holder',
                placeholder: 'Nosilac ugovora',
                value: Number(client?.contract_holder || '0'),
                col: 2
            },
            //should be disabled
            {
                type: 'input',
                label: 'UID Putnik',
                name: 'customer_id',
                placeholder: 'UID Putnik',
                validation: [Validators.required],
                value: client?.customer_id || null,
                disabled: true,
                col: 1
            },
            {
                type: 'input',
                label: 'Ime',
                name: 'customer_name',
                placeholder: 'Ime',
                validation: [Validators.required],
                value: client?.customer_name || '',
                col: 2
            },
            {
                type: 'input',
                label: 'Prezime',
                name: 'customer_lastname',
                placeholder: 'Prezime',
                validation: [Validators.required],
                value: client?.customer_lastname || '',
                col: 2
            },
            {
                type: 'input',
                label: 'Telefon',
                name: 'customer_phone',
                placeholder: 'Telefon',
                validation: [Validators.required],
                value: client?.customer_phone || null,
                col: 2
            },
            {
                type: 'input',
                label: 'Email',
                name: 'customer_email',
                placeholder: 'Email',
                validation: [Validators.required],
                value: client?.customer_email || null,
                col: 3
            },
            {
                type: 'select',
                label: 'Pol',
                name: 'sex',
                value: Number(client.sex) || null,
                placeholder: 'Pol',
                validation: [Validators.required],
                options: GENDER_OPTIONS,
                col: 1
            },
            {
                type: 'input',
                label: 'JMBG',
                name: 'personal_id',
                placeholder: 'JMBG',
                // validation: [Validators.required],
                value: client?.personal_id || null,
                col: 2,
                eventMessages: {
                    onClick: 'lt_personal_id_clicked',
                    onKeyup: 'lt_personal_id_changed',
                }
            },
            {
                type: 'datepicker',
                label: 'Datum rodjenja',
                name: 'customer_birth',
                placeholder: 'Datum rodjenja',
                validation: [Validators.required],
                value: client?.customer_birth || null,
                col: 2
            },
            {
                type: 'input',
                label: 'Broj pasoša',
                name: 'customer_passport_no',
                placeholder: 'Broj pasoša',
                // validation: [Validators.required],
                value: client?.customer_passport_no || null,
                col: 2
            },
            {
                type: 'input',
                label: 'Ulica',
                name: 'customer_address',
                placeholder: 'Ulica',
                validation: [Validators.required],
                value: client?.customer_address || null,
                col: 3
            },
            {
                type: 'input',
                label: 'Grad',
                name: 'customer_city',
                placeholder: 'Grad',
                validation: [Validators.required],
                value: client?.customer_city || null,
                col: 2
            },
            {
                type: 'hidden',
                name: 'id',
                value: client?.id || null,
            }
        ];
        this.clientsFormConfig.push(clientFormConfig);
        this.clientsForm.push(prepareFormGroup(clientFormConfig));
    }

    setClientsAditionalFormConfig(client, formIndex = null) {
        const areaName = client.entryCity?.area_name ?? '';
        const entryCityName = client.entryCity?.name ?? null;
        const fullEntryCityName = areaName && areaName != entryCityName?
            areaName + (entryCityName ? '('+entryCityName+')' : '') : entryCityName;

        const clientAditionalFormConfig: FieldConfig[] = [
            {
                type: 'autocomplete',
                label: 'Mesto ulaska',
                name: 'sett_entry_city',
                placeholder: 'Mesto ulaska',
                //validation: [Validators.required],
                searchStringKey: 'name',
                validation: [Validators.required],
                //value: { value: client.entryCity?.id ?? null, name: client.entryCity?.name ?? null },
                value: { value: client.entryCity?.id ? client.entryCity.id : (client.entryCity?.value ? client.entryCity.value : null), name: fullEntryCityName },
                eventMessages: {
                    onChange: 'contract-entry-city-selected',
                    addNewEvent: 'new-passenger-entry-city-clicked'
                },
                eventAttributes: {formIndex: formIndex},
                addNewOption: true,
                options: this.domesticCities,
                col: 3,
            },
            {
                type: 'input',
                label: 'Broj busa/aviona',
                name: 'sett_transport_no',
                placeholder: 'Broj busa/aviona',
                value: client?.sett_transport_no || '',
                col: 1
            },
            {
                type: 'input',
                label: 'Broj sedišta',
                name: 'sett_seat_no',
                placeholder: 'Broj sedišta',
                value: client?.sett_seat_no || null,
                col: 1
            },
            {
                type: 'input',
                label: 'Broj sobe',
                name: 'sett_room_no',
                placeholder: 'Broj sobe',
                value: client?.sett_room_no || null,
                col: 2
            },
            {
                type: 'select',
                label: 'Osiguranje',
                name: 'sett_insurance',
                placeholder: 'Osiguranje',
                value: client?.sett_insurance ? Number(client?.sett_insurance) : 0,
                col: 2,
                options: [
                    {
                        id: 1,
                        name: 'DA'
                    },
                    {
                        id: 0,
                        name: 'NE'
                    },
                ]
            },
            {
                type: 'input',
                label: 'Napomena',
                name: 'sett_notice',
                placeholder: 'Napomena',
                value: client?.sett_notice || null,
                col: 3
            },
        ];
        this.clientAditionalFormShowHide.push({ visible: true });
        this.clientsAditionalFormConfig.push(clientAditionalFormConfig);
        this.clientsFormAditional.push(prepareFormGroup(clientAditionalFormConfig));
    }

    setOrganizerFormConfig(values = {}, isOrganizerModel: boolean = false) {
        this.organizerConfig = [
            {
                type: 'hidden',
                name: 'organizer_id',
                value: isOrganizerModel ? values['id'] : values['organizer_id']
            },
            {
                type: 'input',
                label: 'Oranizator',
                name: 'organizer_agency',
                placeholder: 'Oranizator',
                validation: [Validators.required],
                value: isOrganizerModel ? values['name'] : values['organizer_agency'],
                col: 3
            },
            {
                type: 'input',
                label: 'Br. računa organizatora',
                name: 'organizer_account_no',
                placeholder: 'Br. računa organizatora',
                validation: [Validators.required],
                value: isOrganizerModel ? values['account_no'] : values['organizer_account_no'],
                col: 3
            },
            {
                type: 'input',
                label: 'Br. licence organizatora',
                name: 'organizer_license_no',
                placeholder: 'Br. licence organizatora',
                validation: [Validators.required],
                value: isOrganizerModel ? values['license_no'] : values['organizer_license_no'],
                col: 2
            },
            {
                type: 'input',
                label: 'Matični broj',
                name: 'organizer_jib',
                placeholder: 'Matični broj',
                validation: [Validators.required],
                value: isOrganizerModel ? values['jib'] : values['organizer_jib'],
                col: 2
            },
            {
                type: 'datepicker',
                label: 'Datum licence organizatora',
                name: 'organizer_license_date',
                placeholder: 'Datum licence organizatora',
                validation: [Validators.required],
                value: isOrganizerModel ? values['license_date'] : values['organizer_license_date'],
                col: 2
            },
            {
                type: 'input',
                label: 'Osoba za reklamacije',
                name: 'organizer_complaint_person',
                placeholder: 'Osoba za reklamacije',
                validation: [Validators.required],
                value: isOrganizerModel ? values['complaint_person'] : values['organizer_complaint_person'],
                col: 3
            },
            {
                type: 'input',
                label: 'Broj za reklamacije',
                name: 'organizer_complaint_tel',
                placeholder: 'Broj za reklamacije',
                validation: [Validators.required],
                value: isOrganizerModel ? values['complaint_tel'] : values['organizer_complaint_tel'],
                col: 2
            },
            {
                type: 'input',
                label: 'Kontakt organizatora',
                name: 'organizer_tel',
                placeholder: 'Kontakt organizatora',
                validation: [Validators.required],
                value: isOrganizerModel ? values['tel'] : values['organizer_tel'],
                col: 2
            },
            {
                type: 'input',
                label: 'Email organizatora',
                name: 'organizer_email',
                placeholder: 'Email organizatora',
                validation: [Validators.required],
                value: isOrganizerModel ? values['email'] : values['organizer_email'],
                col: 3
            },
            {
                type: 'input',
                label: 'Web stranica organizatora',
                name: 'organizer_url',
                placeholder: 'Web stranica organizatora',
                validation: [Validators.required],
                value: isOrganizerModel ? values['url'] : values['organizer_url'],
                col: 2
            }
        ];
    }

    setArrangementFormConfig(values, loc = '') {
        if (values.departureCity) {
            values.departure_city = values.departureCity;
        }
        const transportObj = this.transportationTypes.find(item => item['id'] == values['transport_type']);
        const transportVal = transportObj && transportObj['id'] ? transportObj['id'] : null;
        let accomodation = values['arrangment_accomodation'];
        let accomodationValue = {
            value: null,
            name: ''
        };
        if (typeof accomodation == 'object') {
            accomodationValue = {
                value: accomodation?.id ?? null,
                name: accomodation?.name ?? '',
            };
        } else {
            accomodationValue = {
                value: values['arrangment_accomodation_id'],
                name: values['arrangment_accomodation'],
            }
        }
        const areaName = values.departure_city?.area_name ?? '';
        const depCityName = values.departure_city?.name ?? null;
        const departureCityName = areaName && areaName != depCityName?
            areaName + (depCityName ? '('+depCityName+')' : '') : depCityName;
        this.arrangementConfig = [
            {
                type: 'hidden',
                label: 'Contract Has Arrangment ID',
                name: 'id',
                value: values['id'] ? values['id'] : null,
            },
            {
                type: 'hidden',
                label: 'Arrangment ID',
                name: 'arrangement_id',
                value: values['arrangement_id'] ? values['arrangement_id'] : null,
            },
            {
                type: 'hidden',
                label: 'Arrangment Accomodation ID',
                name: 'arrangment_accomodation_id',
                value: values['arrangment_accomodation_id'] ? values['arrangment_accomodation_id'] : null,
            },
            {
                type: 'autocomplete',
                label: 'Naziv aranžmana',
                name: 'arrangement_name',
                placeholder: 'Naziv aranžmana',
                validation: [Validators.required],
                searchStringKey: 'name',
                value: {
                    value: values['id'],
                    name: values['arrangement_name']?.name ?? values['arrangement_name']
                },//can be object or string
                api: {
                    hostUrl: environment.apiHost,
                    endpoint: '/lider/arrangment?status=1',
                    aditionalParams: {
                        organizer: () => {
                            return this.organizerForm.value?.organizer_id ?? '';
                        }
                    },
                },
                eventMessages: {
                    onChange: 'contract-arrangement-selected'
                },
                col: 3
            },
            {
                    type: 'select',
                    label: 'Aranžman CIS',
                    name: 'cis_program_id',
                    placeholder: 'Aranžman CIS',
                    value: values['cis_program_id'] ?? null,
                    options: this.cisArrangementOptions,
                    col: 2,
                    eventMessages: {
                        onChange: 'cis-program-selected'
                    },
            },
            {
                type: 'autocomplete',
                label: 'CIS aranžman pretraga',
                name: 'cis_program_id_search',
                placeholder: 'CIS aranžman pretraga',
                validation: [Validators.required],
                searchStringKey: 'name',
                value: {
                    value: values.cis_program_id_search?.value ?? null,
                    name: values.cis_program_id_search?.name ?? ''
                },
                api: {
                    hostUrl: environment.apiHost,
                    endpoint: '/lider/cis/cis-programs',
                    aditionalParams: {
                        organizerJib: () => {
                            return this.organizerForm.value?.organizer_jib ?? '';
                        }
                    }, 
                },
                
                eventMessages: {
                    onChange: 'cis-program-id-search-selected'
                },
                col: 3
            },
            {
                type: 'select',
                labels: 'Destinacija CIS',
                multiple: true,
                name: 'cis_destination_id',
                placeholder: 'Destinacija CIS',
                value: values['cis_destination_id'] ?? [],
                options: this.cisCityOptions,
                col: 2
            },
            {
                type: 'input',
                label: 'Zemlja',
                name: 'arrangement_country',
                placeholder: 'Zemlja',
                validation: [Validators.required],
                value: values['arrangement_country'],
                col: 2
            },
            {
                type: 'input',
                label: 'Mesto',
                name: 'arrangement_city',
                placeholder: 'Mesto',
                value: values['arrangement_city'],
                col: 2
            },
            /*
            {
                type: 'input',
                label: 'Smeštaj',
                name: 'arrangment_accomodation',
                placeholder: 'Smeštaj',
                value: values['arrangment_accomodation'],
                col: 2  
            },
            */
            {
                type: 'autocomplete',
                label: 'Smeštaj',
                name: 'arrangment_accomodation',
                placeholder: 'Smeštaj',
                validation: [Validators.required],
                value: accomodationValue,
                options: this.hotelOptions,
                eventMessages: {
                    onChange: 'contract-hotel-selected'
                },
                col: 2
            },
            {
                type: 'input',
                label: 'Tip sobe',
                name: 'room_name',
                placeholder: 'Tip sobe',
                value: values['room_name'],
                col: 2
            },
            {
                type: 'input',
                label: 'Usluga',
                name: 'service',
                placeholder: 'Usluga',
                validation: [Validators.required],
                value: values['service'],
                col: 1
            },
            {
                type: 'select',
                label: 'Usluga CIS',
                name: 'cis_service',
                placeholder: 'Usluga CIS',
                value: values['cis_service'],
                options: this.serviceTypesOptions,
                validation: [Validators.required],
                col: 2
            },
            {
                type: 'select',
                label: 'Prevoz',
                name: 'transport_type',
                options: this.transportationTypes,
                placeholder: 'Prevoz',
                validation: [Validators.required],
                value: transportVal,
                col: 2
            },
            {
                type: 'autocomplete',
                label: 'Mesto polaska',
                name: 'departure_city',
                placeholder: 'Mesto polaska',
                validation: [Validators.required],
                searchStringKey: 'name',
                addNewOption: true,
                value: { value: values?.departure_city?.id ? values?.departure_city?.id : (values?.departure_city?.value ? values.departure_city.value : null), name: departureCityName },
                eventMessages: {
                    onChange: 'contract-departure-cityselected',
                    addNewEvent: 'new-departure-city-clicked'
                },
                options: this.domesticCities,
                col: 2
            },
            {
                type: 'input',
                label: 'Broj dana',
                name: 'days_number',
                placeholder: 'Broj dana',
                value: values['days_number'],
                col: 1
            },
            {
                type: 'input',
                label: 'Broj noći',
                name: 'nights_number',
                placeholder: 'Broj noći',
                value: values['nights_number'],
                col: 1
            },
            {
                type: 'daterangepicker',
                label: 'Datum polaska - povratka',
                name: 'date_from_to',
                placeholder: 'Datum polaska - povratka',
                value: {
                    date_from: values['date_from'],
                    date_to: values['date_to'],
                },
                startName: 'date_from',
                endName: 'date_to',
                col: 2,
                eventMessages: {
                    onChange: 'contractDateFromToChanged'
                },
            },
           /* {
                type: 'datepicker',
                label: 'Datum do',
                name: 'date_to',
                placeholder: 'Datum do',
                value: values['date_to'],
                col: 2
            },
            */
            {
                type: 'daterangepicker',
                label: 'Datum prijave - odjave',
                name: 'check_in_date_from_to',
                placeholder: 'Datum prijave',
                value: {
                    check_in_date: values['check_in_date'],
                    check_out_date: values['check_out_date'],
                },
                minDate: values['date_from'],
                startName: 'check_in_date',
                endName: 'check_out_date',
                col: 2
            },
            
            {
                type: 'textarea',
                label: 'Napomena',
                name: 'notice',
                placeholder: 'Napomena',
                validation: [Validators.required],
                value: values['notice'],
                col: 4
            },
            {
                type: 'textarea',
                label: 'Interna napomena',
                name: 'notice_intern',
                placeholder: 'Interna napomena',
                value: values.notice_intern ?? null,
                col: 4
            },
            {
                type: 'textarea',
                label: 'Napomena organizatoru',
                name: 'notice_to_organizer',
                placeholder: 'Napomena organizatoru',
                value: values.notice_to_organizer ?? null,
                col: 4
            },
        ];
    }
    /*
     *  Whereever default value in db is null I put null as default, because of comparing on yii2 side
     *   It compares modelproperties by type ,whether attribute is changed, and on that coparation it decides
     *    to execute update query or not
     */
    setReservationConfirmationFormConfig(values = {}) {
        this.reservationConfirmationConfig = [
            {
                type: 'input',
                label: 'Br. potvrde organizatora',
                name: 'organizer_contract_no',
                placeholder: 'Br. potvrde organizatora',
                validation: [Validators.required],
                value: values['organizer_contract_no'] ? values['organizer_contract_no'] : '',
                col: 3
            },
            {
                type: 'input',
                label: 'Broj garancije',
                name: 'organizer_guaranty_number',
                placeholder: 'Broj garancije',
                validation: [Validators.required],
                value: values['organizer_guaranty_number'] ? values['organizer_guaranty_number'] : '',
                col: 3
            },
            {
                type: 'datepicker',
                label: 'Datum izd. garancije',
                name: 'organizer_guaranty_date',
                placeholder: 'Datum izd. garancije',
                validation: [Validators.required],
                value: values['organizer_guaranty_date'] ? values['organizer_guaranty_date'] : '',
                col: 3
            },
            {
                type: 'input',
                label: 'Broj fakture',
                name: 'organizer_guaranty_file',
                placeholder: 'Broj fakture', //iskoristeno polje koje nije trebalo biti 
                value: values['organizer_guaranty_file'] ? values['organizer_guaranty_file'] : '',
                col: 3
            },
            {
                type: 'input',
                label: 'Provizija €',
                name: 'seller_fee_eur',
                placeholder: 'Provizija €',
                validation: [Validators.required],
                value: values['seller_fee_eur'] ? values['seller_fee_eur'] : null,
                col: 3
            },

            {
                type: 'input',
                label: 'Provizija RSD',
                name: 'seller_fee_rsd',
                placeholder: 'Provizija RSD',
                validation: [Validators.required],
                value: values['seller_fee_rsd'] ? values['seller_fee_rsd'] : null,
                col: 3
            },

            {
                type: 'input',
                label: 'Poziv na broj(uplatnica)',
                name: 'payment_reference',
                placeholder: 'Poziv na broj(uplatnica)',
                validation: [Validators.required],
                value: values['payment_reference'] ? values['payment_reference'] : null,
                col: 3
            }
        ];
    }

    setFeeFormConfig(values: FeeFormConfig) {
        const currencies = [];
        for (let [key, value] of this.currenciesMap) {
            currencies.push({
                id: value.id,
                name: value.name,
            });
        }
        this.feeFormConfig = [
            /*
            {
              type: 'select',
              label: 'Tip usluge',
              name: 'type',
              options: this.feeTypes,
              placeholder: 'Odaberi tip usluge',
              col: 3
            },
            */
            {
                type: 'autocomplete',
                label: 'Naziv usluge',
                name: 'type',
                placeholder: 'Naziv usluge',
                validation: [
                    {
                        name: 'required',
                        validator: Validators.required,
                        message: 'Obavezno polje'
                    },
                    /*
                    {
                        name: 'invalidAutocomplete',
                        validator: autocompleteValidator(),
                        message: 'Odaberite jednu od ponuđenih opcija'
                    }*/
                ],
                searchStringKey: 'name',
                eventMessages: {
                    onChange: 'contract-service-selected'
                },
                options: this.feeTypes,
                value: values.type ?? null,
                col: 2
            },

            {
                type: 'input',
                label: 'Količina',
                name: 'quantity',
                placeholder: 'Količina',
                col: 1,
                value: values.quantity ?? null,
                validation: [
                    {
                        name: 'required',
                        validator: Validators.required,
                        message: 'Obavezno polje'
                    },
                ],
            },
            {
                type: 'input',
                label: 'Cena',
                name: 'price',
                placeholder: 'Cena',
                col: 1,
                value: values.price ?? null,
                validation: [
                    {
                        name: 'required',
                        validator: Validators.required,
                        message: 'Obavezno polje'
                    },
                ],
            },
            {
                type: 'select',
                label: 'Valuta',
                name: 'currency',
                options: currencies,
                placeholder: 'Valuta',
                col: 1,
                value: values.currency ?? 2,
                validation: [
                    {
                        name: 'required',
                        validator: Validators.required,
                        message: 'Obavezno polje'
                    },
                ],
            },
            {
                type: 'input',
                label: 'Popust',
                name: 'discount',
                value: values.discount ?? 0,
                placeholder: 'Popust',
                col: 1
            },
            {
                type: 'input',
                label: 'Cena dobavljača',
                name: 'supplier_price',
                placeholder: 'Cena dobavljača',
                value: values.supplier_price ?? '',
                col: 1,
            },
            {
                type: 'select',
                label: 'Valuta',
                name: 'supplier_currency',
                options: currencies,
                placeholder: 'Valuta dobavljača',
                col: 1,
                value: values.supplier_currency ?? 2,
                validation: [
                    {
                        name: 'required',
                        validator: Validators.required,
                        message: 'Obavezno polje'
                    },
                ],
            },
            {
                type: 'input',
                label: 'Komentar',
                name: 'comment',
                value: values.comment ?? null,
                placeholder: 'Komentar',
                col: 4
            },
            {
                type: 'hidden',
                name: 'id',
                value: values.id ?? null
            },
            {
                label: values.submit ?? 'Dodaj',
                name: 'submit',
                type: 'button',
                col: 2
            },
        ];
    }

    openSnackBar(message: string, action = null, options = {}) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['success-snackbar'],
            ...options
        });
    }

    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }

    getArrangementName() {
        const arrForm = this.arrangementForm?.value ?? null;
        return arrForm && arrForm?.arrangement_name?.name ? arrForm?.arrangement_name?.name : arrForm?.arrangement_name;
    }
    save(action = '') {
        this.isLoading = true;
        const arrForm = this.arrangementForm.value;
        const arrangementName = this.getArrangementName();
        const accomodationName = arrForm?.arrangment_accomodation?.name ? arrForm?.arrangment_accomodation?.name : arrForm?.arrangment_accomodation;
        const accomodationId = arrForm?.arrangment_accomodation?.value ? arrForm?.arrangment_accomodation.value : arrForm?.arrangment_accomodation_id;
        let customers = this.clientsForm.getRawValue();
        let customerAditionalDetails = this.clientsFormAditional.getRawValue();

        let customerError = '';
        customers = customers.map((customer, index) => {
            let entryCityId = customerAditionalDetails[index]?.sett_entry_city?.value ?? null;
            if (!entryCityId) {
                customerError += 'Putnik ' + customer.customer_lastname + ': Mesto ULASKA putnika je obavezno polje(Odaberite jednu od ponuđenih opcija)';
            }
            return {
                ...customer,
                ...customerAditionalDetails[index],
                sett_entry_city: entryCityId
            };
        });
        if (customerError) {
            this.showError(customerError);
            this.isLoading = false;
            return;
        }

        this.contractService.save({
            id: this.id,
            contractsBookId: this.contractsBookId,
            organizer: this.organizerForm.value,
            reservationId: this.reservationId,
            //arrangment name can bi object(from autocomplete or free text in input)
            arrangment: {
                ...arrForm,
                arrangement_name: arrangementName,
                arrangment_accomodation: accomodationName,
                arrangment_accomodation_id: accomodationId,
                arrangement_id: arrForm.arrangement_name?.id ? arrForm.arrangement_name?.id : arrForm.arrangement_id,
                departure_city: arrForm.departure_city?.value ? arrForm.departure_city?.value : null,
                cis_program_id: arrForm?.cis_program_id_search?.value ?? ( this.cisArrangementOptions.length && arrForm.cis_program_id ? arrForm.cis_program_id : null),
            },
            reservationConfirmation: this.reservationConfirmationForm.value,
            customers: customers,
            fees: this.contractHasFee,
            contractStatus: this.contractStatus.value,
            action: action
        }).subscribe(resp => {
            if (resp['success'] && resp['success'] === true) {
                const msg = action === this.FINALIZE_ACTION ? 'kreiran' : 'sačuvan';
                this.openSnackBar('Ugovor je uspešno ' + msg + '.', 'U redu', { panelClass: ['green-snackbar'] });
                
                this.openSnackBar('Ugovor je uspešno ' + msg + '.', 'U redu', { panelClass: ['green-snackbar'] });
                /*
                if (resp.meta?.warning && resp.meta.warning.length > 0) {
                    const warningMsg = resp.meta.warning.join("\n");
                    this.openSnackBar(warningMsg, 'U redu', { panelClass: ['orange-snackbar'] });
                }*/
                //when saving new contract then take from response
                if (!this.id) {
                    this.router.navigate(['/contract/' + resp['data']?.id]);
                } else {
                    this.reloadContractData();
                }
            } else {
                if (resp.message) {
                    this.showError(resp.message, 'U redu');
                } else {
                    this.showError('Desila se greška. Molimo pokušajte ponovo', 'U redu');
                }
            }
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
            this.showError(err?.error?.message);
        });
    }

    /*
     * It is different beacuse it assigns contract number to contract 
     * New row for current contract added in KEPTA 
     */
    /*
    finalizeContract() {
        this.save(this.FINALIZE_ACTION);
    }
    */

    providerChanged() {
        this.setOrganizerFormConfig(this.selectedOperator.value, true);

        //this.organizerForm.setValue('organizer_agency', 1);
        //this.selectedArrangment.setValue(null);
        //this.arrangmentChanged();
    }

    displayOperator(item: any) {
        if (item) {
            return item.name;
        }
    }

    addFee($event, fromEvent = null) {
        if (fromEvent === true && this.addFeeForm.form.invalid) {
            return false;
        }
        const quantity = $event.quantity;
        const price = parseFloat(Number($event.price).toFixed(2));
        const discount = parseFloat(Number($event.discount).toFixed(2));
        const total = (price * quantity - discount).toFixed(2);

        const supplierPrice = parseFloat(Number($event.supplier_price ?? 0).toFixed(2));
        const supplierCurrencyId = $event.supplier_currency;
        let supplierTotal = (supplierPrice * quantity).toFixed(2);
        let currency = this.currencyList.find(item => item.id == $event.currency);
        const currencyName = currency?.symbol || '';
        const supplierCurrency = this.currencyList.find(item => item.id == supplierCurrencyId)?.name ?? '';

        const feeTypeId = $event.type.value ?? (fromEvent ? 0 : $event.type);
        const feeName = $event.type.name ?? ($event.name ?? $event.type);
        let service = {
            id: $event.id,
            currency: $event.currency,
            type: feeTypeId,
            name: feeName,
            quantity: quantity,
            price: price,
            price_formatted: {
                currencyName: currencyName,
                value: price
            },
            discount: discount,
            discount_formatted: {
                currencyName: currencyName,
                value: discount
            },
            total: total,
            total_formatted: [{
                currencyName: currencyName,
                value: total
            }],
            supplier_currency: supplierCurrencyId,
            supplier_total: supplierTotal,
            supplier_total_formatted: {
                currencyName: supplierCurrency,
                value: supplierTotal
            },
            supplier_price: supplierPrice,
            supplier_price_formatted: {
                currencyName: supplierCurrency,
                value: supplierPrice
            },
            comment: $event.comment,
        };
        //this.services.push($event);
        //this.updateTotals($event);
        //this.data.push(service);
        //this.dataSource.next(this.data);
        //@todo think more performant solution

        if ($event.id && fromEvent) {
            //updating existing fee/service
            const index = this.contractHasFee.findIndex(fee => fee.id == $event.id);
            this.contractHasFee[index] = service;
        } else {
            //adding new one
            this.contractHasFee.push(service);
        }
        this.feeTableDataSource.next(this.contractHasFee);
        this.showAddFeeForm = false;
    }

    showHideFeeForm() {
        this.showAddFeeForm = !this.showAddFeeForm;
        this.setFeeFormConfig({});
    }

    onFeeTableEvent(event: TableEvent) {
        if (event.name == 'edit-fee') {
            this.editFee(event.data);
            document.getElementById("usluge-card").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }
        if (event.name == 'delete-fee') {
            //@todo use index instead of this method of check
            const index = this.contractHasFee.findIndex(row => JSON.stringify(row) == JSON.stringify(event.data));
            this.deleteFee(event.data, index);
        }
    }

    scrollToSection(sectionID) {
        const yOffset = -100; 
        const elementNavigateTo = document.getElementById(sectionID);
        const y = elementNavigateTo.offsetTop + yOffset;
        const sideContainer = document.getElementById('sidenavContent');
        sideContainer.scrollTop = y;
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    deleteFee(fee, index) {
        var confirmDialog = confirm('Stavka će biti bespovratno obrisana. Da li ste sigurni?');
        if (confirmDialog) {
            if (!!fee.id) {
                this.contractService.deleteFee(fee.id, this.id)
                    .subscribe(
                        result => {
                            if (result.success) {
                                this.deleteFeeFromList(index);
                                this.fetchContractData(this.id).subscribe(
                                    contract => {
                                        if (contract['success']) {
                                            this.setContractData(contract['data']);
                                        } else {
                                            alert('Greška pri učitavanju podataka. Molimo refrešujte stranicu');
                                        }
                                    },
                                    err => {
                                        this.showError(err.error.message);
                                    }
                                );
                            } else {
                                this.showError('Desila se greška, molimo pokušajte ponovo');
                            }
                        },
                        error => {
                            this.showError(error.error.message, 'U redu');
                        }
                    )

            } else {
                this.deleteFeeFromList(index);
            }
        }

    }

    editFee(element) {
        const feeType = Number(element.type);
        const selectedFeeType = this.feeTypes.find(type => type.value === feeType);
        let currentFee: FeeFormConfig = {};
        currentFee.id = element.id ?? null;
        if (selectedFeeType) {
            currentFee.type = selectedFeeType;
        } else {
            currentFee.type = {
                value: 0,
                name: element.name ?? '',
            };
        }
        currentFee.price = element.price;
        currentFee.quantity = element.quantity;
        currentFee.currency = Number(element.currency);
        currentFee.discount = element.discount;
        currentFee.supplier_price = element.supplier_price;
        currentFee.supplier_currency = Number(element.supplier_currency);
        currentFee.comment = element.comment;
        currentFee.submit = 'Sačuvaj';
        this.setFeeFormConfig(currentFee);
        this.showAddFeeForm = true;
    }

    deleteFeeFromList(index) {
        this.contractHasFee.splice(index, 1);
        this.feeTableDataSource.next(this.contractHasFee);
    }

    openDialog(purpose = null): void {
        let customers = [];
        this.clientsForm.controls.forEach(customer => {
            customers.push(customer.value['customer_name'] + ' ' + customer.value['customer_lastname']);
        });
        let commentParts = [];
        commentParts.push(customers.join(' , '));

        const arrForm = this.arrangementForm.form
        const arrName = arrForm.controls['arrangement_name'].value;
        const arrangmentName = arrName?.name ?? arrName;
        const dateFrom = formatDate(arrForm.controls['date_from'].value);
        const dateTo = formatDate(arrForm.controls['date_to'].value);
        commentParts = [...commentParts, arrangmentName, dateFrom + ' - ' + dateTo];

        let data = {
            isInDialog: true,
            entityId: this.id,
            referenceEntity: 1,
            reasonType: 1,
            suppliers: null,
            supplierId: null,
            account_type: null,
            in_out: null,
            comment: ''
        };
        data.comment = commentParts.join(' / ');

        if (purpose && purpose == 'supplier') {
            const supplierName = this.organizerForm.form.get('organizer_agency').value;
            const supplierId = this.organizerForm.form.get('organizer_id').value;
            data.suppliers = [
                {
                    id: supplierId,
                    name: supplierName,
                }
            ];
            data.account_type = this.transactionService.TEKUCI;
            data.in_out = this.transactionService.PAY_OUT;
            data.supplierId = supplierId;
        }
        const dialogRef = this.dialog.open(TransactionEditComponent, {
            width: 'auto',
            data: data,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.event === 'success') {
                this.reloadContractData();
            }
            //this.animal = result;
        });
    }

    loadTransactions(supplier = null) {
        this.transactionService.find({
            referenceId: this.id,
            referenceEntity: 1,
            withSupplier: supplier
        }).subscribe(transactions => {
            if (transactions['data'] && transactions['data']['data'].length) {
                if (supplier) {
                    this.supplierTransactionDataSource = this.processTransactionDataResponse(transactions['data']['data']);
                } else {
                    this.transactionDataSource = this.processTransactionDataResponse(transactions['data']['data']);
                    const totalByMoneyType = transactions['data']['totalByMoneyType'];//@todo delete
                    //this.transactionsTotalPerMoneyType = []
                    /*
                    for (let moneyTypeId in totalByMoneyType) {
                        this.transactionsTotalPerMoneyType.push({
                            amount: formatMoney(totalByMoneyType[moneyTypeId]['amount'], this.mainCurrencyName),
                            type: this.accountTypeMap.get(Number(moneyTypeId))?.name ?? ''
                        });
                    }
                    */
                }
            }
        });
    }

    announcementToOrganizerPDF() {
        if (!this.id) {
            alert('Nedostaje ID ugovora. Kontaktirajte podršku');
        }
        this.sendingAnnounce = true;
        const contractId = this.id;
        this.contractService.sendOrganizerAnnouncmentPDF(contractId).subscribe(
            resp => {
                if (resp.success && resp.data) {
                    //saveAs('data:application/pdf;base64,'+resp.data, 'test.pdf');
                    //return;
                    this.openSnackBar('Najava je uspešno poslata');
                }
            }, err => {

                if (err?.error?.message != undefined) {
                    this.showError(err?.error?.message, false);
                } else {
                    this.showError('Nepoznata greška');
                }
            }
        ).add(() => {
            this.sendingAnnounce = false;
        });
    }

    sendToCis() {
        if (!this.id) {
            alert('Nedostaje ID ugovora. Kontaktirajte podršku');
        }
        this.sendingToCis = true;
        const contractId = this.id;
        this.contractService.sendToCis(contractId).subscribe(
            resp => {
                if (resp.motherToken) {
                    localStorage.setItem('LtMotherToken', resp.motherToken);
                }

                if (resp.success && resp.data) {
                    this.openSnackBar('Uspešno dodato u CIS');
                }
            }, err => {

                if (err?.error?.message != undefined) {
                    this.showError(err?.error?.message, false);
                } else {
                    this.showError('Nepoznata greška');
                }
            }
        ).add(() => {
            this.sendingToCis = false;
        });
    }

    stornoCisContract() {
        const dialogRef = this.dialog.open(CisStornoDialogComponent, {
            width: '450px',
            data: {
              cisId: this.cisId
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.reloadContractData();
        });
    }

    sendContractToClient() {
        if (!this.id) {
            alert('Nedostaje ID ugovora. Kontaktirajte podršku');
        }
        const contractId = this.id;
        this.contractService.sendToClient(contractId).subscribe(
            resp => {
                if (resp.success && resp.data) {
                    this.openSnackBar('Uspešno poslat email');
                }
            }, err => {
                this.responseMsgService.showError(err?.error?.message);
            }
        ).add(() => {
            //this.sendingToCis = false;
        });
    }

    getTravelGuaranteeFromCis() {
        if (!this.id) {
            alert('Nedostaje ID ugovora. Kontaktirajte podršku');
        }
        this.requestingGuarantee = true;
        const contractId = this.id;
        this.contractService.getTravelGuaranteeFromCis(contractId).subscribe(
            resp => {
                if (resp.motherToken) {
                    localStorage.setItem('LtMotherToken', resp.motherToken);
                }
                const byteArray = new Uint8Array(atob(resp.data.document).split('').map(char => char.charCodeAt(0)));
                const blob = new Blob([byteArray], {type: 'application/pdf'});
                // Here is your URL you can use
                const url = window.URL.createObjectURL(blob);

                // i.e. display the PDF content via iframe
                window.open(url, '_blank');
            }, err => {
                this.responseMsgService.showError(err?.error?.message);
            }
        ).add(() => {
            this.requestingGuarantee = false;
        });
    }

    processTransactionDataResponse(data) {
        const transactions = data.map(transaction => {
            const inOrOut = this.inOrOutMap.get(Number(transaction.in_out));
            const accountType = this.accountTypeMap.get(Number(transaction.account_type));
            const currency = this.currenciesMap.get(Number(transaction.currency)).name;
            const repaidItem = transaction.repaid;
            let amountTextParts = [];
            const repaidLength = repaidItem.length;
            repaidItem.forEach((repaid, k) => {
                const currencyName = this.currenciesMap.get(Number(repaid.currency_id)).name;
                let oneCurrencyText = formatMoney(repaid.amount, currencyName);
                if (repaid.currency_id != TransactionService.MAIN_CURRENCY.id) {
                    oneCurrencyText += ' (' + repaid.currency_rate + ')';
                }
                amountTextParts.push(oneCurrencyText);
            });
            const amountText = amountTextParts.join(' + ');

            return {
                ...transaction,
                in_out: inOrOut && inOrOut.name ? inOrOut.name : '',
                account_type: accountType && accountType.name ? accountType.name : '',
                amount: formatMoney(transaction.amount, currency),
                amountInCurrencies: amountText,
                original_date: transaction.original_date ? formatDate(transaction.original_date) : '',
                dateTime: formatDatetime(transaction.timestamp),
            }
        });
        return transactions;
    }

    arrangmentChanged() {

    }

    getHotels(arrangementId) {
        this.contractService.getHotels(arrangementId).subscribe(resp => {
            if (resp.data) {
                if (resp.data.length == 0) {
                    this.showError('Za odabrani aranžman nije pronadjen ni jedan hotel');
                }
                this.hotelOptions = resp.data;
                this.setArrangementFormConfig(this.arrangementForm.form.getRawValue(), 'getHotels');
            } else {
                this.showError('Greška! Učitavanje liste hotela nije uspelo')
            }
        }, err => {
            this.showError(err.error.message);
        });
    }
    
    displayArrangment(item: Arrangment) {
        if (item) { return item.name; }
    }

    reloadContractData() {
        this.reload.emit({
            id: this.id
        });
    }

    ngOnDestroy(): void {
        for (const sub of this.allSub) {
            if (!sub.closed) {
                sub.unsubscribe();
            }
        }
    }
}

interface FeeFormConfig {
    id?;
    type?;
    quantity?;
    price?;
    currency?;
    discount?;
    supplier_price?;
    supplier_currency?;
    comment?;
    submit?;
}