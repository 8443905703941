import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';
import { FormEventService } from '../../form-event.service';

@Component({
  selector: 'form-select',
  styleUrls: ['form-select.component.scss'],
  template: `

  <mat-form-field [formGroup]="group" class="example-full-width">
  <mat-label>{{config.placeholder}}</mat-label>
  <mat-select (selectionChange)="onChange($event)" [(value)]="config.value" [(ngModel)]="config.value" [formControlName]="config.name" [multiple]="config.multiple"> 
      <mat-option *ngFor="let option of config.options" [value]="option.id"> {{ option.name }}</mat-option>
  </mat-select>
  <mat-hint *ngIf="config.hint">{{ config.hint }}</mat-hint>
  <ng-container *ngFor="let validation of config.validation" ngProjectAs="mat-error">
    <mat-error *ngIf="group.controls[config['name']].touched && group.controls[config['name']].hasError(validation.name)">{{validation.message}}</mat-error>
  </ng-container>
</mat-form-field>


  `
})
export class FormSelectComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
  constructor(private formEventService: FormEventService) {
    super();
  }

  onChange(event) {
    if (this.config.eventMessages?.onChange != undefined) {

      this.formEventService.eventEmitter$.next({
        message: this.config.eventMessages?.onChange ?? null,
        el: this,
        value: event.value
      });
    }
  }
}
