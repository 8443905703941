import { Contact } from './contact';
import { ContactFilter } from './contact-filter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from './../../environments/environment';
import {ResponseBody} from "../model/response-body";

@Injectable()
export class ContactService {
    
    constructor(private http: HttpClient) {
    }
    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };
    url =  environment.apiHost + '/lider/contact';
    contactList: Contact[] = [];

    findById(id: number | string): Observable<{
        success: boolean;
        data: Contact
    }> {
        const url = this.url;
        const headers = this.headers;
        return this.http.get<{
            success: boolean;
            data: Contact
        }>(url+ '/' + id, {headers});
    }
    
    load(filter: ContactFilter): void {
        this.find(filter).subscribe(
            result => {
                this.contactList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: ContactFilter): Observable<ResponseBody<Contact[]>> {
        let url = this.url;
        let headers = this.headers;
        let params = {
            "id": filter.id,
            "name": filter.name,
            "lastname": filter.lastname,
            "sex": filter.sex,
            "phome": filter.phome,
            "email": filter.email,
            "personal_id": filter.personal_id,
            "birthday": filter.birthday,
            "source": filter.source,
            "q": filter.q,
            currentPage: filter.currentPage,
            pageSize: filter.pageSize
        };

        return this.http.get<ResponseBody<Contact[]>>(url, {params, headers});
    }

    getImport() {
        let url = this.url;
        let headers = this.headers;
        let params = {};
        return this.http.get<Contact[]>(url, {params, headers});
    }

    save(entity: Contact): Observable<{
        success: boolean;
        data: Contact
    }> {
        const headers = this.headers;

        if (entity.id) {
            //update
            return this.http.put<{
                success: boolean;
                data: Contact
            }>(this.url + '/' + entity.id, entity, {headers});
        } else {
            //create
            return this.http.post<{
                success: boolean;
                data: Contact
            }>(this.url, entity, {headers});
        }
    }
}

