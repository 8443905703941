import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TravelApplicationService } from '../travel-application.service';
import { setFormValue } from 'src/app/shared/helpers/form';
import { autocompleteValidator } from 'src/app/components/dynamic-form-master/components/form-autocomplete/form-autocomplete.component';

interface TravelAppPriceDialogData {
  isInDialog?: boolean;
  parentId?: number | null;
  form: any;
  currencySelectOptions: Array<any>;
  productTypeOptions: Array<any>;

}

@Component({
  selector: 'ta-price-edit',
  templateUrl: './ta-price-edit.component.html',
  //styleUrls: ['./transaction-edit.component.css'] 
})
export class TaPriceEditComponent implements OnInit {

  entityForm = new UntypedFormGroup({});
  entity: any | null = null;
  entityId: string | null = null;
  parentId: string | null = null;
  dialogData: TravelAppPriceDialogData;

  suppliers = [];
  isLoading = false;
  formConfig = [];
  currencySelectOptions = [];
  productTypeOptions = [];
  
  constructor(
    private router: Router,
    private entityService: TravelApplicationService,
    private _snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<TaPriceEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TravelAppPriceDialogData,
  ) {
    this.dialogData = data;

    const entityId = this.dialogData.form.id ?? null;
    this.currencySelectOptions = this.dialogData.currencySelectOptions;
    this.productTypeOptions = this.dialogData.productTypeOptions;
  }

  ngOnInit(): void {
    setFormValue(this.entityForm, this.dialogData.form);
    this.setFormConfig(this.dialogData.form);
  }

  saveItem(event) {
    this.isLoading = true;
    if (this.entityForm.invalid) {
      this.entityForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }
    const payload = this.entityForm.getRawValue();
    if (!this.parentId) {
      this.dialogRef.close({ event: 'success', data: payload });
      return;
    } 
    //if there is parent id then save fee
    this.entityService.save(payload)
      .subscribe((response) => {
        if (response.success) {
          if (this.dialogData.isInDialog) {
            this.dialogRef.close({ event: 'success', data: response['data'] });
          } else {
            if (!this.entityId) {
              this.entityForm.reset();
              this.router.navigate(['/transaction-new']);
            } else {
              this._snackBar.open('Uspešno sačuvano', '', {
                duration: 3000,
                panelClass: ['green-snackbar']
              });
            }
          }
        } else {
          this.showError(response.message);
        }
        this.isLoading = false;
      },
        (err) => {
          this.showError(err.error.message);
          this.isLoading = false;
        }
      );
  }

  showError(message: string, action = null) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }

  setFormConfig(values: any) {
    this.formConfig = [
      {
        type: 'hidden',
        value: values.ltRowIndex ?? null,
        name: 'ltRowIndex'
      },
      {
        type: 'autocomplete',
        label: 'Naziv usluge',
        name: 'type',
        placeholder: 'Naziv usluge',
        validation: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Obavezno polje'
          },
        ],
        searchStringKey: 'name',
        eventMessages: {
          onChange: 'price-product-selected'
        },
        options: this.productTypeOptions,
        value: values.type ?? null,
        col: 2
      },

      {
        type: 'input',
        label: 'Količina',
        name: 'quantity',
        placeholder: 'Količina',
        col: 1,
        value: values.quantity ?? null,
        validation: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Obavezno polje'
          },
        ],
      },
      {
        type: 'input',
        label: 'Cena',
        name: 'price',
        placeholder: 'Cena',
        col: 1,
        value: values.price ?? null,
        validation: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Obavezno polje'
          },
        ],
      },
      {
        type: 'select',
        label: 'Valuta',
        name: 'currency',
        options: this.currencySelectOptions,
        placeholder: 'Valuta',
        col: 1,
        value: values.currency ?? 2,
        validation: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Obavezno polje'
          },
        ],
      },
      {
        type: 'input',
        label: 'Popust',
        name: 'discount',
        value: values.discount ?? 0,
        placeholder: 'Popust',
        col: 1
      },
      {
        type: 'input',
        label: 'Cena dobavljača',
        name: 'supplier_price',
        placeholder: 'Cena dobavljača',
        value: values.supplier_price ?? '',
        col: 1,
      },
      {
        type: 'select',
        label: 'Valuta',
        name: 'supplier_currency',
        options: this.currencySelectOptions,
        placeholder: 'Valuta dobavljača',
        col: 1,
        value: values.supplier_currency ?? 2,
        validation: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Obavezno polje'
          },
        ],
      },
      {
        type: 'input',
        label: 'Komentar',
        name: 'comment',
        value: values.comment ?? null,
        placeholder: 'Komentar',
        col: 4
      },
      {
        type: 'hidden',
        name: 'id',
        value: values.id ?? null
      },
      {
        label: this.entityId || values.ltRowIndex ? 'Sačuvaj' : 'Dodaj',
        name: 'submit',
        type: 'button',
        color:'primary',
        col: 2
      },
    ];
  }

}
