import { Routes } from '@angular/router';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { ContactUploadComponent } from './contact-upload.component';

export const CONTACT_ROUTES: Routes = [
  {
    path: '',
    component: ContactListComponent
  },
  {
    path: 'upload',
    component: ContactUploadComponent
  },
  {
    path: ':id',
    component: ContactEditComponent
  }
]
