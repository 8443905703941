import { Component, OnInit } from '@angular/core';
import {Organizer} from '../organizer';
import {UntypedFormGroup} from '@angular/forms';
import {OrganizerService} from '../organizer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {setFormValue} from '../../shared/helpers/form';

@Component({
  selector: 'app-organizer-edit',
  templateUrl: './organizer-edit.component.html',
  styleUrls: ['./organizer-edit.component.css']
})
export class OrganizerEditComponent implements OnInit {

  organizerForm = new UntypedFormGroup({});
  organizer: Organizer | null = null;
  organizerId: string | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private organizerService: OrganizerService
  ) {
    const organizerId = activatedRoute.snapshot.params.id;
    this.organizerId = organizerId !== 'add' ? organizerId : null;
  }

  ngOnInit(): void {
    if(this.organizerId) {
      this.organizerService.findById(this.organizerId)
        .subscribe((response)=> {
          if(response.success) {
            this.organizer = response.data;
            setFormValue(this.organizerForm, this.organizer);
          }
        })
    }
  }

  submitForm() {
    if(this.organizerForm.invalid) {
      this.organizerForm.markAllAsTouched();
      return;
    }

    const formValue = this.organizerForm.getRawValue();
    const payload = {
      ...formValue,
      is_active: formValue.is_active ? 1 : 0,
      id: this.organizerId
    }


    this.organizerService.save(payload)
      .subscribe((response) => {
        if(response.success) {
          this.organizerForm.reset();
          this.router.navigate(['/organizer/']);
        } else {
          console.error('Handle create errors');
        }
      })
  }

}
