<style>
    .add-new-option{
        height: 100px;
    }
    .add-new-option .holder {
        display: flex;
        flex-direction: column;
    }
    .not-found-txt{
        font-size: 14px;
        color: rgb(51, 51, 51);
        border-bottom: 1px solid rgb(228, 234, 238);
        text-align: center;
    }
    .not-found-add-btn, .not-found-val{
        color:#3f51b5;
    }
    .not-found-add-btn mat-icon{
        margin-right: 0px;
    }

</style>
<mat-form-field class="example-full-width" [ngClass]="{'edited': control.dirty}">
    <mat-label>{{config.label}}</mat-label>
    <input
           matInput
           [formControl]="control"
           [placeholder]="config.placeholder"
           [matAutocomplete]="auto"
           aria-label="State"
           (focus)="focus$.next($event.target.value)"
    >
    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelected($event)"
        panelWidth="auto"
    >
        <mat-option *ngIf="isLoading" class="is-loading">
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
        </mat-option>
        <ng-container *ngIf="!isLoading && !errorMessage">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
                {{option.name}} [{{option.value}}]
            </mat-option>
            
        </ng-container>
        
        <mat-option class="add-new-option" value="add-new-option" *ngIf="config?.addNewOption && filteredOptions.length == 0">
            <span class="holder">
                <span class="not-found-txt">
                    Nismo pronasli <span class="not-found-val">"{{control.value}}"</span>
                </span>
            <span (click)="addNewOption()" class="not-found-add-btn">
                <mat-icon>add_circle</mat-icon>
                Dodaj opciju "{{control.value}}"</span>
            </span>  
        </mat-option>
    </mat-autocomplete>
    <ng-container *ngFor="let validation of config.validation" ngProjectAs="mat-error">
        <mat-error *ngIf="!errorMessage && group.controls[config['name']].hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
    <mat-hint *ngIf="errorMessage && group.controls[config['name']].invalid" [ngStyle]="{'color': 'red'}">{{errorMessage}}</mat-hint>
</mat-form-field>