import { Component, Inject, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PRERESERVATION_OPTIONS } from "./inquiry-constants";
import { InquiryService } from "./inquiry.service";

@Component({
    selector: 'app-lt-inquiry-edit',
    template: `
        <style>
        .mat-list-item {
            border: 1px solid grey;
            border-radius: 5px;
            margin-bottom: 5px;
            height: 40px;
        }
        </style>
        <h2>Izmeni upit #{{id}}</h2>
        <dynamic-form *ngIf="formLoaded"
        [config]="config"
        (submit)="saveInquiry($event)">
        </dynamic-form>
    `
})
export class InquiryEditComponent implements OnInit {
    config = [];
    id = null;
    formLoaded = false;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private matDialogRef: MatDialogRef<InquiryEditComponent>,
        private service: InquiryService,
        private _snackBar: MatSnackBar,
     ) {
        this.id = data.id;
     }
    ngOnInit(): void {
        this.service.findById(this.id).subscribe( (res) => {
            this.setConfig(res.data);
            this.formLoaded = true;
        }, (err) => {

        });
    }

    setConfig(values) {
        if (values.pre_reservation) {
            values.pre_reservation = Number(values.pre_reservation);
        }
        const createdByUser = values.user_id; //disabled for edit if not added manualy
        this.config = [
            {
                type: 'input',
                label: 'Ime i prezime',
                name: 'name_lastname',
                placeholder: 'Ime i prezime',
                validation: [Validators.required],
                value: values.name_lastname,
                disabled: createdByUser < 1,
                col: 4
            },
            {
                type: 'input',
                label: 'Email',
                name: 'email',
                placeholder: 'Email',
                validation: [Validators.required],
                value: values.email,
                disabled: createdByUser < 1,
                col: 4
            },{
                type: 'input',
                label: 'Tel.',
                name: 'phone',
                placeholder: 'Tel.',
                value: values.phone,
                validation: [Validators.required],
                disabled: createdByUser < 1,
                col: 4
            },
            {
                type: 'select',
                label: 'Predrezervacija',
                name: 'pre_reservation',
                options: PRERESERVATION_OPTIONS,
                placeholder: 'Predrezervacija',
                value: values.pre_reservation ?? null ,
                col: 3
            },
            
            {
                type: 'textarea',   
                label: 'Napomena',
                name: 'note',
                placeholder: 'Napomena',
                validation: [Validators.required],
                value: values.note,
                col: 9
            },
            {
                label: 'Sačuvaj',
                name: 'submit',
                type: 'button',
                col: 12
            }
        ];
    }
    saveInquiry(data) {
        this.service.updateInquiry(this.id, data).subscribe( (res) => {
            if (res.data) {
                this.matDialogRef.close({loadData: true});
                this._snackBar.open('Unos uspešan', 'Sačuvano', {
                    duration: 3000,
                    panelClass: ['green-snackbar']
                });
            }
        }, (error) => {
            this.showError(error.error.message);
        });
    }

    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
      }

}