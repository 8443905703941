import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {prepareFormGroup} from '../helpers/form';

@Component({
  selector: 'app-lt-filter',
  templateUrl: './lt-filter.component.html',
  styleUrls: ['./lt-filter.component.css']
})
export class LtFilterComponent implements OnInit {

  @Input() filterForm = new UntypedFormGroup({});
  @Input() filterConfig = [];
  @Input() cols: 2 | 3 | 4 | 5 | 6 | null = null;

  @Output() filterChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.filterForm = prepareFormGroup(this.filterConfig, this.filterForm);
  }
  submitSearch() {
    if(this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      return;
    }
    const search = this.filterForm.getRawValue();
    this.filterChanged.emit(search);
  }

}
