import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';

@Component({
  selector: 'form-textarea',
  template: `
  <mat-form-field [formGroup]="group" class="example-full-width">
      <textarea matInput [formControlName]="config.name" placeholder="{{config.placeholder}}"></textarea>
  </mat-form-field>
  `
})
export class FormTextareaComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
