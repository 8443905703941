import { Component, OnInit, Input, ViewChild , AfterViewInit, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { ContactFilter } from '../contact-filter';
//import { ContactService } from '../contact.service';
//import { Contact } from '../contact';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ArrangmentHasService } from './arrangment-service';
import { ArrangmentHasServiceEditComponent } from './arrangment-service-edit.component';
import { MatPaginator } from '@angular/material/paginator';


@Component({
    selector: 'arrangment-service',
    templateUrl: 'arrangment-service.component.html',
    styles: ['lt-table{width:100%;}.align-right{text-align:right}']
})
export class ArrangmentHasServiceComponent implements AfterViewInit, OnInit {
    @Input() contractId: number;
    @Input() customers: Observable<any>;
    constructor(private cd: ChangeDetectorRef) { }

    currentCustomers = new Map();
    currencyMap = new Map();
    serviceTypesMap =  new Map();
    totals = new Map();
    discounts = {};
    displayedColumns: string[] = ['Naziv','Količina','Cena','Popust', 'Putnik', 'Komentar','Ukupno']; 
    data = [];
    dataSource = new BehaviorSubject([]);

    addService($event) {
        let total = $event.value * $event.times;
        let discount = $event.discount;
        let currency = this.currencyMap.get($event.currency);
        let customerName = '';
        if($event.customer) {
            let customer = this.currentCustomers.get($event.customer);
            customerName+= customer.name+ ' ' + customer.lastname;
        }

        let service = {
            currency: $event.currency,
            total: total,
            Br: this.data.length+1,
            Putnik: customerName,
            Naziv: this.serviceTypesMap.get($event.type), 
            Količina: $event.times, 
            Cena: $event.value.toString() + ' '+ currency , 
            priceInt: total , 
            itemNumber: $event.times , 
            currencyInt: $event.currency , 
            discountInt: Number(discount) , 
            customerId: $event.customer , 
            Popust: $event.discount.toString() + ' '+ currency , 
            Komentar: $event.comment, 
            Ukupno: total.toString() + ' '+currency , 
        };
        this.services.push($event);
        this.updateTotals($event);
        this.data.push(service);
        this.dataSource.next(this.data);
        this.showForm = false;

    }

    updateTotals(service) {
        let total = service.value * service.times;
        let discount = Number(service.discount);
        let currency = this.currencyMap.get(service.currency);
        
        if(this.totals.has(currency)) {
            let currentCurrency = this.totals.get(currency);
            currentCurrency.total+= total;
            currentCurrency.discount+= discount;
            this.totals.set(currency,currentCurrency);
        } else {
            this.totals.set(currency, {currency: currency, total: total, discount: discount});
        }
    }
    
    @ViewChild(ArrangmentHasServiceEditComponent) serviceChild: ArrangmentHasServiceEditComponent;

    ngAfterViewInit() {
      let data  = this.serviceChild.currencies;
      for(let currency of data) {
        this.currencyMap.set(currency.id,currency.name);
      }
      let types  = this.serviceChild.serviceTypes;
      for(let item of types) {
        this.serviceTypesMap.set(item.id,item.name);
      }
      
    }

    services: ArrangmentHasService[] = [];

    ngOnInit() {
        this.search();
    }

    
    ngOnChanges(changes: SimpleChanges) {
        this.customers.subscribe(data => {
            console.log('chaned in services');
            let customerIds= Array.from( data.keys() );
            var removeData = [];
            let newData = [];

            this.data.forEach(function(element,key) {
                let customerId = element.customerId;
                if(customerIds.indexOf(customerId) == -1) {
                    removeData.push({value: (-1) * element.priceInt, discount: (-1) * element.discountInt, times: element.itemNumber, currency: element.currencyInt});
                } else {
                    newData.push(element);
                }
            });
            
            this.data = newData;
            for( let item of removeData) {
                this.updateTotals(item);
            }
            if(changes['customers']) {
                let currentCustomers = this.currentCustomers;
                let newCustomers = [];
                console.log(changes['customers']);
                changes['customers'].currentValue.value.forEach(function(value, key) {
                console.log(currentCustomers);
                console.log(currentCustomers.has(value.id));

                    if(!currentCustomers.has(value.id)) {
                        currentCustomers.set(value.id, value);
                        newCustomers.push(value.id);
                    } 
                });
                console.log(newCustomers); 
                this.currentCustomers = currentCustomers;
                for(let newCustomer of newCustomers) {
                    this.addService({type: 1, customer: newCustomer, discount: 0, value: 120, times: 1, currency: 1});
                }
                this.dataSource.next(this.data);
            }
        
        });    
    }
      
    search(): void {
        //this.contactService.load(this.filter);
    }

    showForm: boolean = false;  
}

