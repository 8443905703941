import { Component, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {defaultFormat as _rollupMoment} from 'moment';
import {AbstractInputComponent} from '../abstract-input.component';
import {FormEventService} from '../../form-event.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Injectable()
class CustomMomentAdapter extends MomentDateAdapter{
  getFirstDayOfWeek() {
    return 1;
  }
}
@Component({
  selector: 'form-input',
  providers: [

    { provide: DateAdapter, useClass: CustomMomentAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    { provide: MAT_DATE_LOCALE, useValue: 'sr-RS' }
  ],
  // styleUrls: ['form-input.component.scss'],
  template: `
  <mat-form-field [formGroup]="group" class="example-full-width">
        <input matInput [min]="config.minDate" placeholder="{{config.placeholder}}" autocomplete="off" [matDatepicker]="dp"  (click)="dp.open()" [formControlName]="config.name"  (dateChange)="adaptDateFormat($event)">
        <mat-hint>Npr 21.01.2022</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
  </mat-form-field>
  `
})
export class FormDatepickerComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor(
      private formEventService: FormEventService
  ) {
    super();
  }

  adaptDateFormat(event) {
    const currentInput = this.group.get(this.config.name);
    currentInput.setValue(currentInput?.value?.format('YYYY-MM-DD') || '');
    this.emitDateUpdatedEvent(currentInput.value);
  }

  emitDateUpdatedEvent(data) {
    this.formEventService.eventEmitter$.next({
      message: 'lt-date-picker-updated',
      el: this,
      value: {
        key: this.config.name,
        value: data
      }
    });
  }
}
