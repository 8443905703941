import { Component } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GeneralDataService } from './shared/services/general-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Aurora';

  constructor(private router: Router, private general: GeneralDataService ) {
  }
  isMenuOpen = false;
  contentMargin = 240;

  task: string[] = [
    'Clearning out my closet', 'Take out trash bins', 'Wash car', 'Tank up the motorcycles', 'Go for flight training'
  ]

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart)
    ).subscribe((event) => {
      this.general.get();
    });
  }

  onToolbarMenuToggle() {
    console.log('On toolbar toggled', this.isMenuOpen);
    this.isMenuOpen = !this.isMenuOpen;

    if(!this.isMenuOpen) {
      this.contentMargin = 0;
    } else {
      this.contentMargin = 240;
    }
  }
  // sidenavEvents(str) {
  //   console.log(str);
  // }
}