import { Transaction } from './transaction';
import { TransactionFilter } from './transaction-filter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from './../../environments/environment';


@Injectable()
export class TransactionService {
    
    constructor(private http: HttpClient) {
    }
    headers = {
        'enctype': 'multipart/form-data'
    };
    url =  environment.apiHost + '/lider/transaction';

    transactionList: Transaction[] = [];
  
    findById(id: string): Observable<Transaction> {
        let url = this.url + '/' + id; 
        let params = {};
        let headers = this.headers;
        return this.http.get<Transaction>(url, {params, headers});
    }
    
    load(filter: TransactionFilter): void {
        this.find(filter).subscribe(
            result => {
                this.transactionList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: TransactionFilter): Observable<Transaction[]> {
        let url = this.url; 
        let headers = this.headers;
        let params = {
            "id": filter.id ? filter.id : '',
            "payment_period_id": filter.payment_period_id ? filter.payment_period_id : '',
            "reference": filter.reference ? filter.reference : '',
            /*"type": filter.type,
            "payment_method": filter.payment_method,
            "payment_subject": filter.payment_subject,
            "payment_period_id": filter.payment_period_id,
            "timestamp": filter.timestamp,*/
        };

        params['open'] = filter.open ? filter.open : '';


        return this.http.get<[]>(url, {params, headers});
    }

    save(entity: Transaction): Observable<Transaction> {
        let url = this.url; 
        let headers = this.headers;

        return this.http.post<Transaction>(url, entity, {headers});
    }
    confirmChecked(data): Observable<Transaction> {
        let url = this.url + '/checkeds'; 
        let headers = this.headers;

        return this.http.post<any>(url, data, {headers});
    }
}

