import { Component, OnInit } from '@angular/core';
import {Organizer} from '../organizer';
import {UntypedFormGroup} from '@angular/forms';
import {OrganizerService} from '../organizer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {setFormValue} from '../../shared/helpers/form';
import { MatDialog } from '@angular/material/dialog';
import { SearchCommonOrganizerComponent } from './search-common-organizer.component';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';

@Component({
  selector: 'app-organizer-edit',
  templateUrl: './organizer-edit.component.html',
  styleUrls: ['./organizer-edit.component.css']
})
export class OrganizerEditComponent implements OnInit {

  organizerForm = new UntypedFormGroup({});
  organizer: Organizer | null = null;
  organizerId: string | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private organizerService: OrganizerService,
    public dialog: MatDialog,
    public rspMsgService: ResponseMessageService,
  ) {
    const organizerId = activatedRoute.snapshot.params.id;
    this.organizerId = organizerId !== 'add' ? organizerId : null;
  }

  ngOnInit(): void {
    if(this.organizerId) {
      this.organizerService.findById(this.organizerId)
        .subscribe((response)=> {
          if(response.success) {
            this.organizer = response.data;
            this.organizerForm.reset();
            setFormValue(this.organizerForm, this.organizer);
          }
        })
    }
  }

  findOrganizerFromCommonSource() {
      const dialogRef = this.dialog.open(SearchCommonOrganizerComponent, {
          width: '500px',
          data: {},
      });

      dialogRef.afterClosed().subscribe(result => {
          if (result && result.jib) {
            setFormValue(this.organizerForm, {
              name: result.name,
              jib: result.jib,
              pib: result.pib,
              license_no: result.licence,
              license_date: result.licence_to,
              email: result.email,
              is_active: true,
              city: result.city,
              postcode: result.postcode,
              address: result.address,
              account_no: ' ',
              tel: result.tel?? null,
            });
          }
          //this.animal = result;
      });
  }

  submitForm() {
    if(this.organizerForm.invalid) {
      this.organizerForm.markAllAsTouched();
      this.rspMsgService.showError('Molimo popunite obavezna polja');
      for (const name in this.organizerForm.controls) {
        if (this.organizerForm.controls[name].invalid) {
        }
    }
      return;
    }

    const formValue = this.organizerForm.getRawValue();
    const payload = {
      ...formValue,
      is_active: formValue.is_active ? 1 : 0,
      id: this.organizerId
    }


    this.organizerService.save(payload)
      .subscribe((response) => {
        if(response.success) {
          this.organizerForm.reset();
          this.router.navigate(['/organizer/']);
        } else {
          console.error('Handle create errors');
        }
      }, (err: any) => {
        this.rspMsgService.showError(err?.error?.message)
    })
  }

}
