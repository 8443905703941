import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class PeriodsService {
    
    constructor(private http: HttpClient) {
    }
    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };
    url =  environment.apiHost + '/lider/periods';
  
    getCurrent(): Observable<any> {
        let url = this.url + '/get-current'; 
        let params = {  };
        let headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    openNewPeriod() {
        let headers = this.headers;
        return this.http.post<any>(this.url, null, {headers});
    }

    closeCurrentPeriod() {
        let headers = this.headers;
        let url = this.url + '/close-current';
        return this.http.post<any>(url, null, {headers});
    }

    getTransactions(period) {
        let url = this.url + '/transactions'; 
        let params = {period};
        let headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    getPeriods() {
        let params = {};
        let headers = this.headers;
        return this.http.get<any>(this.url + '/all', {params, headers});
    }
}

