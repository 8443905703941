<mat-toolbar>
  <mat-toolbar-row>
    <span><button mat-mini-fab color="purple"><mat-icon>shopping_cart</mat-icon></button> Usluge</span>
    <span class="example-spacer"></span>
    <mat-chip-list aria-label="Dodaj uslugu">
      <mat-chip *ngIf="!showForm" (click)="showForm = !showForm" color="purple"><mat-icon>add</mat-icon> Dodaj uslugu</mat-chip>
      <mat-chip *ngIf="showForm" (click)="showForm = !showForm" color="purple"><mat-icon>close</mat-icon> Zatvori</mat-chip> 
    </mat-chip-list>
  </mat-toolbar-row>
</mat-toolbar>

<arrangment-service-edit [customers]="customers" [showForm] = "showForm" (serviceAdded)="addService($event)" ></arrangment-service-edit>
 
<table mat-table [dataSource]="dataSource" >
    <ng-container *ngFor="let item of displayedColumns" matColumnDef="{{item}}">
      <th mat-header-cell *matHeaderCellDef> {{item}} </th>
      <td mat-cell *matCellDef="let element"> {{element[item]}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <p class="align-right">Subtotal</p>
  <div class="align-right" *ngFor="let item of totals | keyvalue"><span *ngIf="item.value.total > 0" >{{item.value.total}} {{item.value.currency}}</span><br><span *ngIf="item.value.discount > 0" > -{{item.value.discount}} {{item.value.currency}}<br></span></div>
  <mat-divider></mat-divider>
  <br>

  <h1 style="display:inline-block;">Total</h1>
  <h1 style="float:right;display:inline-block;">
      <div class="align-right" *ngFor="let item of totals | keyvalue"><span *ngIf="(item.value.total - item.value.discount) > 0" >{{item.value.total - item.value.discount}} {{item.value.currency}}</span><br></div>
  </h1>