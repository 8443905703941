/* tslint:disable:prefer-const */
import {Contract} from './contract';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ResponseBody} from "../model/response-body";
import { ContractSearchParams } from './contract.component';
import {BookOfContracts} from "./book-of-contracts/book-of-contracts";
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ContractService {
    readonly CANCELLED_CONTRACT = 4;
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem(environment.tokenName),
        Ltmothertoken: ''
    };
    url = environment.apiHost + '/lider/contract';

    contractList: Contract[] = [];

    findById(id: string, expand: string = ''): Observable<Contract> {
        const url = this.url + '/' + id;
        const params = {};
        if (expand) {
            params['expand'] = expand;
        }
        const headers = this.headers;
        return this.http.get<Contract>(url, {params, headers});
    }

    load(filterVal: ContractSearchParams): void {
        this.find(filterVal).subscribe(
            result => {
                this.contractList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: ContractSearchParams): Observable<Contract[]> {
        let url = this.url;
        let headers = this.headers;
        let params = {
            ...filter
            //expand: filter.expand
        };
        return this.http.get<[]>(url, {params, headers});
    }

    getClientListExport(filter: ContractSearchParams): Observable<Contract[]> {
        let url = this.url + '/passenger-list-export';
        let headers = this.headers;
        let params = {
            ...filter
        };
        return this.http.get<[]>(url, {params, headers});
    }
    //from passenger page
    getPassengerListExport(filter: any): Observable<any> {
        let url = this.url + '/from-passenger-list-export';
        let headers = this.headers;
        let params = {
            ...filter
        };
        return this.http.get<[]>(url, {params, headers});
    }

    deleteCustomer(contractId: number, clientId: number): Observable<any[]> {
        let url = this.url + '/delete-customer';
        let headers = this.headers;
        let params = {
            contactId: clientId,
            contractId: contractId,
        };
        return this.http.post<any>(url, params, {headers});
    }

    deleteFee(feeId, contractId: number): Observable<ResponseBody<any>> {
        let url = this.url + '/delete-fee';
        let headers = this.headers;
        let params = {
            feeId: feeId,
            contractId: contractId,
        };
        return this.http.post<ResponseBody<any>>(url, params, {headers});
    }

    /*
    save(entity: Contract): Observable<Contract> {
        let url = this.url;
        let headers = this.headers;

        if(entity.id) {
            url += '/' + entity.id;
        }
        console.log(entity);
        if(entity.id) {
            return this.http.put<Contract>(url, entity, {headers});
        } else {
            return this.http.post<Contract>(url, entity, {headers});
        }
    }
    */
    save(entity): Observable<any> {
        let url = this.url;
        let headers = this.headers;

        if (entity.id) {
            url += '/' + entity.id;
        }

        if (entity.id) {
            return this.http.put<Contract>(url, entity, {headers});
        } else {
            return this.http.post<Contract>(url, entity, {headers});
        }
    }

    getContractsBook(filter?: any): Observable<ResponseBody<BookOfContracts>> {
        let url =  environment.apiHost + '/lider/contracts-book?expand=contract.fiscals';
        let headers = this.headers;
        let params = {...filter};
        return this.http.get<ResponseBody<BookOfContracts>>(url, {params, headers});
    }

    getPassengerList(filter?: any): Observable<ResponseBody<any>> {
        let url =  this.url + '/passenger-list';
        let headers = this.headers;
        let params = {...filter, joinPassengers: '1'};
        return this.http.get<ResponseBody<BookOfContracts>>(url, {params, headers});
    }

    getHotels(id): Observable<ResponseBody<any>> {
        let url =  environment.apiHost + '/lider/hotel/search';
        let headers = this.headers;
        let params = {id};
        return this.http.get<ResponseBody<any>>(url, {params, headers});
    }

    updateFiscalReceipt(entity): Observable<unknown> {
        let url =  environment.apiHost + '/lider/fiscal-receipt/' + entity.id;
        let headers = this.headers;
        return this.http.put<unknown>(url, entity, {headers});
    }
    
    addFiscalReceipt(entity): Observable<unknown> {
        let url =  environment.apiHost + '/lider/fiscal-receipt' ;
        let headers = this.headers;
        return this.http.post<unknown>(url, entity, {headers});
    }

    getContractPrintHtml(id): Observable<ResponseBody<string>> {
        let url =  environment.apiHost + '/lider/contract/print-contract?id=' + id;
        let headers = this.headers;
        let params = {};
        return this.http.get<ResponseBody<string>>(url, {params, headers});
    }

    sendOrganizerAnnouncment(contractId) {
        let params = {};
        const headers = this.headers;
        let url =  environment.apiHost + '/lider/contract/announcement-pdf?id=' + contractId;
        return this.http.get<ResponseBody<any>>(url, {params, headers});
    }

    sendToCis(contractId) {
        let params = {
            id: contractId
        };
        const headers = this.headers;
        headers.Ltmothertoken = localStorage.getItem('LtMotherToken') || '';
        let url =  environment.apiHost + '/lider/cis/create-contract';
        return this.http.post<any>(url, params, {headers});
    }

    stornoCisContract(cisId, reasonId) {
        let params = {
            cisId: cisId,
            reason: reasonId
        };
        const headers = this.headers;
        headers.Ltmothertoken = localStorage.getItem('LtMotherToken') || '';
        let url =  environment.apiHost + '/lider/cis/storno-contract';
        return this.http.post<any>(url, params, {headers});
    }

    saveCisPaymentData(entity) {
        const headers = this.headers;
        headers.Ltmothertoken = localStorage.getItem('LtMotherToken') || '';
        let url =  environment.apiHost + '/lider/cis/save-payment-data';
        return this.http.post<any>(url, entity, {headers});
    }

    getTravelGuaranteeFromCis(contractId) {
        let params = {
            id: contractId
        };
        const headers = this.headers;
        headers.Ltmothertoken = localStorage.getItem('LtMotherToken') || '';
        let url =  environment.apiHost + '/lider/cis/get-guarantee';
        return this.http.post<any>(url, params, {headers});
    }

    sendToClient(contractId: number) {
        let params = {};
        const headers = this.headers;
        let url =  environment.apiHost + '/lider/contract/send-to-client/' + contractId;
        return this.http.get<ResponseBody<any>>(url, {params, headers});
    }

    getStatuses(){
        return of([
          {
              id: 1,
              name: 'Nepotvrđen',
              icon: 'schedule',
              class: 'mat-chip-orange'
          },
          {
              id: 2,
              name: 'Potvrđen',
              icon: 'check',
              class: 'mat-chip-blue'
          },
          {
              id: 3,
              name: 'Rešen',
              icon: 'done_all',
              //icon: 'check_circle',
              class: 'mat-chip-green'
          },
          {
              id: this.CANCELLED_CONTRACT,
              name: 'Storniran',
              icon: 'cancel',
              class: 'mat-chip-red'
          },
          {
            id: 5,
            name: 'Promena',
            icon: 'sync_alt',
            class: 'mat-chip-purple'
        }
        ]);
      }
}

