import { Component, OnInit } from '@angular/core';
import {environment} from './../../environments/environment';
import {HttpHeaders} from '@angular/common/http';


@Component({
  selector: 'app-mass-upload',
  templateUrl: './mass-upload.component.html',
  styleUrls: ['./mass-upload.component.css']
})
export class MassUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  afuConfig = {
    theme: "dragNDrop",
    multiple: true,

    formatsAllowed: ".jpg,.png",
    maxSize: "5",
    uploadAPI:  {
      url:environment.apiHost + '/product/mass-upload',
      headers: {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
      }
    },
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Upload',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
  };

}
