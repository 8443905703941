<div class="form-holder-login">
    <!--<img src="/assets/img/lider-logo.png" alt="">-->
    <h2>Aurora Login</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="example-full-width">
            <input matInput type="text"  placeholder="Korisničko ime"  formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" >
            <mat-error *ngIf="submitted && f.username.errors">
                Molimo unesite ispravno korisničko ime
                </mat-error>
        </mat-form-field>
    
        <mat-form-field class="example-full-width">
            <input matInput type="password"  placeholder="Lozinka"  formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" >
            <mat-error *ngIf="submitted && f.password.errors">
                Molimo unesite ispravnu lozinku
                </mat-error>
        </mat-form-field>
        <div class="form-group">
            <button [disabled]="loading" class="login-btn" mat-raised-button >Uloguj se</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
    </form>  
</div>       

