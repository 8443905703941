<mat-toolbar class="page-toolbar">
  <mat-form-field >
    <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="filter.q" placeholder="Pretraga">
  </mat-form-field>
  <span class="toolbar-spacer"></span>
    <a [routerLink]="['/contact/add']">
        <button mat-raised-button class="add-new-btn" color="primary"><mat-icon >add</mat-icon>Kreiraj</button>
    </a>
    <a [routerLink]="['/contact/upload']">
    <button mat-raised-button class="add-new-btn" color="warn"><mat-icon >add</mat-icon>Importuj</button>  
  </a> 
</mat-toolbar>

<div *ngIf="contactList.length > 0" class="cardList" > 
    <mat-list>
        <mat-list-item *ngFor="let item of contactList">
          <mat-icon [ngStyle]="{'color':  item.sex == 1 ? '#2196f3' : '#ff4081'}" mat-list-icon>perm_identity</mat-icon>
          <p class="list-line-title" mat-line>
            <b>{{item.name + ' ' + item.lastname | uppercase}}</b> JMBG {{item.personal_id}}
          </p>            
          <p mat-line style="font-family:serif">Tel: {{item.phone}} Email: {{item.email}}</p>
          <h3 mat-subheader>
              {{item.source  | uppercase }}
          </h3>
            <a [routerLink]="['/contact/', item.id]" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </a>
        <mat-divider></mat-divider>

        </mat-list-item>
      </mat-list>
</div>
<mat-paginator [length]="resultsLength" [pageSizeOptions]="[20, 30, 50, 100]"></mat-paginator>


  
