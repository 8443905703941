import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormEventService } from '../components/dynamic-form-master/form-event.service';
import { FieldConfig } from '../components/dynamic-form-master/models/field-config.interface';
import { TableOptions } from '../contract/contract.component';
import { OrganizerService } from '../organizer/organizer.service';
import { formatDatetime } from '../shared/helpers/date';
import { LtFilterComponent } from '../shared/lt-filter/lt-filter.component';
import { LtTableComponent } from '../shared/lt-table/lt-table.component';
import { INQUIRY_STATUSES, PRERESERVATION_OPTIONS } from './inquiry-constants';
import { InquiryEditComponent } from './inquiry-edit.component';
import { InquiryStatusChangelogComponent } from './inquiry-status-changelog.component';
import { InquiryService } from './inquiry.service';
import { NewInquiryComponent } from './new-inquiry.component';
import { Title } from '@angular/platform-browser';
import { LtPaginationComponent } from '../shared/pagination/lt-pagination.component';

@Component({
  selector: 'app-lt-inquiry',
  templateUrl: './inquiry-list.component.html',
})
export class InquiryListComponent implements OnInit {
  pageTitle = 'Upiti';
  isSearching: boolean = true;
  allSub: Subscription[] = [];
  totalRowsCount = 0;
  dataSource = [];
  inquiryPageSizeDefault = 100;
  inquiryPageSize = this.inquiryPageSizeDefault;
  tableOptions: TableOptions = {
    hasHideShowCol: true
  };

  columns: Array<any> = [
    {
      name: 'createApplication',
      label: 'Prijava',
      type: 'url',
      url: { 
        route: '/travel-application/new'
      },
      icon: 'add'
    },
    /*{ 
      name: 'actions',
      label: '',
      actions : [
      {
          name: 'Arhiviraj',
          type: 'function_call',
          callback : (row) => {
              this.archiveInquiry(row)
          },
          'icon': 'inventory'
      }]
    },
    */
    {
      name: 'idInquiry', label: 'Br.', type: 'url', sort: true,
      url: {
        route: '/inquiry/'
      }
    },
    {
      name: 'status', label: 'Valid',
      className: 'lt-editable',
      type: 'dynamicComponent'
    },
    {
      name: 'status_id', label: 'Status upita',
      //className: 'lt-editable',
      type: 'dynamicComponent'
    },
    { name: 'communication_channel', type: 'dynamicComponent', className: 'lt-editable', label: 'Komunik.' },
    {
      name: 'button', label: 'Ist.', type: 'button', actions: [
        {
          type: 'event',
          actionName: 'lt-inquiry-status-history',
          icon: 'history',
          name: 'Pregled promena statusa(Istorija)',
        }]
    },
    { name: 'timestamp', label: 'Dat./Vr. upita', type: 'list' },
    { name: 'type', label: 'Tip', type: 'list' },
    { name: 'organizerName', label: 'Org.' },
    { name: 'referer', label: 'Poslato sa', type: 'url', className: 'referer' },
    { name: 'dateFromTo', label: 'Termin' },
    { name: 'adults', label: 'Odr.' },
    { name: 'children', label: 'D.' },
    { name: 'accomodation', label: 'Smestaj', type: 'list' },
    { name: 'user_message', label: 'Poruka', className: 'user-message' },
    { name: 'note', label: 'Napom.', className: 'note' },
    { name: 'pre_reservation', label: 'Predrez.', className: 'pre_reservation' },
    { name: 'name_lastname', label: 'Ime i Prezime' },
    { name: 'phone', label: 'Tel.' },
    { name: 'email', label: 'Email' },
    {
      name: 'edit',
      label: 'Akcije',
      type: 'button',
      actions: [
        {
          type: 'event',
          actionName: 'lt-edit-inquiry',
          name: 'Izmeni upit',
          icon: 'edit'
        }
      ]
    }
    //{ name: 'data', label: 'Podaci' },
  ];

  readonly COMMUNICATION_CHANNEL = [
    {
      id: '0',
      name: '---',
    },
    {
      id: '10',
      name: 'SMS',
    },
    {
      id: '20',
      name: 'Viber',
    },
    {
      id: '30',
      name: 'whap',
    },
    {
      id: '40',
      name: 'poziv',
    },
    {
      id: '50',
      name: 'email',
    }
  ];

  readonly STATUSES = [
    {
      id: '50',
      name: 'TEST',
      short: 'TEST'
    },
    {
      id: '40',
      name: 'N(posle upita)',
      short: 'NMU'
    },
    {
      id: '30',
      name: 'N(pre upita)',
      short: 'NMO'
    },
    {
      id: '20',
      name: 'Duplikat',
      short: 'DP'
    },
    {
      id: '10',
      name: 'Validan',
      short: 'OK'
    },
    {
      id: '1',
      name: 'Nevalidan',
      short: 'NOT OK'
    }
  ];

  private INQUIRY_STATUSES = [];

  filterConfig: FieldConfig[] = [];
  defaultValues = {
    'status': ['10', '30', '40'],
    'status_id': ['10']
  };
  organizersList = [];

  @ViewChild(LtFilterComponent) filter: LtFilterComponent;
  @ViewChild(LtTableComponent) table: LtTableComponent;

  constructor(
    private _snackBar: MatSnackBar,
    private _service: InquiryService,
    private organizerService: OrganizerService,
    private router: Router,
    private route: ActivatedRoute,
    private formEventService: FormEventService,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    this.INQUIRY_STATUSES = INQUIRY_STATUSES;
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.allSub.push(
      this.route.queryParams.subscribe(params => {
        this.organizerService.getAll().subscribe(organizers => {
          this.organizersList = organizers.data.map(org => {
            return {
              id: org.id,
              name: org.name,
            }
          });

          this.inquiryPageSize = params.pageSize ?? this.inquiryPageSizeDefault;
          this.setFilterConfig(params);
          this.getInquiries({ pageSize: this.inquiryPageSize, ...params });
        });
      })
    );

    this.allSub.push(this.formEventService.eventEmitter$.subscribe(event => {
      if (event.message == 'lt-inquiry-status-id-changed') {
        if (event.value != event.oldValue) {
          const rowId = event.editedData.id;
          const communicationChannel = event.editedData.communication_channel.config.value ?? null;
          this._service.updateStatus(rowId, event.value, communicationChannel).subscribe(resp => {
            if (resp.data == 1) {
              this.openSnackBar('Status upita uspešno ažuriran', null, {
                duration: 3000,
                panelClass: ['green-snackbar']
              });
              this.router.navigate(
                [],
                {
                  relativeTo: this.route,
                  queryParams: { saved: Date.now() },
                  queryParamsHandling: 'merge'
                });
            }
          }, err => {
            const errorMsg = err?.error?.message ?? 'Desila se greška, molimo pokušajte ponovo';
            this.showError(errorMsg);
          });
        }
        this.table.destroyInlineComponent();
      }

      if (event.message == 'lt-inquiry-status-changed') {
        if (event.value != event.oldValue) {
          const rowId = event.editedData.id;
          this._service.setArchiveStatus(rowId, event.value).subscribe(resp => {
            if (resp.data == 1) {
              this.openSnackBar('Status uspešno ažuriran', null, {
                duration: 3000,
                panelClass: ['green-snackbar']
              });
              this.router.navigate(
                [],
                {
                  relativeTo: this.route,
                  queryParams: { saved: Date.now() },
                  queryParamsHandling: 'merge'
                });
            }
          }, err => {
            const errorMsg = err?.error?.message ?? 'Desila se greška, molimo pokušajte ponovo';
            this.showError(errorMsg);
          });
        }
        this.table.destroyInlineComponent();
      }
      if (event.message == 'lt-inquiry-communication-channel-changed') {
        if (event.value != event.oldValue) {
          const rowId = event.editedData.id;
          const statusId = event.editedData?.status_id?.config?.value ?? null;
          this._service.updateCommunicationChannel(rowId, event.value, statusId).subscribe(resp => {
            if (resp.data == 1) {
              this.openSnackBar('Kanal komunikacije uspešno ažuriran', null, {
                duration: 3000,
                panelClass: ['green-snackbar']
              });
              this.router.navigate(
                [],
                {
                  relativeTo: this.route,
                  queryParams: { saved: Date.now() },
                  queryParamsHandling: 'merge'
                });
            }
          }, err => {
            const errorMsg = err?.error?.message ?? 'Desila se greška, molimo pokušajte ponovo';
            this.showError(errorMsg);
          });
        }
        this.table.destroyInlineComponent();
      }
    }));
  }

  getInquiries(params) {
    this.isSearching = true;
    const searchParams = {
      pageIndex: 0,
      pageSize: 20,
      status: params.status !== undefined ? params.status : this.defaultValues.status.join(','),
      status_id: params.status_id !== undefined ? params.status_id : this.defaultValues.status_id.join(','),
      id: params.id ?? '',
      organizer: params.organizer ?? ''
    };

    if (params.pageIndex !== undefined) {
      searchParams.pageIndex = params.pageIndex;
    }
    if (params.pageSize !== undefined) {
      searchParams.pageSize = params.pageSize;
    }

    this._service.find(searchParams).subscribe(resp => {
      this.dataSource = resp.data.map(oneRow => {
        const data = JSON.parse(oneRow.data);
        const kids = [];
        for (let i = 1; i <= 4; i++) {
          if (data['deteGodine' + i]) {
            kids.push(data['deteGodine' + i]);
          }
        }
        let accomodation = [data.smestaj ?? ''];
        if (data.tipSobe) {
          accomodation.push(data.tipSobe);
        }
        let datesAndNightsInfo = (data.termin ?? '');
        datesAndNightsInfo += data.datumPolaska ? ' ' + data.datumPolaska : '';
        datesAndNightsInfo += data.brojNocenja ? ' - ' + data.brojNocenja : '';
        //@todo think something better Interface + separate method or view
        let avioData = '';
        if (data.type == 'avio') {
          avioData += 'Podaci sa forme: ';
          avioData += 'Polaziste:' + data?.polaziste + ' ';
          avioData += 'Odrediste:' + data?.odrediste + ' ';
          avioData += 'Polazak:' + data?.polazak + ' ';
          avioData += 'Povratak:' + data?.povratak + ' ';
          avioData += 'Direktan:' + data?.direkt;
        }
        const message = avioData.length > 0 ? avioData + ' ' + data.poruka : data?.poruka;
        return {
          ...oneRow,
          idInquiry: {
            idParam: oneRow.id,
            text: oneRow.id,
          },
          timestamp: (formatDatetime(oneRow.timestamp)).split(' '),
          name_lastname: data.ime ?? '',
          type: [
            (data?.vrstaZahteva ? data.vrstaZahteva.substring(0, 4) : data.type),
            oneRow.user ? oneRow.user.split(' ')[0] : ''
          ],
          phone: data.telefon ?? '',
          email: data.email ?? '',
          adults: data.odrasli ?? '',
          organizerName: oneRow.organizerName ? oneRow.organizerName.substring(0, 10) : '',
          user_message: message,
          children: data.dete ? data.dete + '(' + kids.join(", ") + ')' : '',
          departureDate: data.datumPolaska ?? '',
          dateFromTo: datesAndNightsInfo,
          accomodation: accomodation,
          pre_reservation: PRERESERVATION_OPTIONS.find(el => el.id == oneRow.pre_reservation)?.name,

          //@todo put messages as constants
          status_id: {
            text: this.INQUIRY_STATUSES.find(el => el.id == oneRow.status_id)?.name,
            config: {
              label: 'Status upita',
              name: 'status_id',
              eventName: 'lt-inquiry-status-id-changed',
              options: this.INQUIRY_STATUSES,
              placeholder: 'Status upita',
              col: 12,
              value: oneRow.status_id
            },
            class: 'lt-editable ' + (oneRow.status_id == '50' ? 'mat-chip-blue' : '')
          },
          status: {
            text: this.STATUSES.find(el => el.id == oneRow.archive)?.short,
            config: {
              label: 'Status',
              name: 'status',
              eventName: 'lt-inquiry-status-changed',
              options: this.STATUSES,
              placeholder: 'Status',
              col: 12,
              value: oneRow.archive
            }
          },
          communication_channel: {
            text: this.COMMUNICATION_CHANNEL.find(el => el.id == oneRow.communication_channel)?.name ?? '---',
            config: {
              label: 'Komunikacija',
              name: 'communication_channel',
              eventName: 'lt-inquiry-communication-channel-changed',
              options: this.COMMUNICATION_CHANNEL,
              placeholder: 'Komunikacija',
              col: 12,
              value: oneRow.communication_channel
            }
          },
          referer: {
            href: data.referer ?
              data.referer +
              (
                data.referer && data.referer.indexOf('?') != -1 ?
                  '&ltAppRef=1' : '?ltAppRef=1'
              ) : '',
            text: data.referer ? data.referer : oneRow.arrangementNameById
          },
          createApplication: {
            params: {
              fromInquiry: oneRow.id,
            },
            text: '',
          }
        };
      });
      this.totalRowsCount = resp['meta'].totalCount;
      this.isSearching = false;
    }, err => {
      this.isSearching = false;
      const errorMsg = err?.error?.message ?? 'Desila se greška, molimo pokušajte ponovo';
      this.showError(errorMsg);
    });
  }

  onTableEvent($event) {
    const eventName = $event.name ?? null;
    if (eventName == 'lt-inquiry-status-history') {
      if ($event?.data?.id) {
        let changes = [];
        //@todo status_id as constant, used on multiple palces
        this._service.getChanges($event.data.id, 'status_id').subscribe(resp => {
          const data = resp.data;
          changes = data.map(oneChange => {
            let currentValue = oneChange.current_value ? JSON.parse(oneChange.current_value) : {};
            currentValue = JSON.parse(currentValue);
            const statusId = currentValue.status_id ?? null;
            let statusInfo = this.INQUIRY_STATUSES.find(el => el.id == statusId);
            const statusName = statusId && statusInfo ? statusInfo.name : 'Nepoznat(Greska)';

            return {
              text: formatDatetime(oneChange.timestamp) + " " + oneChange.user + ' : ' + statusName
            }
          });
          this.dialog.open(InquiryStatusChangelogComponent, {
            height: '80vh',
            data: { list: changes }
          });
        });
      }
    }

    if (eventName == 'lt-edit-inquiry') {
      if ($event?.data?.id) {
        const ref = this.dialog.open(InquiryEditComponent, {
          height: '80vh',
          data: { id: $event.data.id }
        });
        ref.afterClosed().subscribe((res) => {
          if (res && res.loadData) {
            this.router.navigate(
              [],
              {
                relativeTo: this.route,
                queryParams: { search: Date.now() },
                queryParamsHandling: 'merge'
              }
            );
          }
        });
      }
    }
  }

  onFilterChanged(event) {
    let filterFormValues = this.filter.filterForm.getRawValue();
    for (let i in filterFormValues) {
      const value = filterFormValues[i];
      if (Array.isArray(value)) {
        filterFormValues[i] = value.join(',');
      }
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { pageSize: this.inquiryPageSize, pageIndex: 0, ...filterFormValues, search: Date.now() },
        queryParamsHandling: 'merge'
      }
    );
  }

  paginationChanged(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          pageIndex: event.data.pageIndex,
          pageSize: event.data.pageSize
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  setFilterConfig(values) {
    this.filterConfig = [
      {
        type: 'input',
        label: 'Br. Upita',
        name: 'id',
        placeholder: 'Br. Upita',
        col: 1,
        value: values.id ?? ''
      },/*
        {
          type: 'datepicker',
          label: 'Polazak od',
          name: 'departure_from',
          placeholder: 'Polazak od', 
          //value: this.searchValues?.departure_from ? this.searchValues.departure_from : '',
          col: 2
        },
        {
          type: 'datepicker',
          label: 'Polazak do',
          name: 'departure_to',
          placeholder: 'Polazak do',
          //value: this.searchValues?.departure_to ? this.searchValues.departure_to : '',
          col: 2
        },*/
      {
        type: 'select',
        label: 'Validnost',
        name: 'status',
        multiple: true,
        options: this.STATUSES,
        placeholder: 'Validnost',
        value: values.status !== undefined ? values.status.split(',') : this.defaultValues.status,
        col: 2
      },
      {
        type: 'select',
        label: 'Status upita',
        name: 'status_id',
        multiple: true,
        options: this.INQUIRY_STATUSES,
        placeholder: 'Status upita',
        value: values.status_id !== undefined ? values.status_id.split(',') : this.defaultValues.status_id,
        col: 2
      },
      /*{
        type: 'input',
        label: 'Ime/Prezime',
        name: 'name',
        placeholder: 'Ime/Prezime', 
        col: 2,
        value: values.name ?? ''
      },
      {
        type: 'input',
        label: 'Email',
        name: 'email',
        placeholder: 'Email', 
        col: 2,
        value: values.name ?? ''
      },*/
      {
        type: 'select',
        label: 'Organizator',
        name: 'organizer',
        multiple: true,
        options: this.organizersList,
        placeholder: 'Organizator',
        value: values?.organizer !== undefined ? values.organizer.split(',') : [],
        col: 2
      },
    ];
  }
  //@todo if not used in 2 months delete 06.05.23
  /* not used atm
    archiveInquiry(row) {
      if (!row.id) {
        alert('Greska! Nedostaje ID upita za arhiviranje');
        return;
      }
  
      this._service.setArchiveStatus(row.id, 1).subscribe(resp => {
        if (resp.data == 1) {
          this.openSnackBar('Uspešno sačuvano');
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: {saved: Date.now()},
              queryParamsHandling: 'merge'
            });
        }
      }, err => {
        const errorMsg = err?.error?.message ?? 'Desila se greška, molimo pokušajte ponovo';
        this.showError(errorMsg);
      });
    }
    */
  openSnackBar(message: string, action = null, options = {}) {
    this._snackBar.open(message, action, {
      duration: 5000,
      ...options
    });
  }
  showError(message: string, action = null) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['red-snackbar']
    });
  }

  openNewInquiryDialog() {
    this.dialog.open(NewInquiryComponent, {
      data: {}
    }).afterClosed()
      .subscribe(
        (data) => {
          if (data && data['success']) {
            this._snackBar.open('Unos uspešan', 'Sačuvano', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
            this.router.navigate(
              [],
              {
                relativeTo: this.route,
                queryParams: { saved: Date.now() },
                queryParamsHandling: 'merge'
              });
          }
        },
        (err) => {
          this.showError(err.error.message);
        }
      );
  }
}
