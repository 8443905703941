import { Component, Inject, Optional, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DynamicFormComponent } from "src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component";
import { ResponseMessageService } from "src/app/shared/services/response-message.service";
import { Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { DomesticCityService } from "./domestic-city.service";


@Component({
    selector: 'app-cis-storno-dialog',
    template: `
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loading"></mat-progress-bar>

    <h3>

  Dodaj novo mesto/grad
    </h3>
  <dynamic-form
        [config]="config"
        #form="dynamicForm">
        </dynamic-form>
         <button class="green-btn" mat-button [disabled]="loading" (click)="save($event)">
            Sačuvaj
        </button>
        &nbsp;
        <button class="" [disabled]="loading" mat-button [mat-dialog-close]="true">
            Nazad
        </button>
  `
})
export class DomesticCityAssignComponent {
    cisId = null;
    @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
    loading = false;
    placeName = '';
    config = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {placeName?: string},
        @Optional() public dialogRef: MatDialogRef<DomesticCityAssignComponent>,
        private domesticCityService: DomesticCityService,
        private respMessageService: ResponseMessageService,
    ) {
        //this.placeName = data?.placeName ?? '';
        this.setConfig();
        /*
        setTimeout(()=> {
            this.form.setValue('name', {name:'lova'});
        }, 200);
        */
    }
    
    setConfig() {
        this.config = [
            {
                type: 'autocomplete',
                label: 'Naziv mesta',
                name: 'name',
                //value: {name: placeName, value: null},
                placeholder: 'Naziv mesta',
                validation: [Validators.required],
                searchStringKey: 'name',
                api: {
                    hostUrl: environment.apiHost,
                    endpoint: '/lider/domestic-city/find-not-assigned',
                },
            }
        ];
    }
    

    save(event) {
        this.loading = true;
        const place = this.form.form.controls['name']?.value;
        const placeId = place?.id;
        if (!placeId) {
            this.respMessageService.showError('Odaberite jedno od mesta sa liste');
            this.loading = false;
            return;
        }
        //this.dialogRef.close({reason: 'saved'});

        this.domesticCityService.addSelectedCity(placeId).subscribe(
            response => { 
                if(response['success'] === true) {    
                    this.respMessageService.showSuccess();
                    this.dialogRef.close({event:'success', data: place});
                } else {
                    this.respMessageService.showError(null);
                }
            },
            err => { 
                this.respMessageService.showError(err?.error?.message);
            }
        ).add(() => {
            this.loading = false;
        });
    }

    closeDialog(event) {
        this.dialogRef.close({reason: 'cancel'});
    }

}