<mat-card class="example-card">
    <mat-toolbar class="no-padding no-margin">
        <button mat-mini-fab color="primary"><mat-icon>receipt_long</mat-icon></button>Fikalni računi
        <span class="example-spacer"></span>
        <h4 *ngIf="!referenceItemId">Dodavanje moguće nakon što se ugovor sačuva i doda u CIS</h4>
        <button *ngIf="referenceItemId" mat-stroked-button color="primary" aria-label="Dodaj fiskalni račun" (click)="openDialogFiscal()"><mat-icon>add</mat-icon>Dodaj fiskalni račun</button>
        &nbsp;<button *ngIf="referenceItemId" mat-stroked-button color="primary" aria-label="Generiši fiskalni račun" (click)="openFiscalGenerateDialog()"><mat-icon>receipt_long</mat-icon>Generiši fiskalni račun</button>
    </mat-toolbar>
    <mat-card-content>
       <app-lt-table *ngIf="receiptsFormatted.length"
       [columns]="columns"
       [dataSource]="receiptsFormatted"
       (tableEvent)="onTableEvent($event)"></app-lt-table>
    </mat-card-content>
</mat-card>