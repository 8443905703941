import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';
import { FormEventService } from '../../form-event.service';

@Component({
  selector: 'form-input',
  styleUrls: ['form-input.component.scss'],
  template: `
  <mat-form-field [formGroup]="group" class="example-full-width">
      <input matInput placeholder="{{config.placeholder}}" [(value)]="config.value" [formControlName]="config.name"
      (click)="onClick($event)"
      (keyup)="onKeyup($event)"
      >
      <ng-container *ngFor="let validation of config.validation" ngProjectAs="mat-error">
        <mat-error *ngIf="group.controls[config['name']].touched && group.controls[config['name']].hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
  </mat-form-field>
  <mat-hint *ngIf="config.hint">{{ config.hint }}</mat-hint>
  `
})
export class FormInputComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor(private formEventService: FormEventService) {
    super();
  }

  onClick(event) {
    if (this.config.eventMessages?.onClick != undefined) {
      this.formEventService.eventEmitter$.next({
        message: this.config.eventMessages?.onClick ?? null,
        el: this,
        value: event.target.value
      });
    }
  }
  onKeyup(event) {
    if (this.config.eventMessages?.onKeyup != undefined) {
      this.formEventService.eventEmitter$.next({
        message: this.config.eventMessages?.onKeyup ?? null,
        el: this,
        value: event.target.value
      });
    }
  }

}
 