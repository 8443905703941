<div *ngIf="showForm">
    <div class="form">
        <div style="text-align:center">
            <h1>
            Nova usluga
            </h1>
        </div>
    
        <dynamic-form
        [cols]="3"
        [config]="config"
        #form="dynamicForm"
        (submit)="submitArrangmentService($event)">
        </dynamic-form>
    
        <div class="margin-top">
        </div>
    </div>
</div>

      