import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HelperDataService {
    constructor() { }

    getPaymentSubjects(){
        return [
            {
                id: 1,
                name: 'Putovanje',
                checked: true
            },
            {
                id: 2,
                name: 'Avio karta',
                checked: false
            },
            {
                id: 3,
                name: 'PZO',
                checked: false
            },
            {
                id: 4,
                name: 'Slobodan unos',
                checked: false
            },
            {
                id: 5,
                name: 'Uplata pazara',
                checked: false
            }
        ];
    }
    getMoneyTypes(){
        return [
            {
                id: 1,
                name: 'Keš',
                checked: false
            },
            {
                id: 2,
                name: 'Ček',
                checked: false
            }
        ];
    }

    getTransactionTypes(){
        return [
            {
                id: '1',
                name: 'Uplata',
                checked: true
            },
            {
                id: '2',
                name: 'Isplata',
                checked: false
            }
        ];
    }

}