import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class BankCardTransactionService {
    
    constructor(private http: HttpClient) {
    }
    headers = {
        'enctype': 'multipart/form-data'
    };
    url =  environment.apiHost + '/lider/bank-card-transaction';

  
    findById(id: string): Observable<any> {
        let url = this.url + '/' + id; 
        let params = {};
        let headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    save(entity: {}): Observable<any> {
        let url = this.url; 
        let headers = this.headers;

        return this.http.post<any>(url, entity, {headers});
    }

    getAll(id = null): Observable<any> {
        let params = {};
        if (id) {
            params['id'] = id;
        }
        return this.http.get<any>(this.url, {params: params});
    }

    getReceiptData(id): Observable<any> {
        let params = {};
        if (id) {
            params['id'] = id;
        }
        return this.http.get<any>(this.url + '/receipt', {params: params});
    }
    getArchive(): Observable<any> {
        const path = this.url + '/archive';
        return this.http.get<any>(path);
    }

    getProviders(): Observable<any> {
        const path = environment.apiHost + '/lider/provider';
        return this.http.get<any>(path);
    }
    
}

