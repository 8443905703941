import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Contact} from '../../contact/contact';
import {FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {prepareFormGroup} from '../../shared/helpers/form';
import {FieldConfig} from '../../components/dynamic-form-master/models/field-config.interface';
import {Subscription} from 'rxjs';
import {getDateFromPersonalId} from '../../shared/helpers/jmbg';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy {

  @Input() contact: Contact | null = null;
  @Input() contactForm = new UntypedFormGroup({});

  contactConfig: FieldConfig[] = [];
  allSub: Subscription[] = [];

  constructor() { }


  ngOnInit(): void {
    this.setContactFormConfig(this.contact);

    this.allSub.push(
      this.contactForm.valueChanges
        .subscribe((formValue) => {
          const client = this.contactForm.controls;

          const personalIdControl = client.personal_id || null;
          const customerBirthControl = client.birthday || null;

          if (!personalIdControl?.pristine /*&& !customerBirthControl?.value*/) {
            const personalId = personalIdControl.value;
            if (personalId.length === 13) {
              const date = getDateFromPersonalId(personalId);
              if (date != customerBirthControl?.value) {
                customerBirthControl.setValue(date);
              }
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    for (const sub  of this.allSub) {
      if(!sub.closed) {
        sub.unsubscribe();
      }
    }
  }

  setContactFormConfig(values: Contact | null) {
    const contactConfig: FieldConfig[]  = [
      {
        type: 'input',
        label: 'Ime',
        name: 'name',
        placeholder: 'Ime',
        validation: [Validators.required],
        value: values?.name || ''
      },
      {
        type: 'input',
        label: 'Prezime',
        name: 'lastname',
        placeholder: 'Prezime',
        validation: [Validators.required],
        value: values?.lastname || ''
      },
      {
        type: 'input',
        label: 'Telefon',
        name: 'phone',
        placeholder: 'Telefon',
        // validation: [Validators.required],
        value: values?.phone || ''
      },
      {
        type: 'input',
        label: 'Email',
        name: 'email',
        placeholder: 'Email',
        // validation: [Validators.required],
        value: values?.email || ''
      },
      {
        type: 'input',
        label: 'JMBG',
        name: 'personal_id',
        placeholder: 'JMBG',
        // validation: [Validators.required],
        value: values?.personal_id || ''
      },
      {
        type: 'datepicker',
        label: 'Datum rodjenja',
        name: 'birthday',
        placeholder: 'Datum rodjenja',
        // validation: [Validators.required],
        value: values?.birthday || ''
      },
      {
        type: 'input',
        label: 'Broj pasoša',
        name: 'passport_number',
        placeholder: 'Broj pasoša',
        // validation: [Validators.required],
        value: values?.passport_number || ''
      },
      {
        type: 'input',
        label: 'Ulica',
        name: 'address',
        placeholder: 'Ulica',
        // validation: [Validators.required],
        value: values?.address || ''
      },
      {
        type: 'input',
        label: 'Grad',
        name: 'city',
        placeholder: 'Grad',
        // validation: [Validators.required],
        value: values?.city || ''
      },
      {
        type: 'select',
        label: 'Pol',
        name: 'sex',
        options: [
          {
            id: 1,
            name: 'Muški'
          },
          {
            id: 2,
            name: 'Ženski'
          }
        ],
        placeholder: 'Pol',
        validation: [Validators.required],
        value: values?.sex || ''
      },
      {
        type: 'input',
        label: 'Izvor',
        name: 'source',
        placeholder: 'Izvor',
        // validation: [Validators.required],
        value: values?.source || ''
      }
    ];


    // set default col size for each input
    this.contactConfig = contactConfig.map(formControl => {
      return {
        ...formControl,
        col: formControl.col || 6
      }
    });

    this.contactForm = prepareFormGroup(this.contactConfig, this.contactForm);
  }

}
