import { Hotel } from './hotel';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from './../../environments/environment';
import { ResponseBody } from '../model/response-body';
//import { HotelFilter } from './hotel-filter';

@Injectable()
export class HotelService {
    url = environment.apiHost + '/lider/hotel';
    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };

    constructor(private http: HttpClient) {
    }

    dataList: Hotel[] = [];
  
    findById(id: number): Observable<Hotel> {
        let params = {};
        let headers = this.headers;
        return this.http.get<Hotel>(this.url + '/' + id, {params, headers});
    }
    
    load(filter: object): void {
        this.find(filter).subscribe(
            result => {
                this.dataList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: object,expand: string = ''): Observable<any[]> {

        let params = {
            sort: '-id'
        };
        if(filter['name']){
            params['name'] = filter['name'];
        }
        if(filter['arrangement']){
            params['arrangement'] = filter['arrangement'];
        }
        if(filter['active']){
            params['active'] = filter['active'];
        }
        if(expand){
            params['expand'] = expand;
        }
        //params['page'] = data['page'];
        //params['per-page'] = data['perPage'];
        let headers = this.headers;

        return this.http.get<Hotel[]>(this.url + '/hotel-old', {params, headers});
    }

    getHotels(id): Observable<ResponseBody<any>> {
        let url =  this.url + '/search';
        let headers = this.headers;
        let params = {id};
        return this.http.get<ResponseBody<any>>(url, {params, headers});
    }
    
    save(entity: Hotel): Observable<Hotel> {
        let headers = this.headers;
        return this.http.post<Hotel>(this.url, entity, {headers});
    }
}

