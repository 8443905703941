export class TransactionFilter {
    id: string = '';
    type: string = '';
    payment_method: string = '';
    payment_subject: string = '';
    payment_period_id: string = '';
    timestamp: string = '';
    amount: string = '';
    open: number;
    reference: number;
    sortCol: string;
    sortOrder: string;
}