import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators} from '@angular/forms';
import { prepareFormGroup } from '../shared/helpers/form';
import { Transaction } from './Transaction';
import { TransactionService } from './transaction.service';
import { FormEventService } from '../components/dynamic-form-master/form-event.service';
import { DynamicFormComponent } from '../components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  //styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {

    @Input() entity: Transaction | null = null;
    @Input() entityForm = new UntypedFormGroup({});
    @Input() suppliers = [];

    entityConfig = [];
    transactionTypes;
    accountTypes;
    currencies;
    reasonTypes;

    subscriptions = [];
     @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

    constructor(
        private transactionService: TransactionService,
        private formEventService: FormEventService,
    ) {
    }

    ngOnInit(): void {
        this.transactionService.getInOut().subscribe(inOut => {
            this.transactionTypes = inOut;
        });
        this.transactionService.getAccountTypes().subscribe(types => {
            this.accountTypes = types;
        });
        this.transactionService.getCurrencies().subscribe(currencies => {
            this.currencies = currencies;
        });
        this.transactionService.getTranactionReasons().subscribe(reasons => {
            this.reasonTypes = reasons;
        });
        this.setEntityFormConfig(this.entity);

        this.subscriptions.push(
        this.formEventService.eventEmitter$.subscribe(event => {
            if (event.message == 'ltMoneyTypeChanged') {
                const moneyTypeSelected = event.value;
                const isEnabled = this.isStatementDateEnabled(moneyTypeSelected);
                this.form.setDisabled('original_date', !isEnabled);
                if (!isEnabled) {
                    this.entityForm.controls['original_date'].setValue(null);
                }
            }
        })
        );
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.entity) {

        }
    }

    setEntityFormConfig(values: Transaction | null, mode = 'create') {
        const disabled = values && values?.id;
        const moneyTypeValue = values?.account_type
            || this.accountTypes.find(item => item.checked === true)?.id;
        this.entityConfig = [
            {
                type: 'select',
                label: 'Uplata/Isplata',
                name: 'in_out',
                placeholder: 'Uplata/Isplata',
                //validation: [Validators.required],
                options: this.transactionTypes,
                value: values?.in_out || this.transactionTypes.find(item => item.checked === true)?.id,
                disabled: disabled
            },
            {
                type: 'select',
                label: 'Vrsta novca',
                name: 'account_type',
                placeholder: 'Vrsta novca',
                options: this.accountTypes,
                validation: [Validators.required],
                value: moneyTypeValue,
                disabled: disabled,
                eventMessages: {
                    onChange: 'ltMoneyTypeChanged'
                }
            },
            {
                type: 'input',
                label: 'Iznos',
                name: 'amount',
                placeholder: 'Iznos',
                validation: [Validators.required],
                value: values?.amount || '',
                disabled: disabled
            },
            {
                type: 'select',
                label: 'Valuta',
                name: 'currency',
                options: this.currencies,
                placeholder: 'Valuta',
                validation: [Validators.required],
                value: values?.currency || this.currencies.find(item => item.checked === true)?.id,
                disabled: disabled
            },
            {
                ...{
                    type: 'datepicker',
                    label: 'Datum izvoda',
                    name: 'original_date',
                    placeholder: 'Datum izvoda',
                    value: values?.original_date,
                },
                ...(!this.isStatementDateEnabled(moneyTypeValue) && {disabled: true})
            },
            {
                type: 'select',
                label: 'Svrha transakcije',
                name: 'reason_type',
                options: this.reasonTypes,
                placeholder: 'Svrha transakcije',
                validation: [Validators.required],
                value: values?.reason_type || this.reasonTypes.find(item => item.checked === true)?.id,
                disabled: disabled
            },
            {
                type: 'select',
                label: 'Dobavljač',
                name: 'supplier_id',
                options: this.suppliers,
                placeholder: 'Dobavljač',
                validation: [Validators.required],
                value: values?.supplier_id || null,
                disabled: disabled
            },
            {
                type: 'input',
                label: 'Referenca ID',
                name: 'reference_id',
                placeholder: 'Referenca ID',
                value: values?.reference_id || null,
                //because of contract reference is prefilled and should not be changed
                disabled: values?.reference_id || disabled ? true : false,
            },
            {
                type: 'input',
                label: 'Referenca tekst.',
                name: 'reference_text',
                placeholder: 'Referenca tekst.',
                value: values?.reference_text || null,
                disabled: disabled
            },
            {
                type: 'textarea',
                label: 'Komentar',
                name: 'comment',
                placeholder: 'Komentar',
                value: values?.comment || null,
                disabled: disabled
            },
            {
                type: 'hidden',
                name: 'reference_entity',
                value: values?.reference_entity || null
            },
        ];
        if (this.suppliers.length == 0) {
            this.entityConfig = this.entityConfig.filter(item => item.name != 'supplier_id');
        }
        this.entityForm = prepareFormGroup(this.entityConfig, this.entityForm);
    }

    isStatementDateEnabled(moneyType) {
        return this.transactionService.HAS_BANK_STATEMENT.indexOf(moneyType) !== -1;
    }
    
    ngOnDestroy(): void {
        for (const sub  of this.subscriptions) {
          if(!sub.closed) {
            sub.unsubscribe();
          }
        }
      }
}
