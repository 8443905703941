import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './../material.module';
import {SharedModule} from '../shared/shared.module';
import { INQUIRY_ROUTES } from './inquiry.routes';
import { InquiryListComponent } from './inquiry-list.component';
import { InquiryService } from './inquiry.service';
import { InquiryDetailsComponent } from './details/inquiry-details.component';
import { InquiryStatusChangelogComponent } from './inquiry-status-changelog.component';
import { NewInquiryComponent } from './new-inquiry.component';
import { InquiryEditComponent } from './inquiry-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(INQUIRY_ROUTES),
    MaterialModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    InquiryListComponent,
    InquiryDetailsComponent,
    InquiryStatusChangelogComponent,
    NewInquiryComponent,
    InquiryEditComponent
  ],
  providers: [
    InquiryService
  ],
  exports: [
  ]
})
export class InquiryModule { } 
