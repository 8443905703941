/* tslint:disable:prefer-const */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { ResponseBody } from '../model/response-body';


@Injectable()
export class InquiryService {
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem(environment.tokenName)
    };
    url = environment.apiHost + '/lider/inquiry';

    findById(id: string | number, expand: string = ''): Observable<any> {
        const url = this.url + '/' + id;
        const params = {};
        if (expand) {
            params['expand'] = expand;
        }
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    find(filter = {}): Observable<ResponseBody<any[]>> {
        let url = this.url;
        let headers = this.headers;
        let params = {
            ...filter
        };
        return this.http.get<ResponseBody<any[]>>(url, {params, headers});
    }
    getDetails(inquiryId): Observable<ResponseBody<any[]>> {
        let url = this.url + '/details/' + inquiryId;
        let headers = this.headers;
        let params = {};
        return this.http.get<ResponseBody<any[]>>(url, {params, headers});
    }

    getChanges(inquiryId, columnName): Observable<ResponseBody<any[]>> {
        let url = this.url + '/changes/' + inquiryId;
        let headers = this.headers;
        let params = {
            column_name : columnName
        };
        return this.http.get<ResponseBody<any[]>>(url, {params, headers});
    }

    setArchiveStatus(id, status : 2 | 1 | 0): Observable<any> {
        let url = this.url;
        url += '/archive/' + id;
        let headers = this.headers;
        const params = {
            status : status
        };
        return this.http.put<any>(url, params, {headers});
    }
    updateStatus(id, statusId : 50 | 40 | 30 | 20 | 10, communication_channel: 50 | 40 | 30 | 20 | 10): Observable<any> {
        let url = this.url;
        url += '/update-status/' + id;
        let headers = this.headers;
        const params = {
            status_id : statusId,
            communication_channel : communication_channel,
        };
        return this.http.put<any>(url, params, {headers});
    }

    updateCommunicationChannel(id, channelId : 0 | 40 | 30 | 20 | 10, statusId): Observable<any> {
        let url = this.url;
        url += '/update-communication-channel/' + id;
        let headers = this.headers;
        const params = {
            communication_channel : channelId,
            status_id : statusId,
        };
        return this.http.put<any>(url, params, {headers});
    }

    updateInquiry(id, params :{ pre_reservation?: string , note?: string}): Observable<any> {
        let url = this.url;
        url += '/' + id;
        let headers = this.headers;
        return this.http.put<any>(url, params, {headers});
    }

    create(params): Observable<any> {
        let url = this.url;
        let headers = this.headers;
        return this.http.post<any>(url, params, {headers});
    }
}

