import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AgencySettingsService } from '../settings/agency-settings/agency-settings.service';
import { ResponseMessageService } from '../shared/services/response-message.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  providers: [AgencySettingsService]
})
export class PrintComponent implements OnInit {
  logo = '';
  agencyId = 1;
  companyName = '';
  address = '';
  pib = '';
  jib = '';
  bankAccount = '';
  phone = '';
  email = '';

  constructor(
    private agencySettingsService: AgencySettingsService,
    private responseMessageService: ResponseMessageService,
  ) {}

  ngOnInit() {
    this.agencySettingsService.findById(this.agencyId).subscribe(
      resp => {
      const agencyInfo = resp?.data?.agencySettings ?? {};
      this.logo = environment.logos + agencyInfo.logo_url;
      this.companyName = agencyInfo.name;
      this.address = agencyInfo.address +', ' + agencyInfo.city;
      this.pib = agencyInfo.pib;
      this.jib = agencyInfo.jib;
      this.bankAccount = agencyInfo.account_no;
      this.phone = agencyInfo.tel;
      this.email = agencyInfo.email;
    }, err => {
      this.responseMessageService.showError(err?.error?.message);
    });
  }

}