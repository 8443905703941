/* tslint:disable:prefer-const */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable()
export class EntityFilterService {
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem(environment.tokenName)
    };
    url = environment.apiHost + '/lider/entity-filter';

    findById(id: string, expand: string = ''): Observable<any> {
        const url = this.url + '/' + id;
        const params = {};
        if (expand) {
            params['expand'] = expand;
        }
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    getFiltersById(id: string, entityType): Observable<any> {
        const url = this.url + '/get-filters/' + id;
        const params = {
            entityType
        };
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    getAllEntityFilters(entityType) {
        const url = this.url;
        const params = {
            entityType
        };
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    getAllOptions(entityType) {
        const url = this.url;
        const params = {
            entityType
        };
        const headers = this.headers;
        return this.http.get<any>(url + '/all-options', {params, headers});
    }

    find(filter): Observable<any[]> {
        let url = this.url;
        let headers = this.headers;
        let params = {
            ...filter
            //expand: filter.expand
        };
        return this.http.get<[]>(url, {params, headers});
    }
    massUpdate(entityIds: number[], actions: []): Observable<any> {
        let headers = this.headers;
        let params = {
            entityIds,
            actions
        };
        return this.http.post<any>(this.url + '/mass-update-filters', params, {headers});
    }
    save(entityId, rows): Observable<any> {
        let url = this.url + '/update-filters';
        let headers = this.headers;

        const data  = {id: entityId, rows};
        return this.http.post<any>(url, data, {headers});
    }
}

