<mat-card>
    <mat-card-title>
        <button mat-mini-fab color="primary"><mat-icon>perm_identity</mat-icon></button> Kontakt
    </mat-card-title>
    <mat-card-content>
        <app-contact [contactForm]="contactForm" [contact]="contact"></app-contact>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button (click)="submitForm()" color="primary">
            <mat-icon>save</mat-icon>Sačuvaj promene</button>
    </mat-card-actions>
</mat-card>