import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DynamicFormComponent } from '../components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { HotelService } from '../hotel/hotel.service';
import { EntityType } from './enity-type.enum';
import { EntityFilterService } from './entity-filter.service';

@Component({
  selector: 'app-hotel-has-filter-edit',
  templateUrl: './hotel-has-filter-edit.component.html',
  styleUrls: ['./hotel-has-filter-edit.component.scss']
})
export class HotelHasFilterEditComponent implements OnInit {

  	isSearching: boolean = false;
    allSub: Subscription[] = [];
    hotelId = null;
	hotel = {
		name: ''
	};
	filterConfig = [];
	
    @ViewChild('filtersForm') filtersForm: DynamicFormComponent;

    constructor(
        private hotelService: HotelService,
        private entityFilterService: EntityFilterService,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
    ) {}
  
	ngOnInit(): void {
		this.allSub.push(
            this.route.params.pipe(
                switchMap( params => {
                    this.hotelId = params.id;
					this.entityFilterService.getFiltersById(this.hotelId, EntityType.HOTEL).subscribe(
						res => {
							let filterConfig = [];
							Object.keys(res.data).forEach(filterId => {
								const filterData = res.data[filterId];
								
								filterConfig.push(
									{
										type: 'selectChip',
										name: filterData.id,
										placeholder: 'Filter: ' + filterData.name,
										options: filterData.options,
										value: filterData.value
									},
								);
							});
							this.filterConfig = filterConfig;
						}, err => {
							this.showError(err.error.message);
						}
					);
                    return this.hotelService.findById(this.hotelId);
                })
            ).subscribe(
                resp => {
                    if (resp && resp['data']) {
                        this.hotel = resp['data'];
						this.isSearching = false;
                    }
                },
                err => {
					this.showError(err.error.message);
					this.isSearching = false;
                }
            )
        ); 
	}

	submitForm() {
		let data = this.filtersForm.form.getRawValue();
		let hoteHasOptionRows = [];
		Object.keys(data).forEach(key => {
			if (data[key]) {
				data[key].forEach(option => {
					hoteHasOptionRows.push({
						filter_option_id: option.id,
					});
				});	
			}
		});
		this.entityFilterService.save(this.hotelId, hoteHasOptionRows).subscribe(resp => {
			if (resp && resp.success) {
				this.openSnackBar('Filteri su uspešno sačuvani.', 'U redu', {panelClass: ['green-snackbar']});
			} else {
				this.showError('Desila se greška, molimo pokušajte ponovo ili kontaktirajte podršku');
			}
			this.isSearching = false;
		}, err => {
			this.showError(err.error.message);
			this.isSearching = false;
		})
	}

	openSnackBar(message: string, action = null, options = {}) {
        this._snackBar.open(message, action, {
            duration: 5000,
            ...options
        });
    }
    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }
}
