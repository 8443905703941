import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormComponent } from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { FiscalReceiptsEditComponent } from './fiscal-receipts-edit.component';
import { ContractService } from '../contract.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LT_DATE_RS, LT_DATE_US, formatDate, formatDatetime, todaysDate } from 'src/app/shared/helpers/date';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { FiscalReceiptService } from './fiscal-receipt-service';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { FiscalReceiptGenerateComponent } from './fiscal-receipt-generate.component';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { FiscalReceiptPreviewComponent } from '../fiscal-receipt-preview/fiscal-receipt-preview.component';

@Component({
    selector: 'app-fiscal-receipts-list',
    templateUrl: './fiscal-receipts-list.component.html',
})
export class FiscalReceiptsListComponent implements OnInit {

    fiscalReceiptsConfig = [];
    receiptsFormatted : Array<any>;
    fiscalCurrency = TransactionService.MAIN_CURRENCY.name;
    columns = [
        { name: 'number', label: 'Br. računa' },
        { name: 'date', label: 'Datum' },
        { name: 'amount', label: 'Iznos', type: 'money-object' },
        { name: 'is_fiscalized_txt', label: 'Način unosa' },
        { name: 'is_saved_in_cis', type: 'button', label: 'Sačuvano u CIS' },
        {
            name: 'edit',
            label: 'Akcije',
            type: 'button',
            actions: [
              {
                type: 'event',
                actionName: 'lt-edit-fiscal',
                title: 'Izmeni fiskal',
                icon: 'edit'
              },
              {
                type: 'event',
                actionName: 'lt-preview-fiscal',
                name: '',
                title: 'Pregled fiskala',
                icon: 'visibility'
              }
            ]
          }
    ];

    @ViewChild(DynamicFormComponent) fiscalReceiptsForm: DynamicFormComponent;
    @Input() referenceItemId: number;
    @Input() receipts: Array<any>;
    @Input() fiscalAmount: '';
    @Input() amountsPerMoneyType;
    @Input() fiscalServiceName: '';
    @Input() cisContractId: '';
    
    constructor(
        public dialog: MatDialog,
        public contractService: ContractService,
        public fiscalService: FiscalReceiptService,
        private _snackBar: MatSnackBar,
        private formEventService: FormEventService,
        private respMsgService: ResponseMessageService
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        //@todo check does it affect performance
        if (changes.receipts) {
            const defaultActions = this.columns.find(el => el.name == 'edit').actions;
            const addedToCisBtn = this.columns.find(el => el.name == 'is_saved_in_cis');
            this.receiptsFormatted = changes.receipts.currentValue.map(receipt => {
                return {
                    ...receipt,
                    date: formatDate(receipt.date),
                    amount: { //if changed pay attention used in sending to cis
                        currencyName: this.fiscalCurrency,
                        value: receipt.amount
                    },
                    edit: {
                        actions : defaultActions.filter(el => {
                            if (el.actionName == 'lt-preview-fiscal') {
                                return receipt.is_fiscalized == 1;
                            }
                            if (el.actionName == 'lt-edit-fiscal') {
                                return !(receipt.is_fiscalized == 1);
                            }
                            return true;
                        })
                    },
                    is_fiscalized_txt: receipt.is_fiscalized == 1 ? 'GENERISANO' : 'RUČNO', 
                    is_saved_in_cis: receipt.is_saved_in_cis == 1 ? 
                        {
                            actions: [{
                                type: 'event',
                                actionName: 'done',
                                btnText: 'Sačuvano',
                                icon: 'check',
                                disabled: true,
                                color: 'primary',
                            }]
                        } : 
                        {
                            actions: [{
                                type: 'event',
                                actionName: 'lt-send-to-cis',
                                title: 'Sačuvaj u CIS',
                                btnText: 'Sačuvaj u CIS',
                                icon: 'sync_alt',
                                color: 'primary',
                            }] 
                        }
                    
                }
            });
        }    
    }

    openDialogFiscal(data = {amount: '', date: null}) {
        data.amount = this.fiscalAmount;
        data.date = todaysDate(LT_DATE_US);
        const dialogRef = this.dialog.open(FiscalReceiptsEditComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.reference_id = this.referenceItemId;

                result.cisContractId = this.cisContractId;
                ( result.id ? this.contractService.updateFiscalReceipt(result) : this.contractService.addFiscalReceipt(result)).subscribe({
                    next: (resp: any) => {
                        if (resp.success) {
                            //if contract is not added to CIS it can be updated in CIS
                            if (!this.cisContractId) {
                                this._snackBar.open('Fiskalni račun je uspešno sačuvan u Aurori, ali NIJE U CIS-u', "", {
                                    duration: 5000,
                                    panelClass: ['orange-snackbar'],
                                });
                                this.emitFiscalUpdatedEvent(result.data);
                                return;
                            }
                            this.sendFiscalToCis(result);
                        }
                    },
                    error: (err) => {
                        this.showError(err?.error?.message);
                    }
                });
            }
        });
    }

    openFiscalPreview(data = {name: '', content: null}) {
        const dialogRef = this.dialog.open(FiscalReceiptPreviewComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    openFiscalGenerateDialog(data = {
        unitPrice: '', name: '', quantity: 1, invoiceType: null,
        transactionType: null, paymentType: null}) {
        data.unitPrice = this.fiscalAmount;
        data.name = this.fiscalServiceName;
        data.quantity = 1;
        data.invoiceType = 0; //normal
        data.transactionType = 0; //sale
        data.paymentType = this.amountsPerMoneyType; //default cache
        const dialogRef = this.dialog.open(FiscalReceiptGenerateComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(responseData => {
            if (responseData) {
                responseData.contractId = this.referenceItemId;
                responseData.cisContractId = this.cisContractId;

                this.fiscalService.generateFiscal(responseData).subscribe(
                    resp => {
                        let responseData = resp?.data;
                        if (resp.success && responseData) {
                            responseData.cisContractId = this.cisContractId;
                            this.respMsgService.showSuccess('Fiskal uspešno kreiran');
                            this.sendFiscalToCis(responseData);
                        }
                    }, err => {
                        if (err?.error?.message != undefined) {
                            this.showError(err?.error?.message, false);
                        } else {
                            this.showError('Nepoznata greška');
                        }
                    }
                ).add(() => {
                    //this.sendingToCis = false;
                });
                return;
            }
        });
    }

    onTableEvent(event) {
        if (event && event.name) {
          if (event.name == 'lt-edit-fiscal') {
            console.log(event.name);
            const fiscal = event?.data;
            const fiscalId = fiscal && fiscal?.id;
            if (fiscalId) {
                this.fiscalService.findById(fiscalId).subscribe(res => {
                    if (res.success && res.data) {
                        this.openDialogFiscal(res.data);
                    } else {
                        this.showError('Desila se greska. Molimo pokusajte ponovo');
                    }
                },
                err => {
                    this.showError(err?.error?.message);
                });
            }
          }
          if (event.name == 'lt-preview-fiscal') {
            const fiscal = event?.data;
            const fiscalId = fiscal && fiscal?.id;
            if (fiscalId) {
                this.fiscalService.findById(fiscalId).subscribe(res => {
                    if (res.success && res.data) {
                        this.openFiscalPreview({ content: res.data.journal, name: res.data.number});
                    } else {
                        //this.showError('Desila se greska. Molimo pokusajte ponovo');
                    }
                },
                err => {
                    this.showError(err?.error?.message);
                });
            }
          }

          if (event.name == 'lt-send-to-cis') {
            const fiscal = event?.data;
            if (!fiscal) {
                this.respMsgService.showError('Došlo je do greške. POdaci fiskala nisu pronađeni');
                return false;
            }
            const fiscalId = fiscal?.id;
            if (fiscalId) {
                this.fiscalService.findById(fiscalId).subscribe(res => {
                    if (res.success && res.data) {
                        const response = res.data;
                        const fiscalAmount = response.amount;
                        const fiscalNumber = response.number;
                        const fiscalDate = response.date;
                        if (fiscal.amount.value != fiscalAmount || fiscal.number != fiscalNumber || formatDatetime(fiscal.date, LT_DATE_RS, LT_DATE_US) != fiscalDate) {
                            this.respMsgService.showError('Podaci fiskala su promenjeni u međuvremenu. Molimo osvežite stranicu.');
                        } else {
                            this.sendFiscalToCis({
                                amount: fiscalAmount,
                                date: fiscalDate,
                                number: fiscalNumber
                            });
                        }
                    } else {
                        this.respMsgService.showError('Došlo je do greške. Negativan odgovor');
                    }
                },
                err => {
                    this.showError(err?.error?.message);
                });
            } else {
                this.respMsgService.showError('Fiskal ID atribut nije pronadjen');
            }
          }
        }
        //ltTableColumnsHidden
    }

    emitFiscalUpdatedEvent(data) {
        this.formEventService.eventEmitter$.next({
            message: 'lt-contract-fiscal-updated',
            el: null,
            value: data
        });
    }

    sendFiscalToCis(fiscalData: {
        amount: number,
        date: string,
        number: string,
    }) {
        this.contractService.saveCisPaymentData(fiscalData).subscribe({
            next: (resp: any) => {
                this._snackBar.open('Fiskalni račun je uspešno sačuvan u CIS-u', "", {
                    duration: 3000,
                    panelClass: ['success-snackbar'],
                });
                this.emitFiscalUpdatedEvent(resp.data);
            },
            error: (err) => {
                this.showError(err?.error?.message);
                this.emitFiscalUpdatedEvent(true);
            }
        });
    }

    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }


}
