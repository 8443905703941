import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import { DomesticCityService } from './domestic-city/domestic-city.service';
import { DomesticCityAssignComponent } from './domestic-city/domestic-city-assign.component';
import { MaterialModule } from '../material.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule
    ],
  declarations: [
    DomesticCityAssignComponent
  ],
  providers: [
    DomesticCityService
  ],
  exports: [
  ]
})
export class InventoryModule { } 
