import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableOptions } from '../../contract/contract.component';
import { formatDatetime, LT_DATETIME_US, LT_DATE_RS, LT_TIME } from '../../shared/helpers/date';
import { InquiryService } from './../inquiry.service';

@Component({
  selector: 'app-lt-inquiry-details',
  templateUrl: './inquiry-details.component.html',
})
export class InquiryDetailsComponent implements OnInit {

  	isSearching: boolean = false;
    allSub: Subscription[] = [];
    totalRowsCount = 0;
    dataSource = [];
    inquiryId = null;

    tableOptions: TableOptions= {
        hasHideShowCol: true
    };
    
    columns: Array<any> = [

        { name: 'date', label: 'Datum posete' },
        { name: 'time', label: 'Vreme' },
        { name: 'visitedPage', label: 'Stranica', 'className': 'visitedPage' },
        { name: 'searchedFor', label: 'URL parametri'},
      ]; 
	
    //@ViewChild('filtersForm') filtersForm: DynamicFormComponent;

    constructor(
        private _snackBar: MatSnackBar,
        private _service: InquiryService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}
  
	ngOnInit(): void {
        this.allSub.push(this.route.params.subscribe(
            params => {
                this.inquiryId = params['id'] ?? null;
                if (this.inquiryId) {
                    this.getInquiryDetails(this.inquiryId);
                }
            })
        );
	}

    getInquiryDetails(id) {
        this._service.getDetails(id).subscribe(resp => {
            const inquiry = resp?.data?.model ?? null;
            const rows = resp?.data?.rows ?? null;
            const totalCount = resp?.data?.totalCount ?? null;
            if (rows) {
                this.dataSource = rows.map(row => {
                    return {
                        ...row,
                        date: formatDatetime(row.atTime, LT_DATETIME_US, LT_DATE_RS),
                        time: formatDatetime(row.atTime, LT_DATETIME_US, LT_TIME),
                        visitedPage: row.visitedPage + (row.cameFrom ? '('+row.cameFrom+')' : '') 
                    }
                });
            } else {
                alert('Desila se greška, molimo pokušajte ponovo');
            }
        }, err => {
            const errorMsg = err?.error?.message ?? 'Desila se greška, molimo pokušajte ponovo';
            this.showError(errorMsg);
        });
    }
    onTableEvent($event) {
        console.log($event);
    }
    paginationChanged(event){
        this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: {
                pageIndex: event.data.pageIndex,
                pageSize: event.data.pageSize
              },
              queryParamsHandling: 'merge'
            }
        );
    }
	openSnackBar(message: string, action = null, options = {}) {
        this._snackBar.open(message, action, {
            duration: 5000,
            ...options
        });
    }
    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }
}
