import { Component, Output, EventEmitter, Input, SimpleChanges, OnInit } from "@angular/core";
import { ArrangmentHasService } from './arrangment-service';
import { Observable } from 'rxjs';
import { TransactionService } from "../transaction-new/transaction.service";

@Component({
  selector: "arrangment-service-edit",
  templateUrl: "./arrangment-service-edit.component.html",
})
export class ArrangmentHasServiceEditComponent implements OnInit{
    submitArrangmentService($event) {
      console.log($event);
      //value: {[name: string]: any}
        this.serviceAdded.emit($event);
    }
    @Input() showForm:boolean = false;
    @Input() customers: Observable<any>;
    @Output()
    serviceAdded: EventEmitter<any> = new EventEmitter<any>();
    currencies = [];

    customerMap = new Map();

    serviceTypes = [{ id: 1 , name: 'Aranžman'},{id:2 , name: 'Prevoz'},{id:3 , name: 'Osiguranje'}, {id:4 , name: 'Drugo'}];

    config = [];
      constructor(
        private transactionService: TransactionService
      ) {
      }

      ngOnInit() {
        this.transactionService.getCurrencies().subscribe(currencies => {
          this.currencies = currencies;
        });
        this.setConfig();
      }

      ngOnChanges(changes: SimpleChanges) {
        /*
        this.customers.subscribe(map => {
      
          let customerSelect = this.config[1];
          let customerSelectOptions = [];
          map.forEach(function(customer, customerId) {
            customerSelectOptions.push({id: customerId, name : customer.name + ' '+customer.lastname});
          });

          this.customerMap = map;
          customerSelect.options = customerSelectOptions;
        });    */
      }

      setConfig() {
        this.config = [
          {
            type: 'select',
            label: 'Tip usluge',
            name: 'type',
            options: this.serviceTypes,
            placeholder: 'Odaberi tip usluge',
          },
          {
            type: 'input',
            label: 'Naziv usluge',
            name: 'name',
            placeholder: 'Naziv usluge',
          },
          /*
          {
            type: 'select',
            label: 'Putnik',
            name: 'customer',
            options: [],
            placeholder: 'Putnik',
          },
          */
          {
            type: 'input',
            label: 'Količina',
            name: 'quantity',
            placeholder: 'Količina',
          },
          {
            type: 'input',
            label: 'Cena',
            name: 'price',
            placeholder: 'Cena',
          },
          {
            type: 'input',
            label: 'Popust',
            value: 0,
            name: 'discount',
            placeholder: 'Popust',
          },
          {
              type: 'select',
              label: 'Valuta',
              name: 'currency',
              options: this.currencies,
              placeholder: 'Odaberi valutu',
          },
          {
              type: 'input',
              label: 'Komentar',
              name: 'comment',
              placeholder: 'Komentar',
          },
          {
            label: 'Dodaj',
            name: 'submit',
            type: 'button',
          },
        ];
      }
      
    //id: number;
    //times: number;
   // arrangment: number;
}
