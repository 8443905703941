import { Contact } from '../contact/contact';
import { ArrangmentHasService } from '../arrangment-service/arrangment-service';

export class Contract {
    id: number;
    provider_id: number;
    arrangment_id: number;
    hotel_id: number;
    termin_id: number;
    room_id: number;
    transport_id: number;
    created_at: string;    
    number: string;
    customers: Contact[] = [];
    arrangements: any[] = [];
    services: ArrangmentHasService[] = [];
}