import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizerSettingsService } from './organizer-settings.service';
import { DynamicFormComponent } from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';

@Component({
  selector: 'app-organizer-settings-edit',
  templateUrl: './organizer-settings-edit.component.html',
})
export class OrganizerSettingsEditComponent implements OnInit {

  @Input() organizerId: string;
  contractPdfSettings = null;
  config = [];
  organizerSettingId = null;
  processing  = false;
  @ViewChild('settingsForm') settingsForm: DynamicFormComponent;

  constructor(
    private organizerSettingsService: OrganizerSettingsService,
    private responseMessageService: ResponseMessageService
  ) {
  }

  ngOnInit(): void {
    if (this.organizerId) {
      this.loadOrganizerSettings(this.organizerId);
    }
  }

  loadOrganizerSettings(organizerId: string) {
    this.processing = true;
    this.organizerSettingsService.findByOrganizerId(organizerId)
      .subscribe((response) => {
        if (response.success) {
          this.organizerSettingId = response.data?.settings?.id;
          this.contractPdfSettings = response.data?.settings?.contract_pdf ?? {};
          this.setConfig(this.contractPdfSettings);
        }
      }, err => {
        this.responseMessageService.showError(err?.error?.message);
      }).add(() => {
        this.processing = false;
      });
  }

  setConfig(settings) {
    const config = [];
    const items = ['1', '2', '3', '4'];
    for (let i in items) {
      const key = items[i];
      config.push({
        type: 'textarea',
        label: key,
        name: key,
        placeholder: key,
        value: settings[key] ?? ''
      });
    }
    this.config = config;
  }

  saveSettings(event) {
    this.processing = true;
    const data = this.settingsForm.form.getRawValue();
    if (JSON.stringify(data) != JSON.stringify(this.contractPdfSettings)) {
      this.processing = true;
      const payload = {
        organizer_id: this.organizerId,
        settings: {
          contract_pdf: data
        }
      };
      this.organizerSettingsService.save(payload).subscribe(
        resp => {
        this.responseMessageService.showSuccess();
      }, err => {
        this.responseMessageService.showError(err?.error?.message);
      }).add(() => {
        this.processing = false;
      });
    } else {
      this.responseMessageService.showError('Niste izmenili sadržaj');
      this.processing = false;
    }
  }

}
