export const LT_INSURANCE_TYPE = [
    {
      id: 1,
      name: 'Individualno'
    },
    {
      id: 2,
      name: 'Grupno'
    },
    {
      id: 3,
      name: 'Porodično'
    }
];

export enum InsuranceStatus {
  CREATED = 1,
  CANCELED = 10,
}