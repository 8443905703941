import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FieldConfig} from '../../components/dynamic-form-master/models/field-config.interface';

export function prepareFormGroup(configs: FieldConfig[], tmpFormGroup?: UntypedFormGroup): UntypedFormGroup {
    tmpFormGroup = tmpFormGroup || new UntypedFormGroup({});
    for (const config of configs) {
        const tmpControl = new UntypedFormControl(config.value, config.validation);
        if(config.disabled) {
            tmpControl.disable();
        }
        tmpFormGroup.addControl(config.name, tmpControl)
    }
    return tmpFormGroup;
}

export function setFormValue(tmpFormGroup: UntypedFormGroup, controlValues) {
    for(const controlName in  controlValues) {
        if(tmpFormGroup.get(controlName)) {
            tmpFormGroup.get(controlName).setValue(controlValues[controlName]);
        }
    }
}
