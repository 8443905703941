<style>
    ::ng-deep .visitedPage{
        max-width: 1000px;
        width: 1000px;
        min-width: 300px;
        white-space: initial;
    }
</style>
<mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Upit #{{inquiryId}} - Istorija kretanja korisnika</mat-card-title>
    </mat-card-header>
</mat-card>

<app-lt-table
     [options]="tableOptions" 
     [columns]="columns"
     [dataSource]="dataSource"
     (tableEvent)="onTableEvent($event)">
 </app-lt-table>
 <app-lt-pagination [totalCount]="totalRowsCount" (paginationEvent)="paginationChanged($event)"></app-lt-pagination>