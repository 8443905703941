import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { OperatorFilter } from './operator-filter';

@Injectable()
export class DomesticCityService {
    url = environment.apiHost + '/lider/domestic-city'; 
    addSelectedCityPath = '/add-selected-city';

    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };

    constructor(private http: HttpClient) {
    }

    dataList: DomesticCityService[] = [];
  
    findById(id: number): Observable<DomesticCityService> {
        let params = {};
        let headers = this.headers;
        return this.http.get<DomesticCityService>(this.url + '/' + id, {params, headers});
    }

    find(filter: object): Observable<DomesticCityService[]> {

        let params = {
            sort: '-id'
        };
        if(filter['name']){
            params['name'] = filter['name'];
        }
        //params['page'] = data['page'];
        //params['per-page'] = data['perPage'];
        let headers = this.headers;

        return this.http.get<DomesticCityService[]>(this.url, {params, headers});
    }

    addSelectedCity(placeId) {
        let headers = this.headers;
        let entity = {
            placeId
        };
        return this.http.post<DomesticCityService>(this.url + this.addSelectedCityPath, entity, {headers});
    }
    save(entity: DomesticCityService): Observable<DomesticCityService> {
        let headers = this.headers;
        return this.http.post<DomesticCityService>(this.url, entity, {headers});
    }
}

