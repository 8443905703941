import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
    title : string;
    text : string;
    confirmBtn : string;
    cancelBtn : string;
    confirmClass : string;
    cancelClass : string;
}

@Component({
    selector: 'app-common-dialog',
    templateUrl: 'common-dialog.component.html',
})
export class CommonDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.title = data.title;
        this.text = data.text;
        this.confirmBtn = data.confirmBtn;
        this.cancelBtn = data.cancelBtn;
        this.confirmClass = data.confirmClass ? data.confirmClass : '';
        this.cancelClass = data.cancelClass ? data.cancelClass : '';
    }
    title : string;
    text : string;
    confirmBtn : string;
    cancelBtn : string;
    confirmClass : string;
    cancelClass : string;
}