<div class="form-filter__wrapper flex_col no-padding">
    <div class="form-filter__group">
        <dynamic-form [cols]="cols" [form]="filterForm" [config]="filterConfig"></dynamic-form>
    </div>
    <div class="form-filter__actions">
        <button mat-raised-button color="primary"
            (click)="submitSearch()"><mat-icon>search</mat-icon>Pretraži</button>
        <button mat-flat-button
            (click)="filterForm.reset()"><mat-icon>refresh</mat-icon>Resetuj</button>
    </div>
</div>