export class Contact {
    id: number;
    name: string;
    lastname: string;
    sex: number;
    phone: string;
    email: string;
    personal_id: number;
    birthday: string;
    source: string;
    postcode: string;
    city: string;
    address: string;
    passport_number: string;
    passport_expiry: string;
    entity_holder?: boolean;
}