<mat-card>
    <mat-progress-bar *ngIf="processing" mode="indeterminate"></mat-progress-bar>
    <br>
    <mat-card-title>
        <button mat-mini-fab color="primary"><mat-icon>tune</mat-icon></button> Dodatni podaci na ugovoru
    </mat-card-title>
    <mat-card-content>
        <dynamic-form
        [config]="config"
        #settingsForm>
        </dynamic-form>
    </mat-card-content>
    <mat-card-actions align="end">
        <button *ngIf="organizerId;else msg" [disabled]="processing" mat-raised-button (click)="saveSettings($event)" color="primary">
            <mat-icon>save</mat-icon>Sačuvaj promene</button>
            <ng-template #msg>
                <h4><mat-icon>info</mat-icon>Moguće sačuvati nakon što je organizator unesen</h4>
            </ng-template>
    </mat-card-actions>
</mat-card>