import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from './contract.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { formatDate, formatDatetime, nowDateTime } from '../shared/helpers/date';
import { FieldConfig } from "../components/dynamic-form-master/models/field-config.interface";
import { TransactionService } from '../transaction-new/transaction.service';
import { FormEventService } from '../components/dynamic-form-master/form-event.service';
import { LtFilterComponent } from '../shared/lt-filter/lt-filter.component';
import { OrganizerService } from '../organizer/organizer.service';
import { saveAs } from 'file-saver';
import { LtPaginationComponent } from '../shared/pagination/lt-pagination.component';
import { GENDER_OPTIONS } from '../contact/sex-options';
import { Title } from '@angular/platform-browser';

export interface ContractSearchParams {
  id?: number;
  departure_from?: string;
  departure_to?: string;
  return_from?: string;
  return_to?: string;
  customer_name?: string;
  customer_lastname?: string;
  customer_personal_id?: string;
  customer_phone?: string;
  transport?: number;
  organizer?: string;
  guar_no?: number;
  org_contr?: number;
  arr?: string;
  hotel?: string;
  status_id?: string;
  extendedView?: string;
  fullSearch?: string;
  hideOldDates?: number | string;
  sortOrder?: string;
  sortCol?: string;
  pageSize?: number;
}

export interface TableOptions {
  expandable?: boolean;
  hasHideShowCol?: boolean;
}
@Component({
  selector: 'contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit, AfterViewInit {
  pageTitle = 'Ugovori';
  contractPageSizeDefault = 100;
  contractPageSize; //override default page size
  pageSizeOptions = [20, 30, 50, 100, 200, 300, 500 ]; //override default
  isSearching: boolean = false;
  searchValues: ContractSearchParams = {
  };
  allSub: Subscription[] = [];
  static readonly CLONE_KWD = 'clone';
  columns: Array<any> = [
    {
      name: 'number', label: 'Br. Ugovora', type: 'url', sort: true,
      url: {
        route: '/contract/'
      }
    },
    { name: 'status', label: 'Status', type: 'chip' },
    { name: 'customer', label: 'Nosilac Ug.' },
    { name: 'created_at', label: 'Ug. Započet', sort: true },
    { name: 'arrangement', label: 'Aranžman', sort: true },
    { name: 'hotel', label: 'Hotel', sort: true },
    { name: 'room', label: 'Smeštaj', sort: true },
    { name: 'service', label: 'Usluga', sort: true },
    { name: 'departureDate', label: 'Polazak', sort: true },
    { name: 'returnDate', label: 'Povratak', sort: true },
    { name: 'daysLeft', label: 'Do polaska', sort: true },
    { name: 'contractTotal', label: 'Cena aranžmana', type: 'money-object'},
    { name: 'totalPayedIn', label: 'Ukupno uplaćeno', type: 'money-list'},
    { name: 'financialStatus', label: 'Dug', type: 'money-list' },
    { name: 'oweMainCurrency', label: 'Dug Total', type: 'money-object' },
    { name: 'oweSupplierMainCurrency', label: 'Dug dobavljaču', type: 'money-object' },
    { name: 'oweInsuranceMainCurrency', label: 'Osig. Dug', type: 'money-object' },
    { name: 'provider', label: 'Organizator', sort: true },
    { name: 'organizer_contract_no', label: 'Br. ug. organizatora' },
    { name: 'organizer_guaranty_number', label: 'Br. garancije' },
    { name: 'fiscal_numbers', label: 'Broj fiskala', type: 'list' , isVisible: false},
    { name: 'fiscal_dates', label: 'Datum fiskala', type: 'list',isVisible: false },
    { name: 'fiscal_amounts', label: 'Iznos fiskala', type: 'money-list' , isVisible: false},
    { name: 'organizer_invoice_no', label: 'Br. fakture'},
    { name: 'notice', label: 'Napomena' },
    { name: 'notice_intern', label: 'Interna Nap.' },
    { name: 'announced', label: 'Najava', type: 'icon' },
    {
      name: 'actions',
      label: '',
      tooltip: { title: 'Dodaj novi ugovor' },
      headerPath: '/contract/new',
      actions: [
        /*{
            name: 'Izmeni',
            path: '/contract/',
            param : 'id',
            'icon': 'edit'
        },
        */
        {
          name: 'Kloniraj',
          path: '/contract/' + ContractComponent.CLONE_KWD + '-',
          param: 'id',
          'icon': 'file_copy'
        },
        {
          name: 'Print',
          path: '/contract/print/',
          param: 'id',
          'icon': 'picture_as_pdf'
        },
        {
          name: 'Pošalji najavu',
          type: 'function_call',
          callback: (row) => {
            this.announcementToOrganizer(row)
          },
          'icon': 'record_voice_over'
        }]
    }
  ];
  //hiddenColumns = ['fiscal_numbers', 'fiscal_dates', 'fiscal_amounts'];
  hiddenColumns = [];
  toolbarButtons = [
    {
      label: 'Lista putnika export',
      clickAction: 'exportPassengerList',
      icon: 'contact_page',
      color: 'primary'
    }
  ];
  displayedColumns: string[] = this.columns.map(column => column.name);

  dataSource = new BehaviorSubject([]);
  contracts = [];
  organizers: any[] = [];

  filterConfig: FieldConfig[] = [];

  currenciesMap = new Map();
  contractStatusMap = new Map();
  contractStatusList = [];

  tableOptions: TableOptions = {
    expandable: true,
    hasHideShowCol: true
  };
  expandedTable = false;

  totalRowsCount = 0;
  defaultFilterValues = {hideOldDates :1};
  @ViewChild(LtFilterComponent) filter: LtFilterComponent;
  @ViewChild(LtPaginationComponent) pagination: LtPaginationComponent;
  
  constructor(
    public titleService: Title,
    private contractService: ContractService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private formEventService: FormEventService,
    private organizerService: OrganizerService
  ) {
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit() {

    this.contractService.getStatuses().subscribe(statuses => {
      statuses.forEach(status => {
        this.contractStatusMap.set(status.id, status);
        this.contractStatusList.push({
          name: status.name,
          id: String(status.id),
        });
      });
    });

    this.transactionService.getCurrencies().subscribe(currencies => {
      currencies.forEach(currency => {
        this.currenciesMap.set(currency.id, currency);
      });
    });


    this.allSub.push(
      this.route.queryParams.subscribe(params => {
        this.searchValues = {...this.defaultFilterValues,...params};
        this.isSearching = true;
        this.tableOptions = {
          ...this.tableOptions,
          expandable: params.extendedView == 'true' ? true : false
        };
        
        this.contractPageSize = params.pageSize ?? this.contractPageSizeDefault;
        forkJoin(
          {
            organizers: this.organizerService.getAll(),
            result: this.contractService.find({pageSize: this.contractPageSize, ...this.searchValues})
          }
        ).subscribe(
          all => {
            const result = all.result;
            const organizers = all.organizers;

            if (organizers.success === true && organizers.data) {
              this.organizers = organizers.data.map(el => {
                return {
                  id: el.id,
                  name: el.name,
                }
              });
            }
            //we set it here and now cause it depends on organizers
            this.setFilterFormConfig();

            this.contracts = [];
            result['data'].forEach(element => {
              const arrangement = element.arrangements[0] ? element.arrangements[0] : {};
              const customer = element.contractHolder ? element.contractHolder : [];
              const some = element.financeStatus.map(oneCurrency => {
                return oneCurrency.owe + ' ' + this.currenciesMap.get(Number(oneCurrency.currency))?.symbol
              });
              const genInfo = JSON.parse(element.general_info);
              const status = this.contractStatusMap.get(Number(element.contract_status));
              //const arrangementName = arrangement?.arrangement_country + ' ' + arrangement?.arrangement_city
              const fiscalNumbers = [];
              const fiscalDates = [];
              const fiscalAmounts = [];
              element.fiscals.forEach(el => {
                fiscalNumbers.push(el.number);
                fiscalDates.push(formatDate(el.date));
                fiscalAmounts.push({value:el.amount, currencyName: TransactionService.MAIN_CURRENCY.name});
              });

              const totalPayedIn = [];
              const totalCurr = element.totalPayedInByCurrency;
              for (let currId in totalCurr) {
                totalPayedIn.push({
                  value: totalCurr[currId],
                  currencyName: this.currenciesMap.get(Number(currId))?.name});
              }
              const daysLeft = arrangement?.daysLeft ?? null;
              const daysLeftText =  daysLeft ?  daysLeft + ' '+ (daysLeft % 10 != 1 ? 'dana' : 'dan') : '';

              this.contracts.push({
                id: element.id,
                customer: customer['customer_lastname'] + ' ' + customer['customer_name'],
                created_at: formatDatetime(element.created_at),
                number: {
                  text: element.incremental_number ? '#' + element.incremental_number : 'P-' + element.id,
                  idParam: element.id
                },
                arrangement: arrangement?.arrangement_name,
                hotel: arrangement?.arrangment_accomodation,
                provider: element.organizer_agency,
                organizer_contract_no: element.organizer_contract_no,
                organizer_guaranty_number: element.organizer_guaranty_number,
                departureDate: formatDate(arrangement?.date_from),
                returnDate: formatDate(arrangement?.date_to),
                daysLeft: daysLeftText,

                todate: formatDate(arrangement?.date_from) + ' / ' + formatDate(arrangement?.date_to),
                room: arrangement?.room_name,
                service: arrangement?.service,
                financialStatus: element.financeStatus.map(oneCurrency => {
                  const currencyName = this.currenciesMap.get(Number(oneCurrency.currency)) ?
                    this.currenciesMap.get(Number(oneCurrency.currency)).symbol : ''
                  return {
                    value: oneCurrency.owe,
                    currencyName: currencyName,
                  };
                }),
                contractTotal: {
                  value: element?.oweTotal?.servicesTotal,
                  currencyName: 'EUR',
                  error: element.oweTotal.error != '' ? element.oweTotal.error : '',
                },
                /*
                totalPayedIn: {
                  value: element?.oweTotal?.paidIn,
                  currencyName: TransactionService.MAIN_CURRENCY.name,
                  error: element.oweTotal.error != '' ? element.oweTotal.error : '',
                },
                */
               totalPayedIn: totalPayedIn,
                oweMainCurrency: {
                  value: element?.oweTotal?.amount,
                  currencyName: TransactionService.MAIN_CURRENCY.name,
                  error: element.oweTotal.error != '' ? element.oweTotal.error : '',
                  class: element?.oweTotal?.amount > 50 ? 'red-background' : '',
                },
                oweSupplierMainCurrency: {
                  value: element?.oweToSupplierTotal?.amount,
                  currencyName: TransactionService.MAIN_CURRENCY.name,
                  error: element.oweToSupplierTotal.error != '' ? element.oweToSupplierTotal.error : '',
                  class: element?.oweToSupplierTotal?.amount > 50 ? 'red-background' : '',
                },
                oweInsuranceMainCurrency: {
                  value: element?.oweInsurance?.amount,
                  currencyName: TransactionService.MAIN_CURRENCY.name,
                  error: element.oweInsurance.error != '' ? element.oweInsurance.error : '',
                  class: element?.oweInsurance?.amount > 50 ? 'red-background' : '',
                },
                announced: {
                  iconName: genInfo?.announced ? 'done' : null,
                  iconClass: genInfo?.announced ? 'green' : ''
                },
                status: {
                  text: status && status.name ? status.name : '',
                  class: status && status.class ? status.class : '',
                  icon: status && status.icon ? status.icon : ''
                },
                fiscal_numbers: fiscalNumbers,
                fiscal_dates: fiscalDates,
                fiscal_amounts: fiscalAmounts,
                organizer_invoice_no: element.organizer_guaranty_file,
                notice: arrangement.notice,
                notice_intern: arrangement.notice_intern,
                //actions : '',
                customerList: element.customers.map(customer => {
                  return {
                    ...customer,
                    sex: GENDER_OPTIONS.find(g => g.id == customer.sex)?.name ?? ''
                  }
                })
              });

            });
            this.totalRowsCount = result['meta'].totalCount;
            this.dataSource.next(this.contracts);
          },
          err => {
            alert('Desila se greška, molimo pokušajte ponovo ili kontaktirajte podršku');
            console.error('error loading', err);
          },
          () => {
            this.isSearching = false;
          }
        )
      })
    );
  }

  ngAfterViewInit(): void {
    this.allSub.push(
      this.formEventService.eventEmitter$.subscribe(event => {
        if (event.message == 'contractExtendedViewChanged') {
          this.onFilterChanged(this.filter.filterForm.getRawValue());
        }
      })
    );

  }

  announcementToOrganizer(data) {
    if (!data.id) {
      alert('Nedostaje ID ugovora. Kontaktirajte podršku');
    }
    const contractId = data.id;
    this.contractService.sendOrganizerAnnouncment(contractId).subscribe(
      resp => {
        if (resp.success) {
          this.openSnackBar('Najava je uspešno poslata');
          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: { announced: 1 }, queryParamsHandling: 'merge'
            }
          );
        }
      }, err => {

        if (err?.error?.error?.message != undefined) {
          this.openSnackBar(err.error.error.message, false, 'U redu');
        } else {
          this.openSnackBar('Nepoznata greška', false, 'U redu');
        }
      }
    );
  }

  openSnackBar(message: string, success: boolean = true, action: string = '') {
    let config: MatSnackBarConfig = {
      panelClass: ['success-snackbar']
    };

    if (success) {
      config.duration = 3000;
    } else {
      config.panelClass = ['red-snackbar'];
    }

    this._snackBar.open(message, action, config);
  }

  onFilterChanged(filter) {
    //@todomilos filter and use only set value
    /*
    Object.keys(filter).forEach(key=> {
      if (filter[key] === '') {
        console.log('deleted ' + key);
        delete filter[key]; 
      }
    });
    */
   let statusIdParam = filter.status_id ?? [];
   let organizer = filter.organizer ?? [];
   statusIdParam = statusIdParam.join(','); 
   organizer = organizer.join(',');
   console.log(filter);
    filter = {
      ...filter,
      status_id: statusIdParam,
      organizer: organizer,
      ...this.pagination.defaultValues,
      pageSize: this.contractPageSize,
      hideOldDates: filter.hideOldDates ? 1 : '' 
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: filter,
        queryParamsHandling: 'merge'
      }
    );
  }

  setFilterFormConfig() {
    const filterConfig: FieldConfig[] = [
      {
        type: 'input',
        label: 'Br. Ugovora',
        name: 'id',
        placeholder: 'Br Ugovora',
        value: this.searchValues?.id ? this.searchValues.id : '',
        col: 1
      },
      {
        type: 'datepicker',
        label: 'Polazak od',
        name: 'departure_from',
        placeholder: 'Polazak od',
        value: this.searchValues?.departure_from ? this.searchValues.departure_from : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Polazak do',
        name: 'departure_to',
        placeholder: 'Polazak do',
        value: this.searchValues?.departure_to ? this.searchValues.departure_to : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Povratak od',
        name: 'return_from',
        placeholder: 'Povratak od',
        value: this.searchValues?.return_from ? this.searchValues.return_from : '',
        col: 2
      },
      {
        type: 'datepicker',
        label: 'Povratak do',
        name: 'return_to',
        placeholder: 'Povratak do',
        value: this.searchValues?.return_to ? this.searchValues.return_to : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Prevoz',
        name: 'transport',
        options: [
          {
            id: '',
            name: '',
          },
          {
            id: 1,
            name: 'Bus'
          },
          {
            id: 2,
            name: 'Avio'
          },
          {
            id: 3,
            name: 'Sopstveni'
          }],
        placeholder: 'Prevoz',
        //validation: [Validators.required],
        value: this.searchValues?.transport ? Number(this.searchValues.transport) : '',
        col: 1
      },
      {
        type: 'input',
        label: 'Aranžman',
        name: 'arr',
        placeholder: 'Aranžman',
        value: this.searchValues?.arr ? this.searchValues.arr : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Smeštaj',
        name: 'hotel',
        placeholder: 'Smeštaj',
        value: this.searchValues?.hotel ? this.searchValues.hotel : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Ime',
        name: 'customer_name',
        placeholder: 'Ime putnika',
        value: this.searchValues?.customer_name ? this.searchValues.customer_name : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Prezime',
        name: 'customer_lastname',
        placeholder: 'Prezime putnika',
        value: this.searchValues?.customer_lastname ? this.searchValues.customer_lastname : '',
        col: 2
      },
      {
        type: 'input',
        label: 'JMBG',
        name: 'customer_personal_id',
        placeholder: 'JMBG  putnika',
        value: this.searchValues?.customer_personal_id ? this.searchValues.customer_personal_id : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Telefon',
        name: 'customer_phone',
        placeholder: 'Broj telefona',
        value: this.searchValues?.customer_phone ? this.searchValues.customer_phone : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Organizator',
        name: 'organizer',
        multiple: true,
        options: this.organizers,
        placeholder: 'Organizator',
        //validation: [Validators.required],
        value: this.searchValues.organizer !== undefined ? this.searchValues.organizer.split(',') : null,
        col: 2
      },
      {
        type: 'input',
        label: 'Br. ug. organizatora',
        name: 'org_contr',
        placeholder: 'Br. ug. organizatora',
        value: this.searchValues?.org_contr ? this.searchValues.org_contr : '',
        col: 2
      },
      {
        type: 'input',
        label: 'Br. garancije',
        name: 'guar_no',
        placeholder: 'Br. garancije',
        value: this.searchValues?.guar_no ? this.searchValues.guar_no : '',
        col: 2
      },
      {
        type: 'select',
        label: 'Status ugovora',
        multiple: true,
        name: 'status_id',
        options: this.contractStatusList,
        placeholder: 'Status ugovora',
        //validation: [Validators.required],
        value: this.searchValues.status_id !== undefined ? this.searchValues.status_id.split(',') : null,
        col: 2
      },
      {
        type: 'checkbox',
        label: 'Pregled putnika',
        name: 'extendedView',
        value: this.searchValues?.extendedView == 'true' ? true : false,
        eventMessages: {
          onChange: 'contractExtendedViewChanged'
        },
        col: 2
      },
      {
        type: 'checkbox',
        label: 'Sakrij prošle datume',
        name: 'hideOldDates',
        value: this.searchValues?.hideOldDates == 1 ? 1 : '',
        eventMessages: {
          onChange: 'contractExtendedViewChanged'
        },
        col: 2
      }
    ];

    // set default col size for each input
    this.filterConfig = filterConfig.map(formControl => {
      return {
        ...formControl,
        col: formControl.col || 3
      }
    });
  }

  showHideExtendedView() {
    /*this.tableOptions = {
      ...this.tableOptions,
      expandable: !this.tableOptions.expandable
    }
    */
    this.tableOptions = { ...this.tableOptions };
  }

  onTableEvent(event) {
    if (event && event.name) {
      if (event.name == 'ltTableSortChanged') {
        const sortedCol = event?.event?.active;
        const sortOrder = event?.event?.direction;
        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { sortCol: sortedCol, sortOrder: sortOrder },
            queryParamsHandling: 'merge'
          }
        );
      }

      if (event && event.name == 'exportPassengerList') {
        this.exportPassengerList();
      }
    }
    //ltTableColumnsHidden
  }

  exportPassengerList() {
    this.contractService.getClientListExport({pageSize: this.contractPageSize,...this.searchValues}).subscribe(res => {
      if (res['data']) {
        this.downloadXlsxFile(res['data'], 'lista-putnika-' + nowDateTime());
      }
    });
  }

  downloadFile(data: any, filename: string) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, filename);
  }

  downloadExcel(data: any, filename: string) {
    var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }

  downloadXlsxFile(data, filename) {
    const format = 'xlsx';
    var linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + data;
    var downloadLink = document.createElement("a");
    var fileName = filename + '.' + format;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  paginationChanged(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          pageIndex: event.data.pageIndex,
          pageSize: event.data.pageSize
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.allSub) {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}